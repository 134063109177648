import React, { Component } from "react";
import { isEqual } from "lodash";
import { Link } from "react-router-dom";

import buildColor from "../../_static/ColorPalette";
import Logo from "../../_static/Logos";
import Icon from "../../_static/Icons";
import { account, arrowLeft } from "../../_static/Icons/icons";
import {
  HeaderContent,
  LogoNavigationContainer,
  User,
  UserImageContainer,
  UserImage
} from "./styled-components";

const isProgramPage = (pathname) => pathname.includes("/program");

export default class HeaderTool extends Component {
  static defaultProps = {
    layout: "normal",
    user: "Admin",
    userRole: "",
    userImage: null,
    homeUrl: "/",
    currentUrl: "/"
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return (
      <HeaderContent data-qa-label="header" layout={this.props.layout}>
        <LogoNavigationContainer data-qa-label="tvg-logo-nav-container">
          <Link to={this.props.homeUrl}>
            {isProgramPage(this.props.currentUrl) ? (
              <Icon
                icon={arrowLeft}
                color={buildColor("white", "100")}
                size={24}
              />
            ) : (
              <Logo brand="tvg" data-qa-label="tvgLogo" />
            )}
          </Link>
        </LogoNavigationContainer>
        <User data-qa-label="tvg-user">
          <UserImageContainer data-qa-label="tvg-user-container">
            {this.props.userImage ? (
              <UserImage
                src={this.props.userImage}
                alt={this.props.user}
                data-qa-label="tvg-user-image"
              />
            ) : (
              <Icon
                icon={account}
                color={buildColor("white", "100")}
                qaLabel="tvg-user-icon"
              />
            )}
          </UserImageContainer>
          {`${this.props.user}${
            this.props.userRole.length ? ` (${this.props.userRole})` : ""
          }`}
        </User>
      </HeaderContent>
    );
  }
}
