import styled from "styled-components";

const InAppMessagesContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: auto;
  z-index: 20;

  + header {
    top: 50px;
  }
`;

export default InAppMessagesContainer;
