import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { get } from "lodash";
import tvgConf from "@tvg/conf";

const getDepositToWalletRedirectPath = (queryParams, appVersion) => {
  const params = queryString.parse(queryParams);

  if (params.initialTab) {
    delete params.location;
    delete params.product;
    const { callback } = params;
    delete params.callback;

    const depositUrl = tvgConf().buildUrl({
      app: "dma",
      path: "deposit",
      wrapper: true,
      callback: callback || true,
      params: { ...params, appVersion }
    });

    if (typeof window !== "undefined") {
      window.location.assign(depositUrl);
      return "";
    }

    return depositUrl;
  }

  return tvgConf().buildUrl({
    path: "wallet"
  });
};

const getDepositRedirectPath = (queryParams, appVersion) => {
  const params = queryString.parse(queryParams);

  if (params.fullPage) {
    delete params.location;
    delete params.product;
    const { callback } = params;
    delete params.callback;

    const depositUrl = tvgConf().buildUrl({
      app: "dma",
      path: "deposit",
      wrapper: true,
      callback: callback || true,
      params: { ...params, appVersion }
    });

    if (typeof window !== "undefined") {
      window.location.assign(depositUrl);
      return "";
    }

    return depositUrl;
  }

  return `/more${queryParams}#funds`;
};

const getPromosRedirectPath = (path, queryParams, toggles) => {
  const showPromos =
    get(toggles, "enablePromosStoryblok", false) && path.startsWith("/promos");

  if (!showPromos) return "/";

  const promoPage = path.startsWith("/promos/")
    ? path.replace("/promos/", "")
    : "";
  const pathParams = promoPage
    ? `?promo=${promoPage}${queryParams.replace("?", "&")}`
    : queryParams;

  return `/${pathParams}#promos`;
};

const RedirectPage = () => {
  const appVersion = useSelector((store) =>
    get(store, "ios.init.appVersion", "")
  );
  const toggles = useSelector((store) => get(store, "capi.featureToggles"));
  const pawsAbTesting = useSelector((store) =>
    get(store, "capi.abTesting.wallet", false)
  );

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const queryParams = location.search;

  const shouldShowWallet = get(toggles, "enablePawsAbTesting", false)
    ? pawsAbTesting
    : true;

  const redirectPath = useMemo(() => {
    // Handle redirect rules
    switch (currentPath) {
      case "/login":
        return `/${queryParams}#login`;
      case "/signup":
        return `/registration${queryParams}`;
      case "/my-bets":
        return "/#my-bets";
      case "/forgot-credentials":
        return `/${queryParams}#forgot-credentials`;
      case "/reset-credentials":
        return `/${queryParams}#reset-credentials`;
      case "/recover-email":
        return `/${queryParams}#recover-email`;
      case "/my-funds/deposit":
        return getDepositRedirectPath(queryParams, appVersion);
      case "/deposit":
        return toggles.pawsWalletAvailable && shouldShowWallet
          ? getDepositToWalletRedirectPath(queryParams, appVersion)
          : getDepositRedirectPath(queryParams, appVersion);
      case "/info/tutorials":
        return `/more#tutorials`;
      case "/info/privacypolicy":
        return `/more#privacyPolicy`;
      case "/info/termsandconditions":
        return `/more#termsConditions`;
      case "/info/wagerresponsibly":
        return `/more#wagerResponsibly`;
      case "/handicapping":
        return `/more#handicap-store`;
      case "/handicapping/handicapping-store":
        return `/more#handicap-store`;
      case "/info/accessibility":
        return "/more#accessibility";
      default: {
        return getPromosRedirectPath(currentPath, queryParams, toggles);
      }
    }
  }, []);

  useEffect(() => {
    navigate(redirectPath, { replace: true });
  }, [redirectPath]);

  return null;
};

export default RedirectPage;
