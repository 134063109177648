import { COMMON_EVENT_NAMES } from "../../constants";

export const getEventName = (brand: string) => ({
  SAVE_CHANGES: "Save Changes",
  NAVIGATE_MORE_PAGE: COMMON_EVENT_NAMES.NAVIGATED,
  PAGE_VIEW: `${brand} Account Preferences Opened`,
  PREFERENCE_TOGGLE: `${brand} Account Preferences Toggled`,
  ACCOUNT_DEFAULT_BET_TYPE_SELECTED: `${brand} Account Default Bet Type Selected`,
  ACCOUNT_DEFAULT_BET_AMOUNT: `${brand} Account Default Bet Amount Selected`,
  ACCOUNT_CUSTOM_BET_AMOUNT: `${brand} Account Custom Bet Amount Applied`
});
