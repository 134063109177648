export const initialState = {
  prevPath: "",
  trackCode: "",
  raceNumber: "",
  isRaceDataShown: false,
  isBeta: false,
  isPromoWidgetModalOpen: false,
  promosList: [],
  seoTrackList: [],
  subscription: null,
  showPAbetsSplashNotification: false
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case "PROMOS_LIST_SET": {
      return { ...state, promosList: action.payload };
    }
    case "SET_BETA_OPTIN": {
      return { ...state, isBeta: action.payload };
    }
    case "SET_PROMO_WIDGET_MODAL_STATE": {
      return { ...state, isPromoWidgetModalOpen: action.payload };
    }
    case "SET_SEO_TRACK_LIST": {
      return { ...state, seoTrackList: action.payload };
    }
    default: {
      return state;
    }
  }
}
