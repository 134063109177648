import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";

export const setDeviceId = (deviceId: string | undefined) => {
  const event = getUMEvent(UMEvents.SetDeviceId);

  mediator.dispatch({
    type: event.trigger,
    payload: {
      deviceId
    }
  });
};
