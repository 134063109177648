import React, { useEffect, useState, useRef } from "react";
import { findIndex, get, flowRight as compose } from "lodash";
import { connect, batch } from "react-redux";
import tvgConf from "@tvg/conf";
import Footer from "@tvg/atomic-ui/_templates/Footer";
import { graphql } from "@apollo/client/react/hoc";
import {
  getSelectedSettledTab,
  getSelectedTab,
  getTodayActiveCounter,
  getRedirectWithoutActiveBetsToggle,
  getTodayFutureCounter
} from "@tvg/sh-lib-my-bets/redux/selectors";
import useSetMyBetsDefaults from "@tvg/sh-lib-my-bets/hooks/useSetMyBetsDefaults";

import GraphOptions from "@tvg/sh-lib-my-bets/graphql/optionsActive.graph";
import SettledGraphOptions from "@tvg/sh-lib-my-bets/graphql/optionsSettled.graph";
import ActiveBetsQuery from "@tvg/sh-lib-my-bets/graphql/queries/activeBetsQuery";
import SettledBetsQuery from "@tvg/sh-lib-my-bets/graphql/queries/settledBetsQuery";
import ActiveBetsSubscription from "@tvg/sh-lib-my-bets/graphql/subscriptions/activeBetsSubscription";
import {
  setSelectedTabMyBetsStandalone,
  setShouldDefaultToFuture,
  setShouldDefaultToSettled
} from "@tvg/sh-lib-my-bets/redux/actions";
import { getAccountNumber } from "@urp/store-selectors";

export const getActiveBetsCounter = (props) =>
  props.isLogged ? get(props, "totalActiveBets", 0) : 0;

export const MobileFooter = (props) => {
  const {
    subscribeToMore,
    accountNumber,
    links,
    selectedTab,
    selectedSettledTab,
    refetchSettled,
    activeBetsCounter,
    dispatch,
    redirectWithoutActiveBetsToggle,
    futureBetsCounter
  } = props;

  const [hasSubscription, setHasSubscription] = useState(false);
  const lastAccountNumber = useRef();

  const variables = {
    accountId: parseInt(accountNumber, 10) || 0,
    status: ["PENDING"]
  };

  useEffect(() => {
    if (
      !hasSubscription &&
      !!subscribeToMore &&
      accountNumber !== undefined &&
      accountNumber !== ""
    ) {
      subscribeToMore({
        document: ActiveBetsSubscription,
        variables
      });

      setHasSubscription(true);
    }
  }, [subscribeToMore, accountNumber, hasSubscription]);

  useEffect(() => {
    if (
      !!hasSubscription &&
      (lastAccountNumber.current !== undefined ||
        lastAccountNumber.current !== "") &&
      (accountNumber === undefined || accountNumber === "")
    ) {
      setHasSubscription(false);
    }
    lastAccountNumber.current = accountNumber;
  }, [accountNumber, hasSubscription]);

  useEffect(() => {
    if (
      typeof refetchSettled === "function" &&
      (selectedSettledTab === "TODAY" || selectedTab === "ACTIVE")
    )
      refetchSettled();
  }, [activeBetsCounter]);

  useSetMyBetsDefaults(
    dispatch,
    redirectWithoutActiveBetsToggle || false,
    activeBetsCounter,
    futureBetsCounter
  );

  // TODO: remove toggle effect
  useEffect(() => {
    if (!redirectWithoutActiveBetsToggle) {
      batch(() => {
        dispatch(setSelectedTabMyBetsStandalone("ACTIVE"));
        dispatch(setShouldDefaultToSettled(false));
        dispatch(setShouldDefaultToFuture(false));
      });
    }
  }, [redirectWithoutActiveBetsToggle]);

  const footerLinks = links.map((route, index) => ({
    label: route.name,
    href: route.href,
    onClick: route.onClick,
    counter: index === 2 ? getActiveBetsCounter(props) : 0
  }));

  let active = 0;

  const activePath = props.activePath.split("?")[0];
  if (activePath === "" || activePath === "/") {
    active = 0;
  } else {
    active = findIndex(
      footerLinks,
      (link) => link.href && activePath === link.href
    );
  }

  return (
    <Footer
      linksList={footerLinks}
      active={active}
      layout={props.layout}
      isTextAside={tvgConf().device === "tablet"}
    />
  );
};

MobileFooter.defaultProps = {
  activePath: "",
  links: [],
  totalActiveBets: 0,
  isLogged: false,
  accountNumber: "",
  layout: "horizontal"
};

export default connect(
  (store) => ({
    accountNumber: getAccountNumber(store),
    selectedTab: getSelectedTab(store),
    selectedSettledTab: getSelectedSettledTab(store),
    activeBetsCounter: getTodayActiveCounter(store),
    redirectWithoutActiveBetsToggle: getRedirectWithoutActiveBetsToggle(store),
    futureBetsCounter: getTodayFutureCounter(store)
  }),
  (dispatch) => ({ dispatch })
)(
  compose(
    graphql(ActiveBetsQuery, GraphOptions),
    graphql(SettledBetsQuery, SettledGraphOptions)
  )(MobileFooter)
);
