import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { MediatorEventType, QuickPicksData } from "./types";
import { EVENT_NAME, MODULE } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.QUICK_PICKS_CARD_CTA_CLICKED,
    (data: QuickPicksData): void => {
      track<BaseEventProps>(EVENT_NAME.QUICK_PICKS_CARD_CTA_CLICKED, {
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.RACE_NUMBER]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.POSITION]: data.payload.position,
        [BaseEventProps.LINK_URL]: data.payload.linkUrl,
        [BaseEventProps.LINK_TEXT]: data.payload.linkText
      });
    }
  );
};
