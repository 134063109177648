import React, { Component, Fragment } from "react";
import { noop, get, isEqual } from "lodash";

import { buildPromoBadgeURL } from "@tvg/formatter/url";

import ButtonPromos from "../../_atom/Buttons/promos";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { promosolid, ippChanges, optedin } from "../../_static/Icons/icons";
import {
  RaceDataWrapper,
  Description,
  RaceDataList,
  RaceDataListItem,
  TitleContainer,
  DescriptionContainer,
  Title,
  SubTitle,
  RacePromoWrapper,
  Wrapper,
  EventWrapper,
  PromoBadge,
  PromoBadgeText,
  ChangesIcon
} from "./styled-components";

const getColor = (isDark, isGrey) => {
  let color;
  if (isDark && !isGrey) {
    color = buildColor("white", "100");
  } else if (!isDark && isGrey) {
    color = buildColor("grey", "900");
  } else {
    color = buildColor("grey", "800");
  }
  return color;
};

export default class RaceInfoInline extends Component {
  static defaultProps = {
    importanceLevel: "0",
    isDark: false,
    description: "",
    raceData: [],
    raceNumber: "1",
    isGreyDarkText: false,
    title: null,
    subTitle: null,
    promo: null,
    showPromoBadge: false,
    onPromoClick: noop,
    useAltLink: false,
    isRaceDataChangesShown: false,
    enablePromosStoryblok: false,
    isExternalPromo: false,
    useIsPromoTagShownFlag: false
  };

  constructor(props) {
    super(props);

    this.state = {
      hasRaceEvent: false,
      hasPromo: false,
      isOptedIn: false
    };
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  static getDerivedStateFromProps(props) {
    const isPromoTagShown = get(props, "promo.isPromoTagShown", false);
    const hasPromo = props.useIsPromoTagShownFlag
      ? isPromoTagShown && !!props.promo
      : !!props.promo;
    const isOptedIn = get(props.promo, "isOptedIn", false);

    return {
      hasRaceEvent: props.description || props.title,
      hasPromo: isOptedIn || hasPromo,
      isOptedIn
    };
  }

  renderListItems() {
    return this.props.raceData.map((listItem, index) => (
      <RaceDataListItem
        data-qa-label="raceInfoInline-item"
        isDark={this.props.isDark}
        color={getColor(this.props.isDark, this.props.isGreyDarkText)}
        // eslint-disable-next-line react/no-array-index-key
        key={`${listItem.value}-${index}`}
      >
        {this.props.isRaceDataChangesShown && listItem.changed && (
          <ChangesIcon>
            <Icon
              icon={ippChanges}
              size={15}
              color={buildColor("yellow", "500")}
            />
          </ChangesIcon>
        )}
        {listItem.value}
      </RaceDataListItem>
    ));
  }

  renderDescription() {
    if (this.props.title && this.props.subTitle) {
      return (
        <DescriptionContainer>
          <TitleContainer>
            <SubTitle>{this.props.subTitle}</SubTitle>
            <Title>{this.props.title}</Title>
          </TitleContainer>
          <Description
            isDark={this.props.isDark}
            importanceLevel={this.props.importanceLevel}
          >
            {this.props.description}
          </Description>
        </DescriptionContainer>
      );
    }

    return (
      <Description
        isDark={this.props.isDark}
        importanceLevel={this.props.importanceLevel}
      >
        {this.props.title && <Title>{this.props.title}</Title>}
        {this.props.description}
      </Description>
    );
  }

  renderPromoBtn = (isExternal) => {
    const promoPath = get(this.props, "promo.promoPath", "");

    return (
      <ButtonPromos
        isExternal={isExternal}
        promoPath={
          isExternal
            ? promoPath
            : buildPromoBadgeURL(
                promoPath,
                get(this.props, "raceNumber", "1"),
                this.props.enablePromosStoryblok
              )
        }
        isOptedIn={this.state.isOptedIn}
        qaLabel="promoBtn"
        onClick={this.props.onPromoClick}
        useAltLink={this.props.useAltLink}
      />
    );
  };

  renderPromoBadge = () => (
    <PromoBadge>
      <Icon
        icon={this.state.isOptedIn ? optedin : promosolid}
        color={this.state.isOptedIn ? null : buildColor("white", "100")}
      />
      <PromoBadgeText>
        {this.state.isOptedIn ? "Opted-In" : "Promo"}
      </PromoBadgeText>
    </PromoBadge>
  );

  renderWithEvent = () => (
    <EventWrapper>
      <RacePromoWrapper>
        {this.renderDescription()}
        {this.state.hasPromo && this.renderPromoBadge()}
      </RacePromoWrapper>
      <RaceDataList>{this.renderListItems()}</RaceDataList>
    </EventWrapper>
  );

  renderWithoutEvent = () => (
    <Wrapper hasRaceEvent>
      <RaceDataWrapper>
        <RaceDataList>{this.renderListItems()}</RaceDataList>
      </RaceDataWrapper>
      {this.state.hasPromo && this.renderPromoBadge()}
    </Wrapper>
  );

  renderWithBadge = () =>
    this.state.hasRaceEvent
      ? this.renderWithEvent()
      : this.renderWithoutEvent();

  render() {
    return (
      <Fragment>
        {this.props.showPromoBadge ? (
          this.renderWithBadge()
        ) : (
          <Wrapper>
            <RaceDataWrapper>
              {this.state.hasRaceEvent && this.renderDescription()}
              <RaceDataList>{this.renderListItems()}</RaceDataList>
            </RaceDataWrapper>
            {this.state.hasPromo &&
              this.renderPromoBtn(this.props.isExternalPromo)}
          </Wrapper>
        )}
      </Fragment>
    );
  }
}
