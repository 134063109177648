import React, { Component } from "react";
import { connect } from "react-redux";

import { bindAll } from "lodash";

import FeatureTogglesTemplate from "@tvg/atomic-ui/_templates/FeatureToggles";

import { changeFeatureToggleValue } from "./actions";

export class FeatureToggles extends Component {
  static defaultProps = {
    featureToggles: []
  };

  constructor(props) {
    super(props);
    bindAll(this, "onFeatureToggleClick");
  }

  onFeatureToggleClick(toggleName, currentToggleValue) {
    this.props.dispatch(
      changeFeatureToggleValue(toggleName, !currentToggleValue)
    );
  }

  render() {
    return (
      <FeatureTogglesTemplate
        featureToggles={this.props.featureToggles}
        onFeatureToggleClick={this.onFeatureToggleClick}
      />
    );
  }
}

export default connect(
  (store) => ({
    featureToggles: store.capi.featureToggles
  }),
  (dispatch) => ({ dispatch })
)(FeatureToggles);
