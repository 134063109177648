import React from "react";
import { noop } from "lodash";

import { LogoTVGBlack } from "../../_static/Logos";
import Button from "../../_atom/Buttons/button";

import {
  Container,
  Content,
  Logo,
  Title,
  Description
} from "./styled-components";

const SplashNotification = (props) => (
  <Container device={props.device}>
    <Content device={props.device}>
      {props.showLogo && <Logo>{LogoTVGBlack(27, "")}</Logo>}

      <Title>{props.title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: props.description }} />
      {props.showButton && (
        <Button
          isStretched
          type="primary"
          size="big"
          onClick={props.buttonAction}
          qaLabel={props.qaLabel}
        >
          {props.buttonText}
        </Button>
      )}
    </Content>
  </Container>
);

SplashNotification.defaultProps = {
  showLogo: true,
  showButton: true,
  buttonAction: noop,
  qaLabel: "splash-notification-button"
};

export default SplashNotification;
