import { combineReducers } from "redux";
import quickLinksReducer from "@tvg/quick-links/redux-manager";

import homeReducer, {
  initialState as initialHomeState
} from "@tvg/home/src/store/reducers";

import topRacesReducer, {
  initialState as initialTopRacesState
} from "@tvg/top-races/src/reducers";

import tracksBuilderReducer, {
  initialState as initialTracksBuilderState
} from "@tvg/tracks-builder/src/reducers";

import mhnReducer, {
  initialState as initialMHNState
} from "@tvg/mobile-home/src/reducers";

// race program reducers
import RaceProgram, {
  initialState as initialPGMState
} from "@tvg/program-page/reducers/app";

import RaceProgramVideo, {
  initialState as initialVideoPGMState
} from "@tvg/program-page/reducers/video";

import mobileAccountReducers, {
  initialState as initialMobileAccount
} from "@tvg/mobile-account/src/reducers/mobileAccountReducers";

import RaceProgramLoading, {
  initialState as initialLoadingPGMState
} from "@tvg/program-page/reducers/loading";

import RaceProgramPoolsNInfo, {
  initialState as initialPoolsNInfoPGMState
} from "@tvg/program-page/reducers/poolsNInfo";

import myBetsReducer, {
  initialState as initialMyBetsState
} from "@tvg/my-bets/src/reducers";

import myBetsStandaloneReducer, {
  initialState as initialMyBetsStandaloneState
} from "@tvg/sh-lib-my-bets/redux/reducers";

import upNextRacesReducer, {
  initialState as initialUpNextRacesState
} from "@tvg/tracks/src/tracks-upnext/reducers";

import RaceFiltersReducer, {
  initialState as initialRaceFiltersState
} from "@tvg/tracks-header/reducers";

import tracksAzReducer, {
  initialState as initialTracksAzState
} from "@tvg/tracks/src/tracks-az/reducers";

import mtpStatusReducer, {
  initialState as initialMtpStatusState
} from "@tvg/mtp-update/src/reducers";

import tracksAzFavoritesReducer, {
  initialState as initialTracksAzFavoritesState
} from "@tvg/tracks/src/tracks-favorites/reducers";

import messengerReducer, {
  initialState as initialMessengerState
} from "@tvg/messenger/src/reducers";

import locationSplashReducer, {
  initialState as initialLocationSplashState
} from "@tvg/location-splash/reducer";

import withdrawalsReducer, {
  initialState as initialWithdrawalsState
} from "@tvg/withdrawals/src/reducers";

import betTicketReducer, {
  initialState as initialBetTicketState
} from "@tvg/bet/reducers";

import wagerRewardsReducer, {
  initialState as initialWagerRewardsState
} from "@tvg/wager-rewards/src/reducers";

import onboardingsReducer, {
  initialState as initialOnboardingsState
} from "@tvg/onboardings/src/reducers";

import revalidateLocationReducer, {
  initialState as initialRevalidateLocationState
} from "@tvg/revalidate-location/reducer";

import promosReducer, {
  initialState as initialPromosState
} from "@tvg/promos/src/reducers";

import referReducer, {
  initialState as initialReferState
} from "@tvg/refer/src/reducers";

import accountTransactionsReducer, {
  initialState as initialAccountTransactionsState
} from "@tvg/account-transactions/src/reducers";

import registrationReducer, {
  initialState as initialRegistrationState
} from "@tvg/rma/src/reducer";

import preferencesReducer, {
  initialState as initialPreferencesState
} from "@tvg/sh-lib-preferences/redux/reducers";

import loginControllerReducers from "@tvg/sh-lib-account-actions/src/reducers";

import brazeReducer, {
  initialState as initialBrazeReducer
} from "@tvg/braze/src/store/reducers";

import pawsReducer, { initialPawsState } from "@tvg/sh-lib-paws/redux/reducers";

import seoRaceTracksReducer, {
  initialState as initialSeoRaceTracksState
} from "@tvg/tracks/src/v2/redux/reducers";

import promosOnboardingReducer, {
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

import geoLocationReducer, {
  initialState as initialGeoLocationState
} from "./App/geoLocationReducer";

import iOSEventsReducer, {
  initialState as initialiOSEventsState
} from "./App/iOSEventsReducer";

import userFavoritesReducer, {
  initialState as initialUserFavoritesState
} from "./App/userFavoritesReducer";

import modalReducer, {
  initialState as initialModalState
} from "./App/modalReducer";

import alertsReducer, {
  initialState as initialAlertsState
} from "./App/alertsReducer";

import appReducer, { initialState as initialAppState } from "./App/appReducer";

export const initialState = {
  app: initialAppState,
  homePage: initialMHNState,
  RaceProgram: initialPGMState,
  RaceProgramVideo: initialVideoPGMState,
  RaceProgramLoading: initialLoadingPGMState,
  RaceProgramPoolsNInfo: initialPoolsNInfoPGMState,
  capi: initialCapiState,
  userData: initialUserState,
  userFavorites: initialUserFavoritesState,
  raceFilters: initialRaceFiltersState,
  geolocation: initialGeoLocationState,
  mtpStatus: initialMtpStatusState,
  myBets: initialMyBetsState,
  myBetsStandalone: initialMyBetsStandaloneState,
  upNextRaces: initialUpNextRacesState,
  tracksAz: initialTracksAzState,
  tracksAzFavorites: initialTracksAzFavoritesState,
  messenger: initialMessengerState,
  ios: initialiOSEventsState,
  modal: initialModalState,
  locationSplash: initialLocationSplashState,
  withdrawals: initialWithdrawalsState,
  alerts: initialAlertsState,
  mobileAccount: initialMobileAccount,
  BetTicket: initialBetTicketState,
  wagerRewards: initialWagerRewardsState,
  onboardings: initialOnboardingsState,
  revalidateLocation: initialRevalidateLocationState,
  home: initialHomeState,
  topRaces: initialTopRacesState,
  topTracks: initialTracksBuilderState,
  promos: initialPromosState,
  refer: initialReferState,
  registration: initialRegistrationState,
  accountTransactions: initialAccountTransactionsState,
  preferences: initialPreferencesState,
  paws: initialPawsState,
  seoRaceTracks: initialSeoRaceTracksState,
  promosOnboarding: initialPromosOnboardingState,
  brazeData: initialBrazeReducer
};

export default combineReducers({
  app: appReducer,
  homePage: mhnReducer,
  RaceProgram,
  RaceProgramVideo,
  RaceProgramLoading,
  RaceProgramPoolsNInfo,
  capi: capiReducer,
  userData: userDataReducer,
  userFavorites: userFavoritesReducer,
  raceFilters: RaceFiltersReducer,
  geolocation: geoLocationReducer,
  myBets: myBetsReducer,
  myBetsStandalone: myBetsStandaloneReducer,
  upNextRaces: upNextRacesReducer,
  mtpStatus: mtpStatusReducer,
  tracksAz: tracksAzReducer,
  tracksAzFavorites: tracksAzFavoritesReducer,
  seoRaceTracks: seoRaceTracksReducer,
  messenger: messengerReducer,
  withdrawals: withdrawalsReducer,
  ios: iOSEventsReducer,
  modal: modalReducer,
  mobileAccount: mobileAccountReducers,
  locationSplash: locationSplashReducer,
  alerts: alertsReducer,
  BetTicket: betTicketReducer,
  wagerRewards: wagerRewardsReducer,
  onboardings: onboardingsReducer,
  revalidateLocation: revalidateLocationReducer,
  quickLinks: quickLinksReducer,
  home: homeReducer,
  topRaces: topRacesReducer,
  topTracks: tracksBuilderReducer,
  promos: promosReducer,
  refer: referReducer,
  registration: registrationReducer,
  accountTransactions: accountTransactionsReducer,
  preferences: preferencesReducer,
  paws: pawsReducer,
  promosOnboarding: promosOnboardingReducer,
  ...loginControllerReducers,
  brazeData: brazeReducer
});
