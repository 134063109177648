import React, { useMemo, useState, useEffect, useRef } from "react";

import { get } from "lodash";
import tvgConf from "@tvg/conf";
import {
  hasDirectIntegration,
  brazeReadContentCard,
  brazeClickContentCard
} from "@tvg/braze";
import { inspectURL, processURL } from "@tvg/formatter/url";
import { openExternalLinkIos } from "@tvg/utils/mediatorUtils";

import mediatorChannels from "@tvg/mediator";

import ModalV2 from "../ModalV2";
import ContentCardEmptyState from "../../_molecule/ContentCardEmptyState";

import ContentCard from "../../_molecule/ContentCard";
import { ContentCardsContainer } from "./styled-components";

const conf = tvgConf();
const tvgDomains = conf.getDomains();

const showContentCards = () => {
  if (hasDirectIntegration()) {
    if (typeof window !== "undefined" && window.braze) {
      const target = document.querySelector(
        '[data-selector="container-to-load"]'
      );
      if (target) {
        window.braze.showContentCards(target);
      }
    }
  }
};

const onClickContentCard = (url, isApp, urlType, history) => {
  if (!url) {
    return;
  }
  if (urlType === "isInternal") {
    location.href = url;
  } else if (urlType === "isRelative") {
    history.push(url);
  } else if (urlType === "isExternal") {
    if (isApp) {
      openExternalLinkIos(url);
    } else {
      window.open(url, "_blank");
    }
  }
};

const listContentCards = (
  cards,
  isApp,
  cardReadyToDismiss,
  setCardReadyToDismiss,
  setCardsLoaded,
  history
) =>
  cards.map((card, index) => (
    <ContentCard
      key={card.id}
      tvgDomains={tvgDomains}
      onClickContentCard={() => {
        brazeClickContentCard(card, true);
        mediatorChannels.base.dispatch({
          type: "BRAZE:CLICK_CARD",
          payload: {
            id: card.id,
            position: index + 1,
            pinned: card.pinned
          }
        });
        onClickContentCard(
          processURL(card.url),
          isApp,
          card.url && tvgDomains ? inspectURL(card.url, tvgDomains) : "",
          history
        );
      }}
      setIdToDismiss={(id) => {
        if (id !== cardReadyToDismiss) {
          setCardReadyToDismiss(id);
        }
      }}
      card={card}
      showDismiss={cardReadyToDismiss === card.id}
      onCardLoaded={(cardRef) =>
        setCardsLoaded((prev) => ({ ...prev, [card.id]: cardRef }))
      }
      onDismissCard={() => {
        mediatorChannels.base.dispatch({
          type: "BRAZE:DISMISS_CARD",
          payload: {
            id: card.id,
            position: index + 1,
            pinned: card.pinned
          }
        });
      }}
    />
  ));

export const BrazeContentCards = ({
  brazeContentCards,
  isOpen,
  onClose,
  brazeMessages,
  contentCardsCustom,
  isApp,
  history
}) => {
  const [cardReadyToDismiss, setCardReadyToDismiss] = useState("");

  const [cardsLoaded, setCardsLoaded] = useState({});
  const intersactionObs = useRef(null);

  useEffect(() => {
    // start intersaction
    if (isOpen) {
      const intersactionOptions = {
        rootMargin: "0px",
        threshold: 1.0
      };

      const onCardEnterView = (entries, observer) => {
        const viewedCards = entries.reduce((acc, entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);

            let position = 0;
            const currentCard = brazeContentCards.cards.find((card, index) => {
              position = index;
              return card.id === entry.target.id;
            });

            if (currentCard) {
              acc.push(currentCard);
              mediatorChannels.base.dispatch({
                type: "BRAZE:CARD_IMPRESSION",
                payload: {
                  id: currentCard.id,
                  position: position + 1,
                  pinned: currentCard.pinned
                }
              });
            }
          }

          return acc;
        }, []);

        if (viewedCards.length) {
          brazeReadContentCard(viewedCards, true);
        }
      };

      intersactionObs.current = new IntersectionObserver(
        onCardEnterView,
        intersactionOptions
      );
    }

    // clean variables when modal is closed
    if (!isOpen) {
      if (cardReadyToDismiss !== "") {
        setCardReadyToDismiss("");
      }
      if (Object.keys(cardsLoaded).length > 0) {
        setCardsLoaded({});
      }

      if (intersactionObs.current) {
        intersactionObs.current.disconnect();
      }
    }
    return () =>
      intersactionObs.current ? intersactionObs.current.disconnect() : null;
  }, [isOpen]);

  // start to observer once all cards are loaded
  useEffect(() => {
    const filteredCards = brazeContentCards.cards.filter(
      (card) => card.sc !== "ab-control-card"
    );

    if (Object.keys(cardsLoaded).length === filteredCards.length) {
      filteredCards.forEach(({ viewed, id }) => {
        return !viewed && Object.prototype.hasOwnProperty.call(cardsLoaded, id)
          ? intersactionObs.current.observe(cardsLoaded[id])
          : null;
      });
    }
  }, [cardsLoaded]);

  return useMemo(() => {
    const modalProps = {
      title: get(brazeMessages, "modalTitle", ""),
      titleType: "default",
      isOpen: isOpen && get(brazeMessages, "modalTitle", "") !== "",
      onClose,
      isFullHeight: true,
      qaLabel: "modal-content-cards",
      animation: "bottom"
    };

    const filteredCards = brazeContentCards.cards.filter(
      (card) => card.sc !== "ab-control-card"
    );

    const renderContentCards = () =>
      contentCardsCustom ? (
        <ContentCardsContainer>
          {listContentCards(
            filteredCards,
            isApp,
            cardReadyToDismiss,
            setCardReadyToDismiss,
            setCardsLoaded,
            history
          )}
        </ContentCardsContainer>
      ) : (
        <React.Fragment>
          <ContentCardsContainer data-selector="container-to-load" />
          {isOpen && showContentCards()}
        </React.Fragment>
      );

    return (
      <ModalV2 {...modalProps}>
        {() =>
          brazeContentCards.totalCards > 0 && filteredCards.length > 0 ? (
            renderContentCards()
          ) : (
            <ContentCardEmptyState
              qaLabel="empty-state-content-cards"
              title={get(brazeMessages, "emptyTitle", "")}
              message={get(brazeMessages, "emptyDescription", "")}
            />
          )
        }
      </ModalV2>
    );
  }, [
    isOpen,
    JSON.stringify(brazeContentCards),
    JSON.stringify(brazeMessages),
    cardReadyToDismiss
  ]);
};

BrazeContentCards.defaultProps = {
  brazeContentCards: {
    unviewed: 0,
    totalCards: 0,
    cards: []
  },
  onOpen: () => {},
  brazeMessages: {
    modalTitle: "",
    emptyTitle: "",
    emptyDescription: ""
  },
  contentCardsCustom: false
};

export default BrazeContentCards;
