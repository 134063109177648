import React, { createContext } from "react";
import { StyledContainer } from "./styled-components";
import { Paragraph } from "../../Typography";
import type { RadioGroupContextProps, RadioGroupProps } from "./types";

const initialState = {
  selectedValue: "",
  onChange: () => {}
};

export const GroupContext = createContext<RadioGroupContextProps>(initialState);

export const RadioGroup = ({
  children,
  selectedValue,
  qaLabel,
  onChange,
  groupTitle,
  isDisabled = false,
  ...rest
}: RadioGroupProps) => (
  <StyledContainer {...rest} data-qa-label={qaLabel}>
    <GroupContext.Provider value={{ selectedValue, onChange, isDisabled }}>
      {groupTitle && (
        <Paragraph
          qaLabel={groupTitle}
          fontFamily="bold"
          color="var(--fd-colors-content-default)"
          fontSize="m"
          fontWeight="700"
          lineHeight="125%"
        >
          {groupTitle}
        </Paragraph>
      )}
      <div>{children}</div>
    </GroupContext.Provider>
  </StyledContainer>
);

export type { RadioGroupProps, RadioGroupContextProps };
