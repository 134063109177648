import mediatorChannels from "@tvg/mediator";

// @ts-ignore
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("BETSLIP:UNDO", () => {
    pushToDataLayer({
      event: "betslip",
      gaEventCategory: "Betslip",
      gaEventAction: "Selection Removal Undone",
      gaEventLabel: undefined,
      module: "Bet Ticket",
      tag: undefined
    });
  });
};
