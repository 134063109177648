import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

interface NavigateGtmProps {
  payload: {
    destinationUrl: string;
    isFromQuickLinks: boolean;
  };
}

interface OpenTutorialGtmProps {
  payload: { name: string; tutorialCategory: string };
}

interface LoadTutorialGtmProps {
  payload: {
    title: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}

interface NextTutorialGtmProps {
  payload: {
    buttonText: string;
    linkText: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}

interface CloseTutorialGtmProps {
  payload: {
    title: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}

export default () => {
  // When a user clicks a link to the tutorials content from the Quick Link tile
  // When a user clicks a link to the tutorials content from the Other Links menu in the Account section
  mediatorChannels.base.subscribe(
    "FDR_TUTORIALS:NAVIGATE",
    (data: NavigateGtmProps) => {
      const { destinationUrl, isFromQuickLinks } = data.payload;

      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: "Tutorials",
        module: isFromQuickLinks ? "Quick Links" : "Other Links",
        menu: isFromQuickLinks ? "Quick Links" : "Account",
        tag: undefined,
        destinationUrl // link url path
      });
    }
  );

  // When a user selects a tutorial
  mediatorChannels.base.subscribe(
    "FDR_TUTORIALS:OPEN_TUTORIAL",
    (data: OpenTutorialGtmProps) => {
      const { name, tutorialCategory } = data.payload;

      pushToDataLayer({
        event: "tutorials",
        gaEventCategory: "Tutorials",
        gaEventAction: "Tutorial Selected",
        gaEventLabel: name, // e.g "Live Streaming"
        module: "Tutorials",
        tutorialCategory, // e.g "Live Streaming"
        paginationNumber: undefined,
        totalPages: undefined
      });
    }
  );

  // When a tutorial screen loads
  mediatorChannels.base.subscribe(
    "FDR_TUTORIALS:LOAD_TUTORIAL",
    (data: LoadTutorialGtmProps) => {
      const { title, tutorialCategory, pageNumber, totalPages } = data.payload;

      pushToDataLayer({
        event: "education",
        gaEventCategory: "Education",
        gaEventAction: "Tutorial Viewed",
        gaEventLabel: title, // e.g "Racing Odds"
        tutorialCategory, // e.g "How to win"
        module: "Tutorials",
        paginationNumber: pageNumber, // n of page active
        totalPages, // total number of pages in tutorial
        articleName: title
      });
    }
  );

  // When a user clicks the "Next" CTA
  mediatorChannels.base.subscribe(
    "FDR_TUTORIALS:NEXT",
    (data: NextTutorialGtmProps) => {
      const { buttonText, linkText, tutorialCategory, pageNumber, totalPages } =
        data.payload;

      pushToDataLayer({
        event: "tutorials",
        gaEventCategory: "Tutorials",
        gaEventAction: "Tutorial CTA Clicked",
        gaEventLabel: buttonText, // e.g "Next"
        linkText, // e.g "Next"
        tutorialCategory, // e.g "How to win"
        module: "Tutorials",
        paginationNumber: pageNumber, // n of page active
        totalPages // total number of pages in tutorial
      });
    }
  );

  // When a user clicks the x button to close the tutorial
  mediatorChannels.base.subscribe(
    "FDR_TUTORIALS:CLOSE",
    (data: CloseTutorialGtmProps) => {
      const { title, tutorialCategory, pageNumber, totalPages } = data.payload;

      pushToDataLayer({
        event: "tutorials",
        gaEventCategory: "Tutorials",
        gaEventAction: "Tutorial Closed",
        gaEventLabel: title, // e.g "Racing Odds"
        tutorialCategory, // e.g "How to win"
        module: "Tutorials",
        paginationNumber: pageNumber, // n of page active
        totalPages // total number of pages in tutorial
      });
    }
  );
};
