export const initialState = {
  favoriteTracks: [],
  wagerProfile: "PORT-Generic",
  accountNumber: "",
  shouldUpdate: false,
  openTracks: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "TRACKSAZ_TOGGLE_TRACK": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    default: {
      return state;
    }
  }
}
