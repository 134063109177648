import React from "react";
import MobileHeader from "@tvg/mobile-header";

import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import ApolloContext from "@tvg/utils/apolloContext";
import { buildSignUpUrl } from "../utils/config";

const Header = ({
  title,
  logoMod,
  openRaceNavigation,
  wagerProfile,
  trackCode,
  raceNumber,
  isLogged,
  isBalanceShown,
  balance,
  isVisible,
  isNavigationShown,
  isVideoButtonShown,
  isActionToolsShown,
  isLogActionsShown,
  isTitleCenter = false,
  videoFeedback,
  onVideoButton,
  device,
  currentPath,
  prevPath,
  headerClass,
  isContentCardsInboxShown,
  children,
  currentRace,
  description,
  enablePawsQuickDeposits
}) => (
  <ApolloContext.Consumer>
    {(value) => (
      <Containers.Header
        device={device}
        isVisible={isVisible}
        className={headerClass}
        hasDescription={!!description}
      >
        <MobileHeader
          title={title}
          logoMod={logoMod}
          signUpUrl={buildSignUpUrl()}
          prevPath={prevPath}
          currentPath={currentPath}
          loginUrl={`${currentPath}#login`}
          quickDepositUrl={
            !enablePawsQuickDeposits
              ? `${currentPath}#deposit`
              : `${currentPath}#quick-deposit`
          }
          trackCode={trackCode}
          raceNumber={raceNumber}
          isLogged={isLogged}
          wagerProfile={wagerProfile}
          isBalanceShown={isBalanceShown}
          balance={balance}
          isNavigationShown={isNavigationShown}
          openRaceNavigation={openRaceNavigation}
          isVideoButtonShown={isVideoButtonShown}
          isActionToolsShown={isActionToolsShown}
          isLogActionsShown={isLogActionsShown}
          isTitleCenter={isTitleCenter}
          layout={device === "mobile" ? "normal" : "taller"}
          videoFeedback={videoFeedback}
          onVideoButton={onVideoButton}
          device={device}
          headerClass={headerClass}
          isContentCardsInboxShown={isContentCardsInboxShown}
          currentRace={currentRace}
          behgClient={value.behgClient}
          description={description}
        >
          {children}
        </MobileHeader>
      </Containers.Header>
    )}
  </ApolloContext.Consumer>
);

export default Header;
