import React, { useContext } from "react";
import {
  StyledContainer,
  StyledRadio,
  StyledLabelContainer
} from "./styled-components";
import { Paragraph } from "../Typography";
import { RadioGroup, GroupContext, type RadioGroupProps } from "./RadioGroup";
import type { RadioProps, RadioCompoundComponent } from "./types";

export const Radio = (({
  value,
  label,
  isInvalid = false,
  qaLabel = value,
  ...rest
}: RadioProps) => {
  const {
    onChange,
    isDisabled = false,
    selectedValue
  } = useContext(GroupContext);

  const selectHandler = () => {
    if (isDisabled) return;
    onChange(value);
  };

  return (
    <StyledContainer data-qa-label={qaLabel} {...rest}>
      <StyledRadio
        type="radio"
        name={selectedValue}
        disabled={isDisabled}
        checked={selectedValue === value}
        onClick={selectHandler}
        data-invalid={isInvalid}
        aria-label="radio"
        aria-checked={selectedValue === value}
        data-qa-label={`${qaLabel}-input`}
      />
      {label !== "" && (
        <StyledLabelContainer onClick={selectHandler}>
          <Paragraph
            qaLabel={`${qaLabel}-label`}
            fontFamily="regular"
            color={
              isDisabled
                ? "--fd-colors-content-disabled"
                : "--fd-colors-content-default"
            }
            fontSize="s"
          >
            {label}
          </Paragraph>
        </StyledLabelContainer>
      )}
    </StyledContainer>
  );
}) as RadioCompoundComponent<typeof RadioGroup>;

export type { RadioProps, RadioGroupProps };
Radio.Group = RadioGroup;
