import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type BetConfirmData = {
  type: "BET:BET_CONFIRM";
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
  };
};

type BetConfirmErrorData = {
  type: "BET:BET_ERROR";
  payload: {
    error: number;
    raceNumber?: string;
    trackName?: string;
    selections: string;
    betAmount: number;
    runnerAmount: number;
    betType: string;
  };
};

type BetConfirmSuccessData = {
  type: "BET:BET_SUCCESS";
  payload: {
    raceNumber?: string;
    trackName?: string;
    selections: string;
    betAmount: number;
    runnerAmount: number;
    repeatBet?: number;
    betId: string;
    betType: string;
    isRepeat: boolean;
  };
};

export default () => {
  mediatorChannels.base.subscribe(
    "BET:BET_CONFIRM",
    (data: BetConfirmData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Confirm",
        gaEventLabel: undefined,
        module: "Bet Modal",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionsList: undefined,
        sport: "Horse Racing",
        betAmount: data.payload.betAmount,
        runnerAmount: undefined,
        betId: undefined,
        betType: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BET:BET_ERROR",
    (data: BetConfirmErrorData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Error",
        gaEventLabel: data.payload.error,
        module: "Bet Modal",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionsList: data.payload.selections,
        sport: "Horse Racing",
        betAmount: data.payload.betAmount,
        runnerAmount: data.payload.runnerAmount,
        betId: undefined,
        betType: data.payload.betType
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BET:BET_SUCCESS",
    (data: BetConfirmSuccessData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Success",
        gaEventLabel: data.payload.isRepeat,
        module: "Bet Modal",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: data.payload.repeatBet,
        runnerSelectionsList: data.payload.selections,
        sport: "Horse Racing",
        betAmount: data.payload.betAmount,
        runnerAmount: data.payload.runnerAmount,
        betId: data.payload.betId,
        betType: data.payload.betType
      });
    }
  );
};
