export const initialState = {
  location: {
    latitude: null,
    longitude: null
  },
  regions: [
    {
      description: "",
      id: 0,
      name: "Default",
      type: "Default"
    }
  ],
  state: ""
};

const geoLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GEO_LOCATION_GET": {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default geoLocationReducer;
