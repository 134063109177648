import React from "react";
import type { LinkCardListProps } from "./types";

export const LinkCardList = ({ children, qaLabel }: LinkCardListProps) => {
  const listElements = React.Children.toArray(children);

  return (
    <div data-qa-label={qaLabel}>
      {listElements.map(
        (elem, index) =>
          React.isValidElement(elem) &&
          React.cloneElement(elem, {
            key: `list-${index.toString()}`
          })
      )}
    </div>
  );
};

export type { LinkCardListProps };
