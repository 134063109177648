export const EVENT_NAME = {
  START: "Login Start",
  ATTEMPT: "Login Attempt",
  SUCCESS: "Login Success",
  REGISTRATION_MODAL_OPEN: "Registration Modal Opened",
  ERROR: "Login Error",
  OPEN: "Open",
  FIELD_COMPLETE: "Field Complete",
  SELECT_STATE: "Select State",
  FORGOT_CREDENTIALS: "Forgot Password Clicked",
  LOCATION_MODAL_VIEWED: "Login Location Modal Viewed",
  LOCATION_MODAL_CLOSED: "Login Location Modal Closed",
  LOCATION_MODAL_CTA_CLICKED: "Login Location Modal CTA clicked",
  LOGIN_SERVICE_LOADED: "Login Service Loaded",
  LOGIN_MODAL_OPEN: "Login Modal Opened",
  LOGIN_ERROR_VIEW: "Login Default Error Modal Viewed",
  LOGIN_ERROR_CTA_CLICKED: "Login Default Error Modal CTA clicked",
  LOGIN_ERROR_CLOSED: "Login Default Error Modal Closed"
};
