import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import pushToDataLayer, { getProduct } from "../gtmUtils";

export default () => {
  const productVersion = getProduct(tvgConf().product.toLowerCase());
  const { siteVersion } = tvgConf().gaConfig();

  mediatorChannels.base.subscribe("PENDING_WITHDRAWALS_OPEN", (data) => {
    pushToDataLayer({
      event: "withdrawal",
      gaEventCategory: "Withdrawal",
      gaEventAction: "Open",
      gaEventLabel: "Pending Withdrawals",
      gaEventValue: undefined,
      module: "More",
      amountOption: "No",
      customerStatus: undefined,
      accountId: get(data, "payload.accountId"),
      siteVersion,
      productVersion,
      depositType: undefined,
      depositAmount: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PENDING_WITHDRAWALS_CLOSE", (data) => {
    pushToDataLayer({
      event: "withdrawal",
      gaEventCategory: "Withdrawal",
      gaEventAction: "Close",
      gaEventLabel: "Pending Withdrawals",
      gaEventValue: undefined,
      module: "Pending Withdrawals",
      amountOption: "No",
      customerStatus: undefined,
      accountId: get(data, "payload.accountId"),
      siteVersion,
      productVersion,
      depositType: undefined,
      depositAmount: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "PENDING_WITHDRAWALS_CANCEL_SUCCESS",
    (data) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventCategory: "Withdrawal",
        gaEventAction: "Cancel Withdrawal Success",
        gaEventLabel: "Pending Withdrawals",
        gaEventValue: get(data, "payload.withdrawalAmount"),
        module: "Cancel Withdraw",
        amountOption: "No",
        customerStatus: undefined,
        accountId: get(data, "payload.accountId"),
        siteVersion,
        productVersion,
        depositType: get(data, "payload.withdrawalType"),
        depositAmount: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PENDING_WITHDRAWALS_CANCEL_ERROR",
    (data) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventCategory: "Withdrawal",
        gaEventAction: "Cancel Withdrawal Error",
        gaEventLabel: "Pending Withdrawals",
        gaEventValue: get(data, "payload.withdrawalAmount"),
        module: "Cancel Withdraw",
        amountOption: "No",
        customerStatus: undefined,
        accountId: get(data, "payload.accountId"),
        siteVersion,
        productVersion,
        depositType: get(data, "payload.withdrawalType"),
        depositAmount: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PENDING_WITHDRAWALS_CANCEL_ATTEMPT",
    (data) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventCategory: "Withdrawal",
        gaEventAction: "Cancel Withdrawal Attempt",
        gaEventLabel: "Pending Withdrawals",
        gaEventValue: get(data, "payload.withdrawalAmount"),
        module: "Cancel Withdraw",
        amountOption: "No",
        customerStatus: undefined,
        accountId: get(data, "payload.accountId"),
        siteVersion,
        productVersion,
        depositType: get(data, "payload.withdrawalType"),
        depositAmount: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PENDING_WITHDRAWALS_CLOSE_CONFIRMATION",
    (data) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventCategory: "Withdrawal",
        gaEventAction: "Close",
        gaEventLabel: "Pending Withdrawals",
        gaEventValue: get(data, "payload.withdrawalAmount"),
        module: "Cancel Withdraw",
        amountOption: "No",
        customerStatus: undefined,
        accountId: get(data, "payload.accountId"),
        siteVersion,
        productVersion,
        depositType: get(data, "payload.withdrawalType"),
        depositAmount: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("PENDING_WITHDRAWALS_CALL_CS", (data) => {
    pushToDataLayer({
      event: "withdrawal",
      gaEventCategory: "Withdrawal",
      gaEventAction: "Call Customer Services",
      gaEventLabel: "Pending Withdrawals",
      gaEventValue: get(data, "payload.withdrawalAmount"),
      module: "Cancel Withdraw",
      amountOption: "No",
      customerStatus: undefined,
      accountId: get(data, "payload.accountId"),
      siteVersion,
      productVersion,
      depositType: get(data, "payload.withdrawalType"),
      depositAmount: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "PENDING_WITHDRAWALS_CANCEL_CLICK",
    (data) => {
      pushToDataLayer({
        event: "withdrawal",
        gaEventCategory: "Withdrawal",
        gaEventAction: "Delete",
        gaEventLabel: "Pending Withdrawals",
        gaEventValue: get(data, "payload.withdrawalAmount"),
        module: "Pending Withdrawals",
        amountOption: "No",
        customerStatus: undefined,
        accountId: get(data, "payload.accountId"),
        siteVersion,
        productVersion,
        depositType: get(data, "payload.withdrawalType"),
        depositAmount: undefined,
        microApp: "non-Microapp"
      });
    }
  );
};
