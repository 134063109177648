const Routes = [
  {
    key: "home",
    name: "Home",
    path: "/"
  },
  {
    key: "tracks",
    name: "Tracks",
    path: "/races"
  },
  {
    key: "tracks",
    name: "Tracks",
    path: "/tracks"
  },
  {
    key: "results",
    name: "Results",
    path: "/results"
  },
  {
    key: "live",
    name: "Live",
    path: "/live"
  },
  {
    key: "redirectEngine",
    name: "redirectEngine",
    path: "/redirectEngine"
  },
  {
    key: "picks",
    name: "Picks",
    path: "/handicapping/talent-picks"
  },
  {
    key: "more",
    name: "More",
    path: "/more"
  },
  {
    key: "trackList",
    name: "trackList",
    path: "/racetracks",
    toggle: "enableSeoTrackInfoPage"
  },
  {
    key: "programPage",
    path: "/racetracks/:trackAbbr/:trackName"
  },
  {
    key: "programPageOld",
    path: "/racetracks/:trackAbbr/:trackName/:raceNumber"
  },
  {
    key: "liveProgramPage",
    path: "/live/:channel/:trackAbbr/:trackName"
  },
  {
    key: "liveProgramPageOld",
    path: "/live/:channel/:trackAbbr/:trackName/:raceNumber"
  },
  {
    key: "programPageGreyhounds",
    path: "/greyhounds/:trackAbbr/:trackName"
  },
  {
    key: "programPageGreyhoundsOld",
    path: "/greyhounds/:trackAbbr/:trackName/:raceNumber"
  },
  {
    key: "promosPage",
    path: "/promos/*",
    toggle: "enablePromosStoryblok"
  },
  {
    key: "wagerRewards",
    path: "/wager-rewards",
    toggle: "wagerRewardsApp"
  },
  {
    key: "referFriendPage",
    path: "/referral",
    toggle: "enableReferFriend"
  },
  {
    key: "optedin",
    path: "/opted-in"
  },
  {
    key: "accountSummary",
    path: "/my-account-summary"
  },
  {
    key: "registration",
    path: "/registration"
  },
  {
    key: "resetCredentials",
    path: "/credentials-reset"
  },
  {
    key: "responsibleGaming",
    path: "/responsible-gaming"
  },
  {
    key: "rgDepositLimits",
    path: "/responsible-gaming/deposit-limits"
  },
  {
    key: "RGFundingControls",
    path: "/responsible-gaming/funding-controls"
  },
  {
    key: "rgSelfExclude",
    path: "/responsible-gaming/self-exclude"
  },
  {
    key: "rgSuspendAccount",
    path: "/responsible-gaming/suspend-account"
  },
  {
    key: "rgTimeout",
    path: "/responsible-gaming/timeout"
  },
  {
    key: "wallet",
    path: "/wallet"
  },
  {
    key: "emailUnsubscribe",
    path: "/unsubscribe",
    toggle: "useEmailUnsubscribe"
  },
  {
    key: "preferences",
    path: "/preferences",
    toggle: "usePrefReact"
  },
  {
    key: "preferencesTalentAlerts",
    path: "/preferences/talent-alerts",
    toggle: "usePrefReact"
  },
  {
    key: "default",
    path: "/"
  },
  {
    key: "redirect",
    path: "*"
  }
];

export default Routes;
