import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("REPEAT_BETS:LINK_CLICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "Repeat Bet",
      module: get(data, "payload.module"),
      tag: get(data, "payload.tag"),
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:CLOSE_ACTION", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Repeat Bet",
      module: "Repeat Bet Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:CONFIRM_BET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Confirm",
      gaEventLabel: "Repeat Bet",
      module: "Repeat Bet Modal",
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:SET_BETS", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Set Repeats Success",
      gaEventLabel: get(data, "payload.repeatBet", "1"),
      module: "Repeat Bet Modal",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:BACK", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Back Arrow Clicked",
      gaEventLabel: "Return to Confirm Bet Modal",
      module: "Repeat Bet Modal",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:SET_TO_MAX", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Set To Max Repeat Applied",
      gaEventLabel: undefined,
      module: "Repeat Bet Modal",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:SHOW", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Modal Opened",
      gaEventLabel: "Repeat Bet",
      module: "Bet Placed Modal",
      tag: undefined
    });
  });
};
