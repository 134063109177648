import ASEvents from "./modules/AccountSummary";
import PPMAEvents from "./modules/ProgramPage";
import Tracks from "./modules/Tracks";
import LoginEvents from "./modules/LoginModal";
import RaceResults from "./modules/RaceResults";
import Main from "./modules/Main";
import Ios from "./modules/Ios";
import Handicapping from "./modules/Handicaping";
import Video from "./modules/Video";
import MyBets from "./modules/MyBets";
import SocialShare from "./modules/SocialShare";
import BetConfirmation from "./modules/BetConfirmation";
import WatchLive from "./modules/WatchLivePage";
import PoolsInfo from "./modules/PoollsInfo";
import MorePage from "./modules/MorePage";
import Footer from "./modules/Footer";
import Deposits from "./modules/Deposits";
import PendingWithdrawals from "./modules/PendingWithdrawals";
import RaceAlerts from "./modules/RaceAlerts";
import TalentPicks from "./modules/TalentPicks";
import RepeatBets from "./modules/RepeatBets";
import Promos from "./modules/Promos";
import WagerRewards from "./modules/WagerRewards";
import PastPerformances from "./modules/PastPerformances";
import BetPreferences from "./modules/BetPreferences";
import Onboardings from "./modules/Onboardings";
import Tutorials from "./modules/Tutorials";
import PickAlerts from "./modules/Alerts";
import GridFooter from "./modules/GridFooter";
import TopRaces from "./modules/TopRaces";
import TopTracks from "./modules/TopTracks";
import UpcomingRaces from "./modules/UpcomingRaces";
import QuickLinks from "./modules/QuickLinks";
import HomepageTrackList from "./modules/HomepageTrackList";
import TopPools from "./modules/TopPools";
import PreviousWinners from "./modules/PreviousWinners";
import PromoWidgetCalendar from "./modules/PromoWidgetCalendar";
import ResponsibleGaming from "./modules/ResponsibleGaming";
import ReferFriendMain from "./modules/ReferFriendMain";
import PromosStoryblokMain from "./modules/PromosStoryblokMain";
import FCEvents from "./modules/ForgotCredentials";
import Registration from "./modules/Registration";
import Preferences from "./modules/Preferences";
import Braze from "./modules/Braze";
import Paws from "./modules/Paws";
import RaceTracks from "./modules/RaceTracks";
import PromoOnboarding from "./modules/PromoOnboarding";
import Navigation from "./modules/Navigation";
import Selection from "./modules/Selection";
import RacePageView from "./modules/RacePageView";
import BetSlip from "./modules/BetSlip";
import ResultedTabInteractions from "./modules/ResultedTabInteractions";
import BetSlipUndo from "./modules/BetSlipUndo";
import BetConfirmJourney from "./modules/BetConfirmJourney";
import WagerPad from "./modules/WagerPad";
import Alchemer from "./modules/Alchemer";
import TutorialsFdr from "./modules/TutorialsFdr";
import BetCancel from "./modules/BetCancel";

ASEvents();
Tracks();
PPMAEvents();
LoginEvents();
RaceResults();
Main();
Ios();
Handicapping();
Video();
MyBets();
SocialShare();
BetConfirmation();
PoolsInfo();
MorePage();
Footer();
Deposits();
WatchLive();
PendingWithdrawals();
RaceAlerts();
TalentPicks();
RepeatBets();
Promos();
WagerRewards();
PastPerformances();
BetPreferences();
Onboardings();
Tutorials();
PickAlerts();
GridFooter();
TopRaces();
TopTracks();
UpcomingRaces();
QuickLinks();
HomepageTrackList();
TopPools();
PreviousWinners();
PromoWidgetCalendar();
ResponsibleGaming();
ReferFriendMain();
PromosStoryblokMain();
FCEvents();
Registration();
Preferences();
Braze();
Paws();
RaceTracks();
PromoOnboarding();
Navigation();
Selection();
RacePageView();
BetSlip();
ResultedTabInteractions();
BetSlipUndo();
BetConfirmJourney();
WagerPad();
Alchemer();
TutorialsFdr();
BetCancel();
