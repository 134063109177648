import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("BET_PREFERENCES:SAVE_CHANGES", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Save changes",
      gaEventLabel: {
        betType: data.payload.betType,
        betAmountOption: data.payload.betAmountOption
      },
      module: "Default bet",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "BET_PREFERENCES:NAVIGATE_MORE_PAGE",
    (data) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: "More Menu",
        menu: undefined,
        module: "Default bet",
        sport: undefined,
        microApp: "non-Microapp",
        tag: get(data, "payload.tag"),
        destinationUrl: "/account"
      });
    }
  );
};
