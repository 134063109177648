import React, { useEffect } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get } from "lodash";
import usePrevious from "@tvg/sh-lib-my-bets/hooks/usePrevious";
import RaceQuery from "./graphql/queries/HeaderQuery";
import ApolloOptions from "./graphql/options.graph";
import HeaderComp from "./HeaderComponent";

export const Header = (props) => {
  const { isLogged, behgClient } = props;
  const previousIsLogged = usePrevious(isLogged);
  const filteredCards = props.brazeContentCards.cards.filter(
    (card) => card.sc !== "ab-control-card" && !card.viewed
  );

  useEffect(() => {
    if (
      previousIsLogged &&
      !isLogged &&
      behgClient &&
      behgClient.subscriptionClient
    ) {
      // Close Behg websocket with old session headers
      behgClient.subscriptionClient.close();
    }
  }, [isLogged, previousIsLogged]);

  return (
    <HeaderComp
      {...props}
      isContentCardsInboxShown={
        props.isContentCardsInboxShown && props.headerContentCardsInboxToggle
      }
      unviewedContentCardsCount={filteredCards.length}
    />
  );
};

export default connect(
  (store) => ({
    headerContentCardsInboxToggle: get(
      store,
      "capi.featureToggles.headerContentCardsInbox",
      false
    ),
    brazeContentCards: get(store, "brazeData.brazeContentCards", {})
  }),
  (dispatch) => ({ dispatch })
)(graphql(RaceQuery, ApolloOptions)(Header));
