import React from "react";
import ApolloContext from "@tvg/utils/apolloContext";
import BetCancel from "@tvg/bet-cancel";

export const BetCancelModal = ({ transitionState }) => (
  <ApolloContext.Consumer>
    {(value) => (
      <BetCancel
        transitionState={transitionState}
        behgClient={value.behgClient}
      />
    )}
  </ApolloContext.Consumer>
);

export default BetCancelModal;
