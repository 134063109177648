import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  QUICK_PICKS_CARD_CTA_CLICKED = "QP:QUICK_PICKS_CARD_CTA_CLICKED"
}
export interface QuickPicksCTAEventProps {
  raceNumber?: string;
  trackName?: string;
  position: number;
  linkUrl?: string;
  linkText?: string;
}

export interface QuickPicksData {
  type: MediatorEventType;
  payload: QuickPicksCTAEventProps;
}
