import styled from "styled-components";
import { fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const ContentCardsContainer = styled.div`
  background-color: ${buildColor("white", "100")};
  overflow-x: hidden;

  .ab-feed {
    width: 100%;
    border-radius: 0;
    box-shadow: none;

    .ab-feed-buttons-wrapper {
      display: none;
    }

    .ab-feed-body {
      padding: 0;
      border: 0;
    }

    .ab-card {
      margin-bottom: 0;
      border: 0;
      border-bottom: 1px solid ${buildColor("blue", "000")};
      border-radius: 0;
      z-index: 1001;
      position: relative;

      &:last-child {
        border: 0;
      }

      &:active {
        background-color: ${buildColor("blue_accent", "100")};

        .ab-unread-indicator.read,
        .ab-unread-indicator {
          background-color: transparent;
        }
      }
    }

    .ab-card a {
      position: relative;
      z-index: 1;
    }

    .ab-card .ab-image-area {
      position: relative;
      z-index: 1;
    }

    .ab-card.ab-classic-card.with-image .ab-image-area {
      width: 42px;
      height: 42px;
      padding: 18px 0 18px 12px;
      text-align: center;
    }

    .ab-card.ab-classic-card.with-image .ab-image-area img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .ab-card .ab-card-body {
      z-index: 1;
    }

    .ab-card.ab-classic-card .ab-card-body {
      padding-left: 54px;
      z-index: 1;
    }

    .ab-card.ab-classic-card.with-image .ab-card-body {
      padding-left: 54px;
    }

    .ab-card .ab-title,
    .ab-card.ab-classic-card.with-image .ab-title {
      padding: 18px 12px 0;
      font-size: 14px;
      font-family: ${fontMedium};
      color: ${buildColor("grey", "900")};
      font-weight: inherit;
    }

    .ab-card .ab-description,
    .ab-card.ab-classic-card.with-image .ab-description {
      padding: 4px 12px 18px;
      line-height: 18px;
      font-family: ${fontNormal};
      font-size: 14px;
      color: ${buildColor("grey", "700")};
    }

    .ab-card .ab-url-area {
      margin-top: 8px;
      font-family: ${fontNormal};
      font-size: 14px;
      color: ${buildColor("blue_accent", "500")};
    }

    .ab-card .ab-pinned-indicator {
      border: 0;
      width: 12px;
      height: 14px;
      top: 18px;
      right: 13px;
      z-index: 100;
    }

    .ab-card .ab-pinned-indicator .fa-star {
      color: ${buildColor("yellow", "400")};
      right: 0;
      font-size: 13px;
    }

    .ab-card .ab-unread-indicator {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 0;
      background-color: ${buildColor("blue_accent", "000")};
    }

    .ab-card .ab-unread-indicator.read {
      background-color: ${buildColor("white", "100")};
      display: none;
    }
  }

  .ab-captioned-image {
    .ab-card-body {
      background-color: ${buildColor("blue", "900")};

      .ab-title,
      .ab-card.ab-classic-card.with-image .ab-title {
        color: ${buildColor("white", "100")};
      }

      .ab-description,
      .ab-classic-card.with-image .ab-description {
        color: ${buildColor("blue", "000")};

        .ab-url-area a {
          color: ${buildColor("blue_accent", "300")};
        }
      }
    }
  }
`;
