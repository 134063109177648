import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EVENT_NAME, LABELS, MODULE } from "./constants";
import {
  MediatorEventType,
  EventProps,
  EventData,
  ModuleEventProps
} from "./types";
import { BaseEventProps } from "../../types";
import { track } from "../../amplitude";
import { COMMON_EVENT_NAMES } from "../../constants";

const getEventModule = (module?: string) =>
  module?.toLowerCase().replace(" ", "_") === MODULE ? MODULE : module;

export default () => {
  mediator.base.subscribe(MediatorEventType.BET_ERROR, (data: EventData) => {
    track<BaseEventProps>(EVENT_NAME.BET.ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.ERROR_TYPE]: get(data, "payload.error"),
      [BaseEventProps.MODULE]: getEventModule(data.payload.module),
      [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
      [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount")
    });
  });

  mediator.base.subscribe(MediatorEventType.BET_CONFIRM, (data: EventData) => {
    track<BaseEventProps>(EVENT_NAME.BET.CONFIRM, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: MODULE,
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
      [BaseEventProps.SELECTION_SOURCE]: get(data, "payload.selectionSource")
    });
  });

  mediator.base.subscribe(MediatorEventType.BET_RETRY, (data: EventData) => {
    track<BaseEventProps>(EVENT_NAME.BET.RETRY, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: MODULE,
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
    });
  });

  mediator.base.subscribe(MediatorEventType.CLOSE_MODAL, (data: EventData) => {
    track<BaseEventProps>(
      `${
        get(data, "payload.confirmMode")
          ? LABELS.CONFIRM_MODAL
          : LABELS.BET_SUCCESS
      } ${EVENT_NAME.CLOSE_MODAL}`,
      {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.confirmMode")
          ? "Confirm Bet Modal"
          : "Bet Success Modal",
        [BaseEventProps.MODULE]: get(data, "payload.confirmMode")
          ? "Confirm Bet Modal"
          : "Bet Success Modal"
      }
    );
  });

  mediator.base.subscribe(MediatorEventType.BET_SUCCESS, (data: EventData) => {
    track<BaseEventProps>(EVENT_NAME.BET.SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: getEventModule(data.payload.module),
      [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"), // Concatenate values fron left to right e.g '1,5'
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_ID]: get(data, "payload.betId"),
      [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
      [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet", 0),
      [BaseEventProps.RUNNER_AMOUNT]: get(data, "payload.runnerAmount"),
      [BaseEventProps.SELECTION_SOURCE]: get(data, "payload.selectionSource")
    });
  });

  mediator.base.subscribe(
    MediatorEventType.RETAIN_RUNNERS,
    (data: EventData) => {
      track<EventProps>(EVENT_NAME.RETAIN_RUNNERS, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [ModuleEventProps.PLACE_TYPE]: "Retained Runners",
        [BaseEventProps.MODULE]: "Bet Success Modal",
        [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
        [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet")
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.BET_TIMEOUT, (data: EventData) => {
    track<BaseEventProps>(EVENT_NAME.BET.TIMEOUT, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: getEventModule(data.payload.module), // 'Race Card' or 'Bet Confirmation Modal',
      [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
      [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet")
    });
  });
};
