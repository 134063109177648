import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type PromosOnboardingPayload = {
  type: "PROMOS_ONBOARDING:CTA_CLICK";
  payload: {
    link: string;
    promoId?: string;
    promoName?: string;
  };
};

export default () => {
  mediatorChannels.base.subscribe(
    "PROMOS_ONBOARDING:CTA_CLICK",
    (data: PromosOnboardingPayload): void => {
      pushToDataLayer({
        event: "promos",
        gaEventAction: "CTA Clicked",
        gaEventCategory: "Promos",
        gaEventLabel: data?.payload?.link || "",
        module: "Progress Bar",
        promoId: data?.payload?.promoId,
        promoName: data?.payload?.promoName,
        tag: undefined
      });
    }
  );
};
