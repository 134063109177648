export const initialState = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  preferences: {},
  gotPreferences: false,
  gotPromos: false,
  optedInPromos: {},
  wasLogin: false,
  balance: null,
  promoFunds: 0,
  balancePoller: 120,
  returningUser: false
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_BALANCE_SUCCESS": {
      return { ...state, ...action.payload };
    }
    case "USER_PROMO_FUNDS_SUCCESS": {
      return { ...state, ...action.payload };
    }
    case "USER_PREFS_SET": {
      return {
        ...state,
        preferences: action.payload.preferences,
        gotPreferences: action.payload.gotPreferences
      };
    }
    case "USER_PROMOS_SUCCESS": {
      return { ...state, ...action.payload, gotPromos: true };
    }
    case "USER_PROMOS_FAIL": {
      return { ...state, gotPromos: true };
    }
    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged };
    }
    case "USER_RETURNING_STATE": {
      return { ...state, returningUser: action.payload.returningUser };
    }
    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }
    case "USER_PREFS_BALANCE_SHOWN": {
      return {
        ...state,
        preferences: { ...state.preferences, balance_is_shown: action.payload }
      };
    }
    case "SET_DEFAULT_BET_PREFERENCES": {
      return {
        ...state,
        preferences: { ...state.preferences, bet_prefs: action.payload }
      };
    }
    case "USER_PREFS_BET_CONFIRMATION": {
      return {
        ...state,
        preferences: { ...state.preferences, show_bet_confirm: action.payload }
      };
    }
    case "SET_NEW_PREFERENCE_VALUE": {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.preferenceKey]: action.payload.preferenceValue
        }
      };
    }
    case "USER_BALANCE_POLLER": {
      return { ...state, balancePoller: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
