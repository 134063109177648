import React from "react";
import { Header, Paragraph } from "../Typography";
import {
  LinkCardButton,
  ContentWrapper,
  Container,
  LeftSideContainer
} from "./styled-components";
import { type LinkCardProps } from "./types";
import { LinkCardList, LinkCardListProps } from "./LinkCardList";

const LinkCard = ({
  startContent,
  title,
  description,
  endContent,
  qaLabel,
  isDisabled = false,
  isLinkItem = true,
  isGrouped = false,
  onPress,
  alignStart = false
}: LinkCardProps) => (
  <LinkCardButton
    disabled={isDisabled}
    isLinkItem={isLinkItem}
    onClick={onPress}
    data-qa-label={qaLabel}
    isGrouped={isGrouped}
  >
    <Container>
      <LeftSideContainer hasEndContent={!!endContent} alignStart={alignStart}>
        {startContent && <div>{startContent}</div>}
        <ContentWrapper>
          {!!title && (
            <Header
              tag="h3"
              data-qa-label={`${qaLabel}-title`}
              color="--fd-colors-content-strong"
              fontSize={14}
              fontFamily="medium"
              fontWeight={500}
            >
              {title}
            </Header>
          )}
          {!!description && (
            <Paragraph
              data-qa-label={`${qaLabel}-description`}
              fontSize={14}
              lineHeight="125%"
              fontFamily="regular"
              fontWeight={400}
            >
              {description}
            </Paragraph>
          )}
        </ContentWrapper>
      </LeftSideContainer>
      {endContent}
    </Container>
  </LinkCardButton>
);

export { LinkCard, LinkCardList };
export type { LinkCardProps, LinkCardListProps };
