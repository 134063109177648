import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type SelectBetAmount = {
  type: "BETSLIP:SELECT_BET_AMOUNT";
  payload: {
    amountSelected: string;
  };
};

type PlaceBetData = {
  type: "BETSLIP:PLACE_BET";
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
  };
};

export default () => {
  mediatorChannels.base.subscribe("BETSLIP:REMOVE_ALL_SELECTIONS", () => {
    pushToDataLayer({
      event: "betslip",
      gaEventCategory: "Betslip",
      gaEventAction: "All Selections Removed",
      gaEventLabel: undefined,
      module: "Bet Ticket",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "BETSLIP:SELECT_BET_AMOUNT",
    (data: SelectBetAmount): void => {
      pushToDataLayer({
        event: "betslip",
        gaEventCategory: "Betslip",
        gaEventAction: "Bet Amount Selected",
        gaEventLabel: data.payload.amountSelected,
        module: "Bet Ticket",
        tag: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BETSLIP:PLACE_BET",
    (data: PlaceBetData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Submitted",
        gaEventLabel: undefined,
        module: "Bet Modal",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionsList: undefined,
        sport: "Horse Racing",
        betAmount: data.payload.betAmount,
        betType: undefined
      });
    }
  );
};
