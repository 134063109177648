import { RaceProgram } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PLACE_TYPE = "Place Type",
  FDTV_TYPE = "FDTV Type",
  RACE_STATUS = "Race Status",
  MTP = "Mtp"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  RUNNER_SELECTION = "PP_RUNNER_SELECTION"
}

export interface SelectBetTypeType {
  type: MediatorEventType;
  payload: {
    eventLabel: string;
    currentRace: RaceProgram;
    betTypeCode: WagerTypeCodesEnum | undefined;
  };
}
