import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("PROMO_SIGNAGE:CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.isOptedIn")
        ? "Promo Signage - Opted In"
        : "Promo Signage - Promo",
      module: get(data, "payload.module"),
      destinationUrl: get(data, "payload.destinationUrl"),
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PROMOS_OPEN_FROM_FOOTER", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      tag: undefined,
      gaEventLabel: "Promos",
      module: "Mobile Footer",
      microApp: "non-Microapp"
    });
  });
};
