import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split
} from "@apollo/client";
import { attempt, get } from "lodash";
import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import fetch from "node-fetch";
import TVGConf from "@tvg/conf";
import { policies } from "@tvg/utils/apolloPolicies";

const getClientURI = (graphClient) => {
  let uri = "";

  switch (graphClient) {
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    case "rda":
      uri = TVGConf().config().service.rda;
      break;
    case "gas":
      uri = TVGConf().config().service.gas;
      break;
    case "behg":
      uri = TVGConf().config().service.behg;
      break;
    default:
      uri = TVGConf().config().service.graph;
      break;
  }

  return uri;
};

const getClientWsUri = (graphClient) => {
  let uri = "";

  switch (graphClient) {
    case "graph":
      uri = TVGConf().config().service.graphWS;
      break;
    case "behg":
      uri = TVGConf().config().service.behgWS;
      break;
    case "gas":
      uri = TVGConf().config().service.gasWS;
      break;
    default:
      uri = "";
      break;
  }

  return uri.replace("https:", "").replace("http:", "").replace("//", "wss://");
};

export default {
  getClient(graphClient = "graph") {
    switch (graphClient) {
      case "rda":
        return this.rdaClient;
      case "fcp":
        return this.fcpClient;
      case "gas":
        return this.gasClient;
      case "behg":
        return this.behgClient;
      default:
        return this.graphClient;
    }
  },

  setClient(client, type) {
    switch (type) {
      case "rda":
        this.rdaClient = client;
        break;
      case "fcp":
        this.fcpClient = client;
        break;
      case "gas":
        this.gasClient = client;
        break;
      case "behg":
        this.behgClient = client;
        break;
      default:
        this.graphClient = client;
        break;
    }
  },

  createClient(ssrMode = false, graphClient = "graph", enableWs = false) {
    const httpLink = createHttpLink({
      fetch,
      uri: getClientURI(graphClient).replace("http", "https"),
      credentials: "include",
      headers: {
        "x-tvg-context": TVGConf().context()
      }
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: (count) => {
        if (count < 25) {
          return 2000 * Math.random();
        }
        return 15000;
      }
    });

    const errorLink = onError(
      // eslint-disable-next-line
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
          return forward(operation);
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
        }
      }
    );

    let link;
    let wsLink;
    if (typeof window === "undefined" || !enableWs) {
      link = errorLink.concat(httpLink);
    } else {
      const wsUri = getClientWsUri(graphClient);

      if (wsUri !== "") {
        wsLink = new WebSocketLink({
          fetch,
          uri: wsUri,
          options: {
            reconnect: graphClient !== "gas",
            lazy: true,
            minTimeout: 5000
          },
          credentials: "include",
          headers: { "x-tvg-context": TVGConf().context() }
        });
      }
      // hack for subscription error
      attempt(() => {
        if (wsLink !== undefined) {
          wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
            get(wsLink, "subscriptionClient.maxConnectTimeGenerator.max");
        }
      });

      const tmplink = wsLink
        ? split(
            // split based on operation type
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
              );
            },
            wsLink,
            httpLink
          )
        : httpLink;

      link = retryLink.concat(errorLink).concat(tmplink);
    }

    const cache = new InMemoryCache(policies);

    if (typeof window !== "undefined") {
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      addTypename: true,
      ssrMode,
      connectDevTools: true
    });

    this.setClient(client, graphClient);

    if (enableWs && wsLink) {
      return {
        ...client,
        subscriptionClient: wsLink.subscriptionClient,
        /* eslint-disable */
        subscribe: client.__proto__.subscribe,
        stop: client.__proto__.stop
        /* eslint-enable */
      };
    }

    return client;
  }
};
