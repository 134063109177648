import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";
import { AlchemerFeedbackWidget } from "../../../urp-lib-alchemer";

export default () => {
  mediatorChannels.base.subscribe(AlchemerFeedbackWidget.OPEN, () => {
    pushToDataLayer({
      event: "betaOnboarding",
      gaEventCategory: "Beta Onboarding",
      gaEventAction: "Feedback Widget Opened",
      gaEventLabel: "Program Page",
      module: "Program Page Feedback Widget"
    });
  });

  mediatorChannels.base.subscribe(AlchemerFeedbackWidget.SUBMIT, () => {
    pushToDataLayer({
      event: "betaOnboarding",
      gaEventCategory: "Beta Onboarding",
      gaEventAction: "Feedback Widget Submitted",
      gaEventLabel: "Program Page",
      module: "Program Page Feedback Widget"
    });
  });
};
