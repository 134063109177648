import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:EXPAND_COLLAPSE",
    (data) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.expandAction"),
        gaEventLabel: "Inline Past Performances",
        module: "Race Card",
        tag: get(data, "payload.runnerId"),
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLICK_PP", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: "Inline Past Performances",
      module: "Inline PP",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLICK_MORE_PAGE", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "All PP Modal",
      module: "Inline PP",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:NAVIGATE_PP", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: `PP Navigation ${get(data, "payload.direction")}`,
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:VIEW_RESULTS", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: "Results",
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:SELECT_PP_MORE_PAGE",
    (data) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "Inline Past Performances",
        module: "All PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLOSE_ALL_PP", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "All PP Modal",
      module: "All PP Modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLOSE_SINGLE_PP", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "PP Modal",
      module: "PP Modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:VIEW_RACECARD", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: "RaceCard",
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:VIEW_BETS", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: "Bets",
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:TOGGLE_CONDITIONS",
    (data) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: `Toggle ${data.payload.toggleState}`,
        gaEventLabel: "Matching Conditions",
        module: "All PP Modal",
        tag: `${get(data, "payload.matchingConditions")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:BACK_TO_ALL", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Back",
      gaEventLabel: undefined,
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:VIEW_REPLAY", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Play",
      gaEventLabel: "Video",
      module: "PP Modal",
      tag: `${get(data, "payload.runnerName")}/${get(
        data,
        "payload.trackName"
      )}/${get(data, "payload.raceNumber")}`,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:HORSE_SELECTOR",
    (data) => {
      pushToDataLayer({
        gaEventAction: "Open",
        gaEventCategory: "Site Click",
        gaEventLabel: "Horse Past Performance Modal",
        module: "All PP Modal",
        tag: get(data, "payload.runnerIndex")
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:SEE_ALL_RUNNERS", () => {
    pushToDataLayer({
      gaEventAction: "Open",
      gaEventCategory: "Site Click",
      gaEventLabel: "See all runners",
      module: "Full-Results",
      tag: undefined
    });
  });
};
