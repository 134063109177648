import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export const MICRO_APP = "Responsible Gaming";
export const BASE_PATH = "responsible-gaming";
export const MODULE_NAME_DEFAULT = "Responsible Gambling";
const TIMEOUT_AND_EXCLUSIONS = {
  TIMEOUT: "Timeout",
  SELF_EXCLUSION: "Self-Exclude",
  SUSPEND: "Suspend Account",
  TO_DEFAULT: "Timeout Exclusions Suspensions"
};
export const moduleName = {
  DEPOSIT_LIMIT: "Deposit Limits",
  FUNDING_CONTROLS: "Funding Controls",
  ...TIMEOUT_AND_EXCLUSIONS
};

const pathToModuleName = (path) => {
  if (!path.includes(BASE_PATH)) return null;

  const pathKeys = path.split("/");
  const pathEnd = pathKeys.pop();

  switch (pathEnd) {
    case "deposit-limits":
      return moduleName.DEPOSIT_LIMIT;
    case "funding-controls":
      return moduleName.FUNDING_CONTROLS;
    case "timeout":
      return moduleName.TIMEOUT;
    case "self-exclude":
      return moduleName.SELF_EXCLUSION;
    case "suspend-account":
      return moduleName.SUSPEND;
    default:
      return MODULE_NAME_DEFAULT;
  }
};

export default () => {
  mediatorChannels.base.subscribe("RG_HOME_CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.gaEventLabel", ""),
      module: MODULE_NAME_DEFAULT,
      microApp: "non-Microapp",
      destinationUrl: get(data, "payload.destinationUrl", "")
    });
  });

  mediatorChannels.base.subscribe("RG_OPTION_SELECT_HANDLE", (data) => {
    const moduleKey = get(data, "payload.module", "");
    const gaEventLabel = moduleName[moduleKey];
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel,
      module: MODULE_NAME_DEFAULT,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("HEADER_GO_BACK", (data) => {
    const locationPath = get(data, "payload.locationPathname", "");
    const module = pathToModuleName(locationPath);

    if (module) {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: get(data, "payload.gaEventLabel", ""),
        module,
        microApp: "non-Microapp",
        destinationUrl: get(data, "payload.destinationUrl", "")
      });
    }
  });

  mediatorChannels.base.subscribe("RG_BLOCK_MODAL_OPEN", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "block",
      gaEventLabel: get(data, "payload.gaEventLabel", ""),
      module: moduleName.FUNDING_CONTROLS
    });
  });

  mediatorChannels.base.subscribe("RG_MODAL_CLICK_HANDLE", (data) => {
    const moduleKey = get(data, "payload.module", "");
    const module = TIMEOUT_AND_EXCLUSIONS[moduleKey]
      ? TIMEOUT_AND_EXCLUSIONS.TO_DEFAULT
      : moduleName[moduleKey];
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "select",
      gaEventLabel: get(data, "payload.gaEventLabel", ""),
      module: `${module} modal`
    });
  });

  mediatorChannels.base.subscribe("RG_TIMEOUT_CLICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "submit request",
      gaEventLabel: get(data, "payload.gaEventLabel", ""),
      module: moduleName.TO_DEFAULT
    });
  });

  mediatorChannels.base.subscribe("RG_DEPOSIT_LIMIT_CLICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "apply limit",
      gaEventLabel: get(data, "payload.gaEventLabel", ""),
      module: moduleName.DEPOSIT_LIMIT
    });
  });
};
