import TVGProvider from "./TVGProvider";
import Authentication from "./Authentication";
import InfoScreen from "./InfoScreen";
import PromoCode from "./PromoCode";
import OtherInteractions from "./OtherInteractions";
import InputRelated from "./InputRelated";
import FormCompletion from "./FormCompletion";
import PageView from "./PageView";

const Registration = () => {
  Authentication();
  InfoScreen();
  TVGProvider();
  PromoCode();
  OtherInteractions();
  InputRelated();
  FormCompletion();
  PageView();
};

export default Registration;
