export const openLoginModal = (loginComponent) => ({
  type: "OPEN_LOGIN_MODAL_V1",
  payload: loginComponent
});

export const closeLoginModal = () => ({
  type: "CLOSE_LOGIN_MODAL_V1"
});

export const openDepositsModal = (payload) => ({
  type: "OPEN_DEPOSITS_MODAL",
  payload
});

export const closeDepositsModal = () => ({
  type: "CLOSE_DEPOSITS_MODAL"
});

export const openPasswordRecoveryModal = (payload) => ({
  type: "OPEN_PASSWORD_RECOVERY_MODAL",
  payload
});

export const closePasswordRecoveryModal = () => ({
  type: "CLOSE_PASSWORD_RECOVERY_MODAL"
});

export const openPromosModal = () => ({
  type: "OPEN_PROMOS_MODAL"
});

export const closePromosModal = () => ({
  type: "CLOSE_PROMOS_MODAL"
});

export const openEmailReferralModal = () => ({
  type: "OPEN_EMAIL_REFERRAL_MODAL"
});

export const closeEmailReferralModal = () => ({
  type: "CLOSE_EMAIL_REFERRAL_MODAL"
});

export const openNavigationModal = (contentType) => ({
  type: "OPEN_NAVIGATION_MODAL",
  payload: contentType
});

export const closeNavigationModal = () => ({
  type: "CLOSE_NAVIGATION_MODAL",
  payload: ""
});

export const closePendingWithdrawalsModal = () => ({
  type: "CLOSE_PENDING_WITHDRAWALS_MODAL"
});

export const openPendingWithdrawalsModal = () => ({
  type: "OPEN_PENDING_WITHDRAWALS_MODAL"
});

export const closeBetPrefsModal = () => ({
  type: "CLOSE_BET_PREFERENCES_MODAL"
});

export const openBetPrefsModal = () => ({
  type: "OPEN_BET_PREFERENCES_MODAL"
});

export const fixDepositModalForSightlinePrompt = (
  isSightlineRedirectModalOpen
) => ({
  type: "FIX_DEPOSIT_MODAL_SIGHTLINE_PROMPT",
  payload: { isSightlineRedirectModalOpen }
});

export const quickDepositToFullDepositRedirect = () => ({
  type: "QUICK_DEPOSIT_FULL_DEPOSIT_REDIRECT"
});

export const openFeatureTogglesModal = () => ({
  type: "OPEN_FEATURE_TOGGLES_MODAL"
});

export const closeFeatureTogglesModal = () => ({
  type: "CLOSE_FEATURE_TOGGLES_MODAL"
});

export const openSupportModal = () => ({
  type: "OPEN_SUPPORT_MODAL"
});
export const closeSupportModal = () => ({
  type: "CLOSE_SUPPORT_MODAL"
});

export const openContentCardsModal = () => ({
  type: "OPEN_CONTENT_CARDS_MODAL"
});
export const closeContentCardsModal = () => ({
  type: "CLOSE_CONTENT_CARDS_MODAL"
});
