import {
  ConfirmationModalType,
  PreferencesTabEnum,
  AlertTypePref,
  NotificationsSwitchState
} from "../utils/types";
import type { Actions } from "./actions";

export type State = {
  selectedTab: PreferencesTabEnum;
  notifications: NotificationsSwitchState;
  alert: {
    show: boolean;
    type: AlertTypePref;
    isToggling: boolean;
    shouldShowButton: boolean;
  };
  confirmationModal: {
    isOpen: boolean;
    type: ConfirmationModalType;
  };
  muteAllCommunications: boolean;
  isPreferencesModalOpen: boolean;
};

export const initialState: State = {
  selectedTab: PreferencesTabEnum.BETTING,
  notifications: {
    offersPush: false,
    offersEmail: false,
    promoPush: false,
    promoEmail: false,
    talentPicksPush: false,
    otherFanduelPush: false,
    otherFanduelEmail: false
  },
  alert: {
    show: false,
    type: "error",
    isToggling: false,
    shouldShowButton: false
  },
  confirmationModal: {
    isOpen: false,
    type: ""
  },
  muteAllCommunications: true,
  isPreferencesModalOpen: false
};

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SET_SELECTED_TAB":
      return {
        ...state,
        selectedTab: action.payload
      };
    case "SET_NOTIFICATIONS_SWITCH":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.type]: action.payload.value
        }
      };
    case "OPEN_CONFIRMATION_MODAL_PREF":
      return {
        ...state,
        confirmationModal: {
          isOpen: true,
          type: action.payload.type
        }
      };
    case "CLOSE_CONFIRMATION_MODAL_PREF":
      return {
        ...state,
        confirmationModal: {
          ...initialState.confirmationModal
        }
      };
    case "SHOW_ALERT_PREF":
      return {
        ...state,
        alert: {
          ...state.alert,
          show: true,
          type: action.payload.type,
          isToggling: action.payload.isToggling,
          shouldShowButton: action.payload.shouldShowButton
        }
      };
    case "HIDE_ALERT_PREF":
      return {
        ...state,
        alert: {
          ...initialState.alert
        }
      };
    case "SET_MUTE_ALL_COMMUNICATIONS":
      return {
        ...state,
        muteAllCommunications: action.payload
      };
    case "OPEN_PREFERENCES_MODAL":
      return {
        ...state,
        isPreferencesModalOpen: true
      };
    case "CLOSE_PREFERENCES_MODAL":
      return {
        ...state,
        isPreferencesModalOpen: false
      };
    default:
      return state;
  }
}
