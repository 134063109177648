import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("DEPOSITS_OPEN_QUICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "Quick Deposit Modal",
      module: data.payload.module,
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("DEPOSITS_CLOSE_QUICK", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Quick Deposit Modal",
      module: "Quick Deposit Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });
};
