import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("QUICKLINKS:CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: data.payload.linkName,
      module: "Quick Links",
      tag: `${data.payload.position}${
        data.payload.tag ? ` + ${data.payload.tag}` : ""
      }`,
      menu: undefined,
      sport: "Horse Racing",
      destinationUrl: data.payload.fullURL
    });
  });
};
