import React, { type FC } from "react";

import {
  SegmentedControlPill,
  SegmentedControlTab,
  SegmentText
} from "./components";
import { StyledComponent } from "./styled-components";

import { SCContext } from "./context";

import type { SegmentedControlProps } from "./types";

const SegmentedControl: FC<SegmentedControlProps> = ({
  selectedValue,
  size,
  children,
  qaLabel = "segment-control",
  ...rest
}) => (
  <StyledComponent
    data-qa-label={qaLabel}
    role="tablist"
    size={size}
    numSegments={children.length}
    {...rest}
  >
    <SCContext.Provider value={{ selectedValue }}>
      {children}
      <SegmentedControlPill qaLabel={`${qaLabel}-pill`} />
    </SCContext.Provider>
  </StyledComponent>
);

export { SegmentedControl, SegmentedControlTab, SegmentText };
export type { SegmentedControlProps };
