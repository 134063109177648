import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("LIVE_PAGE_NAVIGATION", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.channel", "TVG"),
      module: "TVGTV",
      tag: undefined,
      microApp: "non-Microapp",
      destinationUrl: get(data, "payload.destinationUrl", "")
    });
  });

  mediatorChannels.base.subscribe("MORE_LIVE_PAGE_CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: "TVGTV",
      menu: undefined,
      module: "More",
      sport: undefined,
      tag: undefined,
      microApp: "non-Microapp",
      destinationUrl: get(data, "payload.destinationUrl", "")
    });
  });
};
