import { find } from "lodash";
import PagesConfig from "../Config";

const URL_PARAMETER_REGEX = /:[^:?]+\?/g;
const ANY_WORD_REGEX = "[\\w-]+";

const configDefault = {
  key: "configDefault",
  header: {
    logoMod: "logo",
    isActionToolsShown: true,
    isLogActionsShown: true,
    isVisible: true
  },
  footer: {
    isVisible: true
  }
};

export const buildSignUpUrl = () => {
  let url = "/registration";

  if (typeof window !== "undefined" && !!window.localStorage.getItem("rfr")) {
    url = `${url}?rfr=${window.localStorage.getItem("rfr")}`;
  }
  return url;
};

export default {
  getComponentCfg: (path, queryParameters = "") => {
    const configCustom = find(PagesConfig, (pageCfg) => {
      const queryParametersRegex = pageCfg.queryParameters
        ? `\\?${pageCfg.queryParameters}`
        : "";

      const urlRegex = new RegExp(
        `^${pageCfg.key}${queryParametersRegex}`.replace(
          URL_PARAMETER_REGEX,
          ANY_WORD_REGEX
        ),
        "i"
      );

      return urlRegex.test(`${path}${queryParameters}`);
    });

    return configCustom
      ? {
          ...configDefault,
          ...configCustom,
          header: {
            ...configDefault.header,
            ...configCustom.header
          },
          footer: { ...configDefault.footer, ...configCustom.footer }
        }
      : configDefault;
  }
};
