export const getUserData = (userData) => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const successBalancePollerRequest = (balancePoller) => ({
  type: "USER_BALANCE_POLLER",
  payload: balancePoller
});

export const failBalanceRequest = (err) => ({
  type: "USER_BALANCE_FAIL",
  payload: err,
  error: true
});

export const setUserPrefs = (userPrefs = {}, gotPreferences = false) => ({
  type: "USER_PREFS_SET",
  payload: {
    preferences: userPrefs,
    gotPreferences
  }
});

export const failUserPrefs = (err) => ({
  type: "USER_PREFS_FAIL",
  payload: err,
  error: true
});

export const successUserPromos = (userPromos) => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});

export const failUserPromos = (err) => ({
  type: "USER_PROMOS_FAIL",
  payload: err,
  error: true
});

export const setUserLoggedState = (isLogged) => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setReturningUserState = (returningUser) => ({
  type: "USER_RETURNING_STATE",
  payload: {
    returningUser
  }
});

export const setUserAccountNumber = (accountNumber) => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
