import React, { FC } from "react";
import type {
  TooltipDescriptiveProps,
  TooltipDescriptivePlacement
} from "./types";
import { Popper } from "../Popper";
import Content from "./content";

const CLOSE_DELAY: number = 30000;

export const TooltipDescriptive: FC<TooltipDescriptiveProps> = ({
  children,
  qaLabel,
  placement = "top-mid",
  isDisabled = false,
  isDarkMode = false,
  closeDelay = CLOSE_DELAY,
  forceClose = false,
  onAction = "click",
  layer = 1999,
  isLeftSideRendered = false,
  ...rest
}) => (
  <Popper
    on={onAction}
    placement={placement}
    isDisabled={isDisabled}
    layer={layer}
    forceClose={forceClose}
    closeDelay={closeDelay}
    isLeftSideRendered={isLeftSideRendered}
    content={
      <Content
        data-qa-label={qaLabel}
        placement={placement}
        isDarkMode={isDarkMode}
        {...rest}
      />
    }
  >
    {children}
  </Popper>
);

export type { TooltipDescriptiveProps, TooltipDescriptivePlacement };
export default TooltipDescriptive;
