import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

const BASE_PATH = "my-account-summary";

export default () => {
  mediatorChannels.base.subscribe("AS_OPEN_FILTER", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "filter",
      tag: undefined,
      gaEventLabel: undefined,
      module: "account summary activity list",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_APPLY_FILTER", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "select filter",
      tag: undefined,
      gaEventLabel: get(data, "payload.filter", []).toString(),
      module: "account summary filters",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_CLEAR_FILTER", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "clear filter",
      tag: undefined,
      gaEventLabel: get(data, "payload.filter", []).toString(),
      module: "account summary activity list",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_TIME_FRAME_SELECTION", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "select filter",
      tag: undefined,
      gaEventLabel: get(data, "payload.timeFrame"),
      module: "account summary header",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_DATE_RANGE_SELECTION", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "select filter",
      tag: undefined,
      gaEventLabel: get(data, "payload.gaEventLabel"),
      module: "account summary date selector",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_DOWNLOAD_CLICK", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "download",
      tag: undefined,
      gaEventLabel: undefined,
      module: "account summary header",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_DOWNLOAD_CLOSE", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "close",
      tag: undefined,
      gaEventLabel: undefined,
      module: "download transactions",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_DOWNLOAD_CONFIRM", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: `download as ${get(data, "payload.fileType")}`,
      tag: undefined,
      gaEventLabel: undefined,
      module: "download transactions",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("AS_PAGINATION_CLICK", (data) => {
    const currentPage = get(data, "payload.currentPage");
    const newPage = get(data, "payload.newPage");
    let gaEventAction = "dropdown";

    if (newPage === currentPage - 1) gaEventAction = "previous";
    if (newPage === currentPage + 1) gaEventAction = "next";

    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction,
      tag: undefined,
      gaEventLabel: undefined,
      module: "account summary activity list",
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("HEADER_GO_BACK", (data) => {
    const locationPath = get(data, "payload.locationPathname", "");

    if (locationPath.includes(BASE_PATH)) {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "return to menu",
        tag: undefined,
        gaEventLabel: undefined,
        module: "account summary",
        microApp: undefined
      });
    }
  });
};
