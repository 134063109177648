export const initialState = {
  currentRace: {},
  betSelections: [[]],
  startBetting: false,
  showBetConfirmation: false,
  betState: "none",
  betAmount: 0,
  betType: null,
  betLeg: 0,
  resultsOfficialsTab: "none",
  raceBets: [],
  queryWithRaces: false,
  queryWithHandicapping: false,
  handicappingMode: "basic",
  isHandicappingModalOpen: false,
  isHandicappingSortModalOpen: false,
  isRaceSelectorModalOpen: false,
  scratchedSelections: [[]],
  showHeaderRaceNavigation: false,
  fixWagerSelector: {
    isWagerSelectorFixed: false,
    fixedOffset: 0
  },
  fixHandicapSection: {
    isHandicapFixed: false,
    fixedOffset: 0,
    handicapFormatHeight: 24
  },
  showMainWagerType: true,
  showVideoButtonHeader: false,
  talentPick: null,
  talentPickTriggerOrigin: null,
  pickEditButton: true,
  applyPick: false,
  expandedRunnerId: "",
  handicappingModeSetBeforeLogin: null,
  handicappingSortModeSetBeforeLogin: null,
  userSetHandicappingMode: null,
  userSetHandicappingSortMode: null
};

const resetArray = (matrix) => matrix.map(() => []);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_BET_CONFIRMATION": {
      return {
        ...state,
        showBetConfirmation: action.payload
      };
    }
    case "SET_START_BETTING": {
      return {
        ...state,
        startBetting: action.payload
      };
    }
    case "SET_BET_AMOUNT": {
      return {
        ...state,
        betAmount: action.payload
      };
    }
    case "SET_SELECTION_SCRATCHED": {
      return {
        ...state,
        scratchedSelections: action.payload
      };
    }
    case "SET_BET_TYPE": {
      return {
        ...state,
        betType: action.payload
      };
    }
    case "SET_BET_SELECTIONS": {
      return {
        ...state,
        betSelections: action.payload
      };
    }
    case "SET_SELECTIONS_MATRIX": {
      return {
        ...state,
        betSelections: action.payload.selections
      };
    }
    case "RESET_BET_SELECTIONS": {
      return {
        ...state,
        betSelections: resetArray(state.betSelections),
        startBetting: false
      };
    }

    case "SET_PICK_EDIT_BUTTON": {
      return {
        ...state,
        pickEditButton: action.payload
      };
    }

    case "RESET_BETTING": {
      return {
        ...state,
        betSelections: [[]],
        startBetting: false,
        showBetConfirmation: false,
        betState: "none",
        betAmount: 0,
        betType: null,
        betLeg: 0,
        resultsOfficialsTab: "none",
        talentPick: null,
        talentPickTriggerOrigin: null,
        pickEditButton: true
      };
    }
    case "SET_BET_STATE": {
      return {
        ...state,
        betState: action.payload
      };
    }
    case "SET_PICK_LEG": {
      return {
        ...state,
        betLeg: action.payload
      };
    }
    case "SET_RESULTS_OFFICIALS_TAB": {
      return {
        ...state,
        resultsOfficialsTab: action.payload
      };
    }
    case "SET_RACE_BETS": {
      return {
        ...state,
        raceBets: action.payload
      };
    }
    case "SET_PICK": {
      return {
        ...state,
        talentPick: action.payload.value,
        talentPickTriggerOrigin: action.payload.triggerOrigin
      };
    }
    case "SET_APPLY_PICK": {
      return {
        ...state,
        applyPick: action.payload
      };
    }
    case "SET_QUERY_WITH_HANDICAPPING": {
      return {
        ...state,
        queryWithHandicapping: action.payload
      };
    }
    case "SET_QUERY_WITH_RACES": {
      return {
        ...state,
        queryWithRaces: action.payload
      };
    }
    case "SET_HANDICAPPING_FAVORITE_MODE": {
      return {
        ...state,
        handicappingFavoriteMode: action.payload
      };
    }
    case "SET_HANDICAPPING_MODE": {
      return {
        ...state,
        handicappingMode: action.payload,
        queryWithHandicapping: action.payload !== "basic"
      };
    }
    case "SET_HANDICAPPING_MODAL_STATE": {
      return {
        ...state,
        isHandicappingModalOpen: action.payload
      };
    }
    case "SET_HANDICAPPING_SORT_MODE": {
      return {
        ...state,
        handicappingSortMode: action.payload
      };
    }
    case "SET_HANDICAPPING_SORT_FAVORITE_MODE": {
      return {
        ...state,
        handicappingSortFavoriteMode: action.payload
      };
    }
    case "SET_HANDICAPPING_SORT_MODAL_STATE": {
      return {
        ...state,
        isHandicappingSortModalOpen: action.payload
      };
    }
    case "USER_SET_HANDICAPPING_MODE": {
      return {
        ...state,
        userSetHandicappingMode: action.payload
      };
    }
    case "USER_SET_HANDICAPPING_SORT_MODE": {
      return {
        ...state,
        userSetHandicappingSortMode: action.payload
      };
    }
    case "SET_RACE_SELECTOR_MODAL_STATE": {
      return {
        ...state,
        isRaceSelectorModalOpen: action.payload
      };
    }
    case "SET_CURRENT_RACE": {
      return {
        ...state,
        currentRace: action.payload
      };
    }
    case "SHOW_HEADER_NAVIGATION": {
      return {
        ...state,
        showHeaderRaceNavigation: action.payload
      };
    }

    case "FIX_HANDICAP": {
      return {
        ...state,
        fixHandicapSection: action.payload
      };
    }

    case "FIX_WAGER_SELECTOR": {
      return {
        ...state,
        fixWagerSelector: action.payload
      };
    }

    case "SHOW_VIDEO_BUTTON_HEADER": {
      return {
        ...state,
        showVideoButtonHeader: action.payload
      };
    }

    case "SAVE_VIDEO_FEEDBACK": {
      return {
        ...state,
        videoFeedback: action.payload
      };
    }

    case "SHOW_MAIN_WAGER_TYPE": {
      return {
        ...state,
        showMainWagerType: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
