import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("RACE_RESULTS_SELECT_TAB", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: get(data, "payload.tabName"),
      module: get(data, "payload.raceResults")
        ? "PP Result Bets Closed"
        : "PP Result After Off",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "RACE_RESULTS_NEXT_RACE_CHANGE_TRACK",
    (data) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated to",
        gaEventLabel: data.payload.actionName,
        module: "Next Race",
        microApp: "non-Microapp",
        destinationUrl: data.payload.destinationUrl
      });
    }
  );
};
