import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  // When a user clicks a back arrow
  mediator.base.subscribe("REGISTRATION:CLICK_BACK_BUTTON", (data) => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: "Back Arrow Clicked",
      gaEventLabel: undefined,
      module: get(data, "payload.module"),
      siteVersion
    });
  });

  // When a user clicks edit in the Review part of the form
  mediator.base.subscribe("REGISTRATION:CLICK_EDIT", (data) => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: "Edit Form Opened",
      gaEventLabel: get(data, "payload.formSection"),
      module: get(data, "payload.module")
    });
  });

  // When a user clicks a link that takes them away from the form (another URL)
  mediator.base.subscribe("REGISTRATION:CLICK_LINK_AWAY_FROM_FORM", (data) => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.linkText"),
      module: get(data, "payload.module"),
      siteVersion
    });
  });
};
