import React, { useCallback } from "react";
import { SwitchComp, SliderWrapper, Input } from "./styled-components";
import type { SwitchProps } from "./types";

export const Switch = ({
  isOn,
  onPress,
  qaLabel,
  isDisabled = false
}: SwitchProps) => {
  const onPressSwitch = useCallback(() => {
    if (isDisabled) return;
    onPress();
  }, [isOn, onPress]);

  return (
    <SliderWrapper
      aria-disabled={isDisabled}
      aria-checked={isOn}
      onClick={onPressSwitch}
      tabIndex={0}
      role="switch"
      data-status={isOn ? "ON" : "OFF"}
      data-qa-label={qaLabel}
    >
      <Input
        checked={isOn}
        type="checkbox"
        onChange={onPressSwitch}
        disabled={isDisabled}
        data-qa-label={`${qaLabel}-input`}
      />
      <SwitchComp data-qa-label={`${qaLabel}-toggle`} />
    </SliderWrapper>
  );
};

export type { SwitchProps };
