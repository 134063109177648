import { useEffect } from "react";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import {
  setShouldDefaultToFuture,
  setShouldDefaultToSettled
} from "../redux/actions";

function useSetMyBetsDefaults(
  dispatch: Dispatch,
  redirectWithoutActiveBetsToggle: boolean,
  activeBetsCounter: number,
  futureBetsCounter: number
) {
  useEffect(() => {
    if (redirectWithoutActiveBetsToggle) {
      if (activeBetsCounter === 0) {
        if (futureBetsCounter === 0) {
          batch(() => {
            dispatch(setShouldDefaultToFuture(false));
            dispatch(setShouldDefaultToSettled(true));
          });
        } else {
          batch(() => {
            dispatch(setShouldDefaultToFuture(true));
            dispatch(setShouldDefaultToSettled(false));
          });
        }
      } else if (activeBetsCounter > 0) {
        batch(() => {
          dispatch(setShouldDefaultToSettled(false));
          dispatch(setShouldDefaultToFuture(false));
        });
      }
    }
  }, [activeBetsCounter, futureBetsCounter, redirectWithoutActiveBetsToggle]);
}

export default useSetMyBetsDefaults;
