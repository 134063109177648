import React from "react";
import loadable from "@loadable/component";
import ProgramPageMask from "@tvg/atomic-ui/_static/Masks/program-page";
import TracksPageMask from "@tvg/atomic-ui/_static/Masks/tracks";
import PicksPageMask from "@tvg/atomic-ui/_static/Masks/talent-picks";
import HomePageMask from "@tvg/atomic-ui/_static/Masks/homepage";

const Loading = (error, Mask) => {
  if (error) {
    try {
      window.onSplashLoadError();
    } catch (e) {
      console.error(e);
    }

    return false;
  }
  return (Mask && <Mask />) || <div />;
};

export const ProgramPageLoadable = loadable(
  () => import(/* webpackChunkName: 'ProgramPage' */ "./components/Program"),
  {
    fallback: (props) => Loading(props.error, ProgramPageMask)
  }
);

export const TracksPageLoadable = loadable(
  () => import(/* webpackChunkName: 'tracksPage' */ "./components/Tracks"),
  {
    fallback: (props) => Loading(props.error, TracksPageMask)
  }
);

export const ResultsPageLoadables = loadable(
  () => import(/* webpackChunkName: 'resultsPage' */ "./components/Results"),
  {
    fallback: (props) => Loading(props.error, TracksPageMask)
  }
);

export const PicksPageLoadable = loadable(
  () => import(/* webpackChunkName: 'picksPage' */ "./components/Picks"),
  {
    fallback: (props) => Loading(props.error, PicksPageMask)
  }
);

export const MorePageLoadable = loadable(
  () => import(/* webpackChunkName: 'morePage' */ "./components/More"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const HomePageLoadable = loadable(
  () => import(/* webpackChunkName: 'homePage' */ "./components/Home"),
  {
    fallback: (props) => Loading(props.error, HomePageMask)
  }
);

export const LivePageLoadable = loadable(
  () => import(/* webpackChunkName: 'livePage' */ "./components/Live"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const WagerRewardsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'wagerRewardsPage' */ "./components/WagerRewards"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const RGLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'RGPage' */ "./components/ResponsibleGaming"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const RGFundingControlsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'RGFundingControlsPage' */ "./components/FundingMethods"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const RGTimeoutLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'rgTimeoutPage' */ "./components/RGTimeout"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const RgDepositLimitsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'depositLimitsPage' */ "./components/RgDepositLimits"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const TrackListPageLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'tracksListPage' */ "./components/TrackList"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const PromosPageLoadable = loadable(
  () => import(/* webpackChunkName: 'PromosPage' */ "./components/Promos"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const ReferPageLoadable = loadable(
  () => import(/* webpackChunkName: 'referFriendPage' */ "./components/Refer"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const AccountSummaryLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'accountSummary' */ "./components/AccountSummary"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const RegistrationLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'registration' */ "./components/Registration"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const ResetCredentialsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'resetCredentials' */ "./components/ResetCredentials"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const WalletLoadable = loadable(
  () => import(/* webpackChunkName: 'wallet' */ "./components/Wallet"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const PreferencesPageLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'preferences' */ "./components/Preferences"),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const PreferencesTalentAlertsPageLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'preferencesTalentAlerts' */ "./components/PreferencesTalentAlerts"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);

export const EmailUnsubscribePageLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'emailUnsubscribe' */ "./components/EmailUnsubscribe"
    ),
  {
    fallback: (props) => Loading(props.error, null)
  }
);
