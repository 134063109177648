import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Controllers = styled.div`
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
  padding: 0 12px 12px;
`;

export const Filters = styled.div`
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
`;

export const Header = styled.div`
  background-color: ${buildColor("white", "100")};
  position: sticky;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
`;
