import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type ResultsChangeTabType = {
  type: "CHANGE_TAB_RESULTS";
  payload: {
    textLabel: string;
    raceNumber: number;
    trackName?: string;
  };
};
export default () => {
  mediatorChannels.base.subscribe(
    "CHANGE_TAB_RESULTS",
    (data: ResultsChangeTabType): void => {
      pushToDataLayer({
        event: "program",
        gaEventCategory: "Program",
        gaEventAction: "Race Card Tab Opened",
        gaEventLabel: get(data, "payload.textLabel"),
        module: "Race Card Tabs",
        tag: undefined,
        raceNumber: get(data, "payload.raceNumber"),
        trackName: get(data, "payload.trackName"),
        sport: "Horse Racing"
      });
    }
  );
};
