import React, { Component } from "react";
import { bindAll, noop, get } from "lodash";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

import { LinkFooter, Text } from "./styled-components";

export class FooterLink extends Component {
  static defaultProps = {
    className: "",
    label: "",
    href: "/",
    counter: 0,
    selected: false,
    icon: {},
    onClick: undefined,
    isTextAside: false
  };

  animClass;

  animTimeout;

  constructor(props) {
    super(props);
    this.animClass = "";
    bindAll(this, ["applyAnimation"]);
  }

  componentDidMount() {
    if (this.props.counter > 0) {
      this.applyAnimation("run-animation");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.counter !== this.props.counter) {
      this.applyAnimation("run-animation");
    }
  }

  findFooterLinkColor = () => {
    if (get(this.props, "selected", false)) {
      return buildColor("white", "100");
    }
    return buildColor("blue", "300");
  };

  applyAnimation(animName) {
    this.animClass = `${animName}`;

    if (this.animTimeout) {
      clearTimeout(this.animTimeout);
    }

    this.animTimeout = setTimeout(() => {
      this.animClass = "";
      this.forceUpdate();
    }, 250);

    this.forceUpdate();
  }

  render() {
    const componentProps = {
      counter: this.props.counter,
      selected: this.props.selected,
      to: this.props.href,
      label: this.props.label,
      onClick: this.props.onClick,
      className: `${this.props.className || ""} ${this.animClass}`,
      istextaside: this.props.isTextAside ? 1 : 0,
      fontSize: this.props.isTextAside ? 14 : 10
    };
    const footerLinkColor = this.findFooterLinkColor();

    return (
      <LinkFooter {...componentProps} onTouchStart={noop}>
        {this.props.icon && (
          <Icon icon={this.props.icon} size={20} color={footerLinkColor} />
        )}
        <Text color={footerLinkColor}>{this.props.label}</Text>
      </LinkFooter>
    );
  }
}

export default FooterLink;
