export const initialState = {
  selectedPoolsNInfoTab: "infochanges",
  isPoolsNInfoModalOpen: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "POOLS_N_INFO_TAB": {
      return {
        ...state,
        selectedPoolsNInfoTab: action.payload
      };
    }

    case "TOGGLE_POOLS_N_INFO_MODAL": {
      return {
        ...state,
        isPoolsNInfoModalOpen: action.payload,
        selectedPoolsNInfoTab: action.payload
          ? state.selectedPoolsNInfoTab
          : "infochanges"
      };
    }

    default: {
      return state;
    }
  }
}
