const RG_OPTIONS = {
  DEPOSIT_LIMIT: "DEPOSIT_LIMIT",
  FUNDING_CONTROLS: "FUNDING_CONTROLS",
  TIMEOUT: "TIMEOUT",
  SELF_EXCLUSION: "SELF_EXCLUSION",
  SUSPEND: "SUSPEND"
};

export const RG_TIMEOUT_EVENTS_MAPPING = {
  TIMEOUT: "SHOW_RG_TIMEOUT_SCREEN",
  SELF_EXCLUSION: "SHOW_RG_SELF_EXCLUSION_SCREEN",
  SUSPEND: "SHOW_RG_SUSPEND_SCREEN"
};

export default RG_OPTIONS;
