import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  TPEventData,
  TPBetEventData,
  TPViewRaceEventData,
  TPTalentEventData,
  TPTCardClickedData,
  TPTAddBetslipData,
  TPTFilterApplied,
  ModuleEventProps,
  EventProps
} from "./types";
import { EVENTS, PAGES, MODULE } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

const getPageModule = (origin: string) =>
  origin === "pickspage" ? PAGES.TP : PAGES.HOMEPAGE;

const getModule = (origin: string) =>
  origin === "pickspage" ? PAGES.TP : PAGES.PP_TRACKMASTER;

const subscribeBetEvents = () => {
  const getEventPropsDefault = (data: TPBetEventData) => ({
    [BaseEventProps.EVENT_CATEGORY]: "Bet",
    [BaseEventProps.TRACK_NAME]: get(data, "payload.race.track.trackName"),
    [BaseEventProps.RACE_NUMBER]: get(data, "payload.race.raceNumber"),
    [BaseEventProps.BET_TYPE]: get(data, "payload.betType")
  });

  // https://betfairus.atlassian.net/browse/TP-81
  mediator.base.subscribe(
    MediatorEventType.TPPAGE_BET,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getPageModule(
          get(data, "payload.module", "pickspage")
        )
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-108
  mediator.base.subscribe(
    MediatorEventType.TPPAGE_EDIT_BET,
    (data: TPBetEventData) => {
      track<EventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getPageModule(
          get(data, "payload.module", "pickspage")
        ),
        [ModuleEventProps.PLACE_TYPE]: get(data, "payload.label")
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-107
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_EDIT_BET,
    (data: TPBetEventData) => {
      track<EventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: "PP Trackmaster Picks",
        [ModuleEventProps.PLACE_TYPE]: get(data, "payload.label")
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-71
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_BET,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: "PP Trackmaster Picks"
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-74
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_BET_SUCCESS,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getModule(
          get(data, "payload.talentPickOrigin")
        ),
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.tag"),
        [BaseEventProps.BET_ID]: get(data, "payload.betId"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.REPEAT_BET]: 0
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-75
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_BET_ERROR,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getModule(
          get(data, "payload.talentPickOrigin")
        ),
        [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.tag"),
        [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
        [BaseEventProps.ERROR_TYPE]: get(data, "payload.label")
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-72
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_CONFIRM_BET_OK,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getModule(
          get(data, "payload.talentPickOrigin")
        )
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-104
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_CONFIRM_PHRASE,
    (data: TPBetEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        ...getEventPropsDefault(data),
        [BaseEventProps.MODULE]: getModule(
          get(data, "payload.talentPickOrigin")
        )
      });
    }
  );
};

export default () => {
  subscribeBetEvents();

  // https://betfairus.atlassian.net/browse/TP-70
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_TPTAB,
    (data: TPEventData) => {
      track<BaseEventProps>(EVENTS.SELECT, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload.label,
        [BaseEventProps.MODULE]: "PP Trackmaster Picks",
        [BaseEventProps.TAG]: data.payload.tag
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-69
  mediator.base.subscribe(
    MediatorEventType.HOMEPAGE_BANNER,
    (data: TPEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.EVENT_LABEL]: data.payload.label,
        [BaseEventProps.MODULE]: "Home Page Banner",
        [BaseEventProps.LINK_URL]: data.payload.url
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-73
  mediator.base.subscribe(
    MediatorEventType.PROGRAMPAGE_CONFIRM_BET_CLOSE,
    (data: TPEventData) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload.label,
        [BaseEventProps.MODULE]: "PP Trackmaster Picks"
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-213
  mediator.base.subscribe(
    MediatorEventType.TALENT_CLICK,
    (data: TPTalentEventData) => {
      track<BaseEventProps>(data.payload.talentFilterState, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "Talent Picks",
        [BaseEventProps.MODULE]: "Talent Picks",
        [BaseEventProps.TAG]: data.payload.talentName
      });
    }
  );

  // https://betfairus.atlassian.net/browse/TP-214
  mediator.base.subscribe(MediatorEventType.ALERTS_CLICK, () => {
    track<BaseEventProps>(EVENTS.ALERTS, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Talent Picks"
    });
  });

  // https://betfairus.atlassian.net/browse/HP-518

  mediator.base.subscribe(MediatorEventType.SEE_NEXT_RACES, () => {
    track<BaseEventProps>(EVENTS.NEXT, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Talent Pick Homepage"
    });
  });

  mediator.base.subscribe(MediatorEventType.SEE_PREVIOUS_RACES, () => {
    track<BaseEventProps>(EVENTS.BACK, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Talent Pick Homepage"
    });
  });

  // https://betfairus.atlassian.net/browse/HP-518
  mediator.base.subscribe(
    MediatorEventType.HP_TP_VIEW_RACE,
    ({ payload }: TPViewRaceEventData) => {
      const { clickLocation, talentPick, url } = payload;
      const { trackName, race, mtp, talent } = talentPick;
      track<BaseEventProps>(EVENTS.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: `${trackName} - R${race} - ${mtp} - ${talent.name}`,
        [BaseEventProps.MODULE]: `talent picks - ${clickLocation}`,
        [BaseEventProps.LINK_URL]: url
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CARD_CLICKED,
    ({ payload }: TPTCardClickedData) => {
      track<EventProps>(EVENTS.CARD_CLICKED, {
        [BaseEventProps.MODULE]: payload.module,
        [BaseEventProps.LINK_URL]: payload.raceURL,
        [BaseEventProps.LINK_TEXT]: payload.linkText,
        [ModuleEventProps.TALENT_NAME]: payload.talentName,
        [BaseEventProps.RACE_TRACK_SELECTION]: payload.trackName,
        [BaseEventProps.RACE_NUMBER_SELECTION]: payload.raceNumber,
        [BaseEventProps.BET_TYPE]: payload.wagerTypeCode,
        [BaseEventProps.POSITION]: payload.cardPosition
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.ADD_TO_BETSLIP,
    ({ payload }: TPTAddBetslipData) => {
      track<EventProps>(EVENTS.ADD_TO_BETSLIP, {
        [BaseEventProps.MODULE]: payload.module,
        [ModuleEventProps.TALENT_NAME]: payload.talentName,
        [BaseEventProps.RACE_TRACK_SELECTION]: payload.trackName,
        [BaseEventProps.SELECTION_SOURCE]: payload.selectionSource,
        [BaseEventProps.RACE_NUMBER_SELECTION]: payload.raceNumber,
        [BaseEventProps.BET_TYPE]: payload.wagerTypeCode,
        [BaseEventProps.POSITION]: payload.cardPosition,
        [BaseEventProps.BET_AMOUNT]: payload.betTotal
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.CTA_CLICKED, () => {
    track<EventProps>(EVENTS.CTA_CLICKED, {
      [BaseEventProps.MODULE]: MODULE.HOME,
      [BaseEventProps.LINK_URL]: "/handicapping/talent-picks",
      [BaseEventProps.LINK_TEXT]: "see_all"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.FILTERS_APPLIED,
    ({ payload }: TPTFilterApplied) => {
      track<EventProps>(EVENTS.FILTERS_APPLIED, {
        [BaseEventProps.MODULE]: "talent_picks_filter",
        [BaseEventProps.FILTER_NAME]: payload.filterName,
        [BaseEventProps.FILTER_VALUE]: payload.filterLabel
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.EMPTY_STATE_VIEWED, () => {
    track<EventProps>(EVENTS.EMPTY_STATE_VIEWED, {
      [BaseEventProps.MODULE]: MODULE.PICKS_PAGE
    });
  });
};
