import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("HEADER_GO_BACK", (data) => {
    const locationPathname = get(data, "payload.locationPathname", "");

    if (locationPathname.includes("talent-alerts")) {
      pushToDataLayer({
        event: "preferences",
        gaEventAction: "Back Arrow Clicked",
        gaEventCategory: "Preferences",
        gaEventLabel: "Preferences",
        module: "Talent Picks"
      });
    } else {
      pushToDataLayer({
        event: "preferences",
        gaEventAction: "Back Arrow Clicked",
        gaEventCategory: "Preferences",
        gaEventLabel: "More",
        module: "Preferences"
      });
    }
  });
};
