import { attempt, get, isError } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
// @ts-ignore
import pushToDataLayer, { getSiteVersion } from "../gtmUtils";

type PageViewGtmData = {
  type: "LOAD:RACE_PAGE_VIEW";
  payload: {
    accountId: string | null;
    balance: number | null;
    event: string;
    loginStatus: string;
    page: string;
    privateBrowser: string;
    productVersion: string;
  };
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  let privateBrowser = attempt(() =>
    localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
  );

  if (isError(privateBrowser)) {
    privateBrowser = "No";
  }

  mediator.base.subscribe(
    "LOAD:RACE_PAGE_VIEW",
    (data: PageViewGtmData): void => {
      pushToDataLayer({
        accountId: get(data, "payload.accountId"),
        balance: get(data, "payload.balance"),
        event: "ga_pageload",
        loginStatus: get(data, "payload.loginStatus", "Logged out"),
        page: get(data, "payload.page"),
        privateBrowser,
        productVersion: get(data, "payload.productVersion"),
        residenceState: get(data, "payload.residenceState"),
        screenName: "Program Page",
        sectionName: "Program Page",
        siteVersion,
        sport: "Horse Racing",
        raceNumber: get(data, "payload.raceNumber", ""),
        trackName: get(data, "payload.trackName", ""),
        raceStatus: get(data, "payload.raceStatus", ""),
        ...(get(data, "payload.racingId") && {
          racingId: get(data, "payload.racingId")
        })
      });
    }
  );
};
