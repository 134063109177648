import mediatorChannels from "@tvg/mediator";
import { PP_RUNNER_MODIFIER_EVENT } from "@tvg/ts-types/Gtm";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("PPM_TVG5_RUNNER_SELECTION", (data) => {
    pushToDataLayer({
      event: "selection",
      gaEventCategory: "Selection",
      gaEventAction: "Add To Betslip",
      gaEventLabel: get(data, "payload.label"),
      module: "Race Card",
      gaEventValue: undefined,
      trackName: get(data, "payload.race.track.trackName"),
      raceNumber: get(data, "payload.race.raceNumber"),
      betType: get(data, "payload.betType")
    });
  });

  mediatorChannels.base.subscribe("PPM_RUNNER_SELECTION", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: get(data, "payload.action"),
      gaEventLabel: get(data, "payload.label"),
      module: get(data, "payload.module"),
      gaEventValue: undefined,
      runnerSelectionList: get(data, "payload.tag"),
      trackName: get(data, "payload.race.track.trackName"),
      repeatBet: undefined,
      raceNumber: get(data, "payload.race.raceNumber"),
      betId: undefined,
      betAmount: undefined,
      betType: get(data, "payload.betType"),
      sport:
        get(data, "payload.race.type.code") === "G"
          ? "Greyhounds Racing"
          : "Horse Racing"
    });
  });

  mediatorChannels.base.subscribe("PPM_SELECT_ALL_RUNNERS", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: data.payload.add
        ? "Add To Betslip"
        : "Remove Selection From Betslip",
      gaEventLabel: "All Runners",
      module: "Race Card",
      gaEventValue: undefined,
      runnerSelectionList: get(data, "payload.tag"),
      trackName: get(data, "payload.race.track.trackName"),
      repeatBet: undefined,
      raceNumber: get(data, "payload.race.raceNumber"),
      betId: undefined,
      betAmount: undefined,
      betType: data.payload.betType,
      sport:
        get(data, "payload.race.type.code") === "G"
          ? "Greyhounds Racing"
          : "Horse Racing"
    });
  });

  mediatorChannels.base.subscribe("PPM_RESET_ALL_RUNNERS", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Remove Selection From Betslip",
      gaEventLabel: "Reset",
      module: "Race Card",
      gaEventValue: undefined,
      runnerSelectionList: get(data, "payload.tag"),
      trackName: get(data, "payload.trackName"),
      repeatBet: undefined,
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: undefined,
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`
    });
  });

  mediatorChannels.base.subscribe("PPM_SELECT_BET_TYPE", (data) => {
    let eventLabel = "";
    if (!get(data, "payload.secondaryType")) {
      eventLabel = `Primary - ${get(data, "payload.primaryType")}`;
    } else {
      eventLabel = `Secondary - ${get(data, "payload.primaryType")} - ${get(
        data,
        "payload.secondaryType"
      )}`;
    }

    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Selected Bet Type",
      gaEventLabel: eventLabel,
      tag: get(data, "payload.isFavorite") && "Favorite",
      module: "Bet Selector",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_BET_TYPE:GO_BACK", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Go Back To",
      gaEventLabel: "Bet Selector",
      tag: undefined,
      module: "Bet Selector",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_SET_BET_AMOUNT", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: `${
        get(data, "payload.isCustomAmount", false) ? "Set" : "Select"
      } Bet Amount`,
      gaEventLabel: `${get(data, "payload.betAmount")}`,
      module: "Bet Amount Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_CLOSE_CUSTOM_AMOUNT", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Bet Amount Modal",
      module: "Bet Amount Modal",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_BET_TIMEOUT", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Time out",
      gaEventLabel: undefined,
      module: "Confirm Bet Modal",
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("PPM_BET_SUBMIT_ERROR", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Error",
      gaEventLabel: get(data, "payload.error"),
      module: "PP Footer",
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("PPM_BET_NOW", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Submit",
      gaEventLabel: undefined,
      module: "PP Footer",
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      runnerAmount: get(data, "payload.runnerAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("PPM_DELETE_BET_TICKET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Remove Selection From Betslip",
      gaEventLabel: "Trash Icon",
      module: "Race Card",
      gaEventValue: undefined,
      runnerSelectionList: undefined,
      trackName: get(data, "payload.trackName"),
      repeatBet: undefined,
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: undefined,
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`
    });
  });

  mediatorChannels.base.subscribe("PPM_OPEN_RACE_NAVIGATION", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: `Race Modal - ${get(data, "payload.trackName")} - R${get(
        data,
        "payload.raceNumber"
      )}`,
      module: get(data, "payload.module"),
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_CLOSE_RACE_NAVIGATION", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: `Race Modal - ${get(data, "payload.trackName")} - R${get(
        data,
        "payload.raceNumber"
      )}`,
      module: "Race Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("PPM_HANDICAP_STORE_PRODUCT", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Select",
      gaEventLabel: data.payload.product,
      module: "PP Trackmaster Picks",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PPM_HANDICAP_STORE_SEE_ALL", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: "see all products",
      module: "PP Trackmaster Picks",
      menu: undefined,
      tag: undefined,
      microApp: "non-Microapp",
      sport: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe(
    PP_RUNNER_MODIFIER_EVENT.APPLY_FILTER,
    ({ payload }) => {
      pushToDataLayer({
        event: "program",
        gaEventCategory: "Program",
        gaEventAction: "Program Filter Applied",
        gaEventLabel: payload.filter,
        module: "Runner Modifier",
        filterType: "Runner Modifier",
        filterValue: payload.filter,
        trackName: payload.trackName
      });
    }
  );

  mediatorChannels.base.subscribe(
    PP_RUNNER_MODIFIER_EVENT.APPLY_SORT,
    ({ payload }) => {
      pushToDataLayer({
        event: "program",
        gaEventCategory: "Program",
        gaEventAction: "Program Sort Applied",
        gaEventLabel: payload.sortOperation,
        module: "Runner Modifier",
        sortType: "Runner Modifier",
        sortName: payload.sortValue,
        sortOperation: payload.sortOperation
      });
    }
  );

  mediatorChannels.base.subscribe("PPM_WILL_PAYS_SELECT_OPTION", (data) => {
    pushToDataLayer({
      event: "program",
      gaEventCategory: "Program",
      gaEventAction: "Will Pays Option Selected",
      gaEventLabel: data.payload.label,
      module: "Will Pays",
      tag: undefined,
      raceNumber: data.payload.raceNumber,
      trackName: data.payload.trackName
    });
  });

  mediatorChannels.base.subscribe("PPM_PROBABLES_SELECT_OPTION", (data) => {
    pushToDataLayer({
      event: "program",
      gaEventCategory: "Program",
      gaEventAction: "Probables Option Selected",
      gaEventLabel: data.payload.label,
      module: "Probables",
      tag: undefined,
      raceNumber: data.payload.raceNumber,
      trackName: data.payload.trackName
    });
  });
};
