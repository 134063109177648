// Carriage Returns, New Line Pattern, Tab or Curly Braces (template injection)
const dangerousEntitiesPattern =
  /[\r\n\t]|&lcub;|&rcub;|&amp;lcub;|&amp;rcub;/g;
const validUrlPattern =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i; // eslint-disable-line no-useless-escape

export const getCleanedUrl = (url: string) => {
  // Remove carriage returns
  let cleanedUrl = url.replace(dangerousEntitiesPattern, "");

  do {
    cleanedUrl = cleanedUrl.replace(dangerousEntitiesPattern, "");
  } while (dangerousEntitiesPattern.test(cleanedUrl));

  // Check if matches the valid URL pattern
  if (!validUrlPattern.test(cleanedUrl)) {
    cleanedUrl = "";
  }

  return cleanedUrl;
};
