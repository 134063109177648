import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("HEADER:WAGERPAD_CLICK", () => {
    pushToDataLayer({
      event: "betslip",
      gaEventCategory: "Betslip",
      gaEventAction: "Betslip Opened",
      gaEventLabel: undefined,
      module: "Global Header",
      tag: undefined
    });
  });
};
