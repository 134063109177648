import {
  HandicapOptionsType,
  HandicapSortOptionsType,
  RaceProgram
} from "@tvg/ts-types/Race";
import { Talent } from "@tvg/ts-types/Talent";

// Declare existing actions
type GraphPendingAction = { type: "GRAPH_REQUEST_PENDING" };
type GraphFinishAction = { type: "GRAPH_REQUEST_FINISH" };

type wagerSelectorFixedType = {
  isWagerSelectorFixed: boolean;
  fixedOffset: number;
};

type FixHandicapType = {
  isHandicapFixed: boolean;
  fixedOffset: number;
  handicapFormatHeight: number;
};

type SetQueryWithRacesAction = {
  type: "SET_QUERY_WITH_RACES";
  payload: boolean;
};

type SetQueryWithHandicappingAction = {
  type: "SET_QUERY_WITH_HANDICAPPING";
  payload: boolean;
};

type SetHandicappingFavoriteModeAction = {
  type: "SET_HANDICAPPING_FAVORITE_MODE";
  payload: HandicapOptionsType;
};

type SetHandicappingModeAction = {
  type: "SET_HANDICAPPING_MODE";
  payload: HandicapOptionsType;
};

type SetHandicappingModalStateAction = {
  type: "SET_HANDICAPPING_MODAL_STATE";
  payload: boolean;
};

type UserSetHandicappingModeAction = {
  type: "USER_SET_HANDICAPPING_MODE";
  payload?: HandicapOptionsType;
};

type UserSetHandicappingSortModeAction = {
  type: "USER_SET_HANDICAPPING_SORT_MODE";
  payload?: HandicapSortOptionsType;
};

type SetHandicappingSortFavoriteModeAction = {
  type: "SET_HANDICAPPING_SORT_FAVORITE_MODE";
  payload: HandicapSortOptionsType;
};

type SetHandicappingSortModeAction = {
  type: "SET_HANDICAPPING_SORT_MODE";
  payload: HandicapSortOptionsType;
};

type SetHandicappingSortModalStateAction = {
  type: "SET_HANDICAPPING_SORT_MODAL_STATE";
  payload: boolean;
};

type SetRaceSelectorModalStateAction = {
  type: "SET_RACE_SELECTOR_MODAL_STATE";
  payload: boolean;
};

type SetCurrentRaceAction = {
  type: "SET_CURRENT_RACE";
  payload: RaceProgram;
};

type ShowRaceNavigationHeaderAction = {
  type: "SHOW_HEADER_NAVIGATION";
  payload: boolean;
};

type fixWagerSelectorAction = {
  type: "FIX_WAGER_SELECTOR";
  payload: wagerSelectorFixedType;
};

type showVideoButtonHeaderAction = {
  type: "SHOW_VIDEO_BUTTON_HEADER";
  payload: boolean;
};

type saveVideoFeedbackAction = {
  type: "SAVE_VIDEO_FEEDBACK";
  payload: string;
};

type fixHandicapSectionAction = {
  type: "FIX_HANDICAP";
  payload: FixHandicapType;
};

type TalentPicksRaceSelectorOpenAction = {
  type: "TALENT_TOGGLE_RACE_SELECTOR";
};

type TalentPicksDropdownOpenAction = {
  type: "TALENT_TOGGLE_TALENT_DROPDOWN";
};

type TalentPicksPopulateListAction = {
  type: "TALENT_POPULATE_LIST";
  payload: Talent[];
};

type TalentPicksSetSelectedIdAction = {
  type: "TALENT_SET_SELECTED_TALENT_ID";
  payload: string;
};

type ShowMainWagerType = {
  type: "SHOW_MAIN_WAGER_TYPE";
  payload: boolean;
};

export type Actions =
  | GraphPendingAction
  | GraphFinishAction
  | SetQueryWithRacesAction
  | SetHandicappingModeAction
  | SetHandicappingSortModeAction
  | SetQueryWithHandicappingAction
  | SetHandicappingModalStateAction
  | SetHandicappingSortModalStateAction
  | SetHandicappingFavoriteModeAction
  | SetHandicappingSortFavoriteModeAction
  | SetRaceSelectorModalStateAction
  | SetCurrentRaceAction
  | fixWagerSelectorAction
  | ShowRaceNavigationHeaderAction
  | saveVideoFeedbackAction
  | fixHandicapSectionAction
  | showVideoButtonHeaderAction
  | TalentPicksRaceSelectorOpenAction
  | TalentPicksDropdownOpenAction
  | TalentPicksPopulateListAction
  | TalentPicksSetSelectedIdAction
  | ShowMainWagerType
  | UserSetHandicappingModeAction
  | UserSetHandicappingSortModeAction;

export const graphPending = (): GraphPendingAction => ({
  type: "GRAPH_REQUEST_PENDING"
});

export const graphFinish = (): GraphFinishAction => ({
  type: "GRAPH_REQUEST_FINISH"
});

export const setQueryWithRacesAction = (
  value: boolean
): SetQueryWithRacesAction => ({
  type: "SET_QUERY_WITH_RACES",
  payload: value
});

export const setHandicappingFavoriteModeAction = (
  value: HandicapOptionsType
): SetHandicappingFavoriteModeAction => ({
  type: "SET_HANDICAPPING_FAVORITE_MODE",
  payload: value
});

export const setHandicappingModeAction = (
  value: HandicapOptionsType
): SetHandicappingModeAction => ({
  type: "SET_HANDICAPPING_MODE",
  payload: value
});

export const userSetHandicappingModeAction = (
  value?: HandicapOptionsType
): UserSetHandicappingModeAction => ({
  type: "USER_SET_HANDICAPPING_MODE",
  payload: value
});

export const userSetHandicappingSortModeAction = (
  value?: HandicapSortOptionsType
): UserSetHandicappingSortModeAction => ({
  type: "USER_SET_HANDICAPPING_SORT_MODE",
  payload: value
});

export const setHandicappingModalStateAction = (
  value: boolean
): SetHandicappingModalStateAction => ({
  type: "SET_HANDICAPPING_MODAL_STATE",
  payload: value
});

export const setHandicappingSortFavoriteModeAction = (
  value: HandicapSortOptionsType
): SetHandicappingSortFavoriteModeAction => ({
  type: "SET_HANDICAPPING_SORT_FAVORITE_MODE",
  payload: value
});

export const setHandicappingSortModeAction = (
  value: HandicapSortOptionsType
): SetHandicappingSortModeAction => ({
  type: "SET_HANDICAPPING_SORT_MODE",
  payload: value
});

export const setHandicappingSortModalStateAction = (
  value: boolean
): SetHandicappingSortModalStateAction => ({
  type: "SET_HANDICAPPING_SORT_MODAL_STATE",
  payload: value
});

export const setRaceSelectorModalStateAction = (
  value: boolean
): SetRaceSelectorModalStateAction => ({
  type: "SET_RACE_SELECTOR_MODAL_STATE",
  payload: value
});

export const setCurrentRaceAction = (
  race: RaceProgram
): SetCurrentRaceAction => ({
  type: "SET_CURRENT_RACE",
  payload: race
});

export const showRaceNavigationHeader = (
  value: boolean
): ShowRaceNavigationHeaderAction => ({
  type: "SHOW_HEADER_NAVIGATION",
  payload: value
});

export const fixWagerSelector = (
  payload: wagerSelectorFixedType
): fixWagerSelectorAction => ({
  type: "FIX_WAGER_SELECTOR",
  payload: {
    isWagerSelectorFixed: payload.isWagerSelectorFixed,
    fixedOffset: payload.fixedOffset
  }
});

export const showVideoButtonHeader = (
  value: boolean
): showVideoButtonHeaderAction => ({
  type: "SHOW_VIDEO_BUTTON_HEADER",
  payload: value
});

export const saveVideoFeedback = (value: string): saveVideoFeedbackAction => ({
  type: "SAVE_VIDEO_FEEDBACK",
  payload: value
});

export const fixHandicapSection = (
  payload: FixHandicapType
): fixHandicapSectionAction => ({
  type: "FIX_HANDICAP",
  payload: {
    isHandicapFixed: payload.isHandicapFixed,
    fixedOffset: payload.fixedOffset,
    handicapFormatHeight: payload.handicapFormatHeight
  }
});

export const talentPicksRaceSelectorOpen =
  (): TalentPicksRaceSelectorOpenAction => ({
    type: "TALENT_TOGGLE_RACE_SELECTOR"
  });

export const talentPicksTalentDropdown = (): TalentPicksDropdownOpenAction => ({
  type: "TALENT_TOGGLE_TALENT_DROPDOWN"
});

export const talentPicksPopulateList = (
  payload: Talent[]
): TalentPicksPopulateListAction => ({
  type: "TALENT_POPULATE_LIST",
  payload
});

export const setSelectedTalentId = (
  payload: string
): TalentPicksSetSelectedIdAction => ({
  type: "TALENT_SET_SELECTED_TALENT_ID",
  payload
});

export const setQueryWithHandicappingAction = (
  value: boolean
): SetQueryWithHandicappingAction => ({
  type: "SET_QUERY_WITH_HANDICAPPING",
  payload: value
});

export const showMainWagerType = (value: boolean): ShowMainWagerType => ({
  type: "SHOW_MAIN_WAGER_TYPE",
  payload: value
});
