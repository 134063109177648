import React, { Component } from "react";
import { get, isEqual, noop } from "lodash";
import { Link } from "react-router-dom";

import tvgConf from "@tvg/conf";

import NotificationBubble from "../../_atom/NotificationBubble";
import VideoButton from "../../_molecule/VideoButton";
import RaceNavigation from "../../_organism/RaceNavigation";
import BalanceButton from "../../_atom/BalanceButton";

import buildColor from "../../_static/ColorPalette";
import Logo from "../../_static/Logos";
import { arrowBack, lock, envelope, email } from "../../_static/Icons/icons";
import {
  Container,
  HeaderContent,
  LoginSignup,
  LoginSignupInner,
  LockIcon,
  LogoNavigationContainer,
  RaceNavigationContainer,
  VideoButtonContainer,
  AnimationContainer,
  HeaderLabelContainer,
  HeaderLabel,
  ActionToolsContainer,
  ContentCardsInboxContainer,
  ButtonTransparent,
  ButtonLinkStyled,
  ChildContainer,
  HeaderDescriptionContainer,
  HeaderDescription
} from "./styled-components";
import Icon from "../../_static/Icons";

export class Header extends Component {
  static defaultProps = {
    logoMod: "",
    isLogged: false,
    isNavigationShown: false,
    isVideoButtonShown: false,
    isActionToolsShown: false,
    isLogActionsShown: true,
    isTitleCenter: false,
    onLogoClick: noop,
    openRaceNavigation: noop,
    quickDeposit: {
      balance: 0,
      isBalanceShown: true,
      url: "/"
    },
    brand: "tvg",
    loginSignup: {
      signUpUrl: "/",
      loginUrl: "/"
    },
    raceData: {
      trackAbbr: "",
      trackName: "",
      raceNumber: "",
      mtp: 0,
      postTime: "",
      status: "O"
    },
    layout: "normal",
    videoFeedBack: "NOT_AVAILABLE_NO_REPLAY",
    onVideoButton: noop,
    onBackClick: noop,
    prevPath: "/",
    label: "",
    unviewedContentCardsCount: 0,
    isContentCardsInboxShown: false,
    headerClass: "",
    openInbox: noop,
    description: ""
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  openCloseInbox = () => {
    this.props.openInbox(true, this.props.unviewedContentCardsCount);
  };

  renderLogo = () => {
    const isBrandTVG = this.props.brand === "tvg";
    let logoHeight = 18;
    if (
      this.props.headerClass === "logo-highlighted" &&
      tvgConf().device !== "mobile"
    ) {
      logoHeight = 28;
    } else if (isBrandTVG) {
      logoHeight = 32;
    }
    return (
      <Link to="/" onClick={this.props.onLogoClick}>
        <Logo
          brand={isBrandTVG ? "tvgPoweredByFanduel" : this.props.brand}
          data-qa-label="tvgLogo"
          height={logoHeight}
          isDark={
            this.props.headerClass === "logo-highlighted" ||
            this.props.headerClass === "logo-login"
          }
        />
      </Link>
    );
  };

  renderBackButton = () => (
    <HeaderLabelContainer
      to={this.props.prevPath}
      onClick={this.props.onBackClick}
      data-qa-label="headerLabel-back"
      center={
        this.props.isTitleCenter ? this.props.isTitleCenter.toString() : "false"
      }
    >
      <Icon
        icon={arrowBack}
        color={
          this.props.headerClass === "monochromatic" ||
          this.props.headerClass === "lowercaseMonochromatic"
            ? buildColor("grey", "900")
            : buildColor("white", "100")
        }
        size={16}
        qaLabel="headerLabel-back-icon"
      />
      <HeaderLabel
        headerClass={this.props.headerClass}
        data-qa-label="headerLabel-back-label"
      >
        {this.props.label}
      </HeaderLabel>
    </HeaderLabelContainer>
  );

  renderVideoActionTools = () => (
    <LogoNavigationContainer
      isNavigationShown={this.props.isNavigationShown}
      className={this.props.headerClass}
      isMobile={tvgConf().device === "mobile"}
      isTitleCenter={this.props.isTitleCenter}
    >
      {!this.props.label && this.props.logoMod !== "navigation"
        ? this.renderLogo()
        : this.renderBackButton()}
      {typeof window !== "undefined" && (
        <AnimationContainer>
          <RaceNavigationContainer
            isNavigationShown={this.props.isNavigationShown}
          >
            <RaceNavigation
              {...this.props.raceData}
              onClickCallback={this.props.openRaceNavigation}
              isSmall
            />
          </RaceNavigationContainer>
          <VideoButtonContainer
            isVideoButtonShown={this.props.isVideoButtonShown}
          >
            <VideoButton
              small
              feedback={this.props.videoFeedBack}
              onClick={this.props.onVideoButton}
            />
          </VideoButtonContainer>
        </AnimationContainer>
      )}
    </LogoNavigationContainer>
  );

  renderLoggedOutActionTools = () => (
    <LoginSignup data-qa-label="header-sessionButtons">
      <ButtonTransparent
        type={
          this.props.headerClass === "logo-login"
            ? "secondary"
            : "secondary_alt"
        }
        url={this.props.loginSignup.loginUrl}
        isGrey={this.props.headerClass === "logo-login"}
        qaLabel="openLoginButton"
      >
        <LockIcon
          icon={lock}
          size={16}
          color={
            this.props.headerClass === "monochromatic" ||
            this.props.headerClass === "lowercaseMonochromatic" ||
            this.props.headerClass === "logo-login"
              ? buildColor("grey", "900")
              : buildColor("white", "100")
          }
        />
        <LoginSignupInner>
          {this.props.headerClass === "logo-login" ? "Login" : "Log in"}
        </LoginSignupInner>
      </ButtonTransparent>

      {this.props.headerClass !== "logo-login" && (
        <ButtonLinkStyled
          tag="link"
          url={this.props.loginSignup.signUpUrl}
          qaLabel="signUpButton"
        >
          Sign up
        </ButtonLinkStyled>
      )}
    </LoginSignup>
  );

  renderLoggedInActionTools = () => (
    <ActionToolsContainer>
      <BalanceButton
        url={this.props.quickDeposit.url}
        balanceAmount={this.props.quickDeposit.balance}
        onBalanceClick={this.props.quickDeposit.onClick}
        isBalanceShown={this.props.quickDeposit.isBalanceShown}
        device="mobile"
      />
      {this.props.isContentCardsInboxShown && (
        <ContentCardsInboxContainer onClick={this.openCloseInbox}>
          <NotificationBubble
            iconSize={20}
            icon={tvgConf().device === "desktop" ? email : envelope}
            counter={this.props.unviewedContentCardsCount}
            qaLabel="content-cards-inbox"
          />
        </ContentCardsInboxContainer>
      )}
    </ActionToolsContainer>
  );

  renderHeaderDescription = () => (
    <HeaderDescriptionContainer>
      <HeaderDescription>{this.props.description}</HeaderDescription>
    </HeaderDescriptionContainer>
  );

  render() {
    let isRg = false;
    if (typeof window !== "undefined") {
      isRg = location.pathname.includes("responsible-gaming");
    }

    return (
      <Container>
        <HeaderContent
          data-qa-label="header"
          layout={this.props.layout}
          className={this.props.headerClass}
          hasDescription={!!this.props.description}
        >
          {this.renderVideoActionTools()}
          {this.props.children && (
            <ChildContainer>{this.props.children}</ChildContainer>
          )}
          {get(this.props, "isLogged", false)
            ? get(this.props, "isActionToolsShown", true) &&
              this.renderLoggedInActionTools()
            : get(this.props, "isLogActionsShown") &&
              !isRg &&
              this.renderLoggedOutActionTools()}
        </HeaderContent>
        {this.props.description && this.renderHeaderDescription()}
      </Container>
    );
  }
}

export default Header;
