import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type BetCancelSubmitData = {
  type: "BET:BET_CANCEL_SUBMIT";
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
    betType: string;
  };
};

type BetCancelErrorData = {
  type: "BET:BET_CANCEL_ERROR";
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
    betType: string;
    error: string;
  };
};

type BetCancelSuccessData = {
  type: "BET:BET_CANCEL_SUCCESS";
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
    betType: string;
  };
};

export default () => {
  mediatorChannels.base.subscribe("MYBETS:BET_CANCEL", () => {
    pushToDataLayer({
      event: "myBets",
      gaEventCategory: "My Bets",
      gaEventAction: "My Bets CTA Clicked",
      gaEventLabel: "Cancel Bet", // e.g "cancel"
      module: "Race Bets",
      linkText: "Cancel Bet",
      linkUrl: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "BET:BET_CANCEL_SUBMIT",
    (data: BetCancelSubmitData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Cancel Submitted",
        gaEventLabel: undefined,
        module: "Race Bets",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionList: undefined,
        betAmount: data.payload.betAmount,
        betType: data.payload.betType
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BET:BET_CANCEL_ERROR",
    (data: BetCancelErrorData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Cancel Error",
        gaEventLabel: data.payload.error,
        module: "Race Bets",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionList: undefined,
        betAmount: data.payload.betAmount,
        betType: data.payload.betType,
        errorType: data.payload.error
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BET:BET_CANCEL_SUCCESS",
    (data: BetCancelSuccessData): void => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Cancel Successful",
        gaEventLabel: undefined,
        module: "Race Bets",
        tag: undefined,
        raceNumber: data.payload.raceNumber,
        trackName: data.payload.trackName,
        repeatBet: undefined,
        runnerSelectionList: undefined,
        betAmount: data.payload.betAmount,
        betType: data.payload.betType
      });
    }
  );

  mediatorChannels.base.subscribe("MYBETS:BET_CANCEL_KEEP_BET", () => {
    pushToDataLayer({
      event: "myBets",
      gaEventCategory: "My Bets",
      gaEventAction: "Cancel Bet CTA Clicked",
      gaEventLabel: "Keep Bet",
      module: "Cancel Bet Modal"
    });
  });

  mediatorChannels.base.subscribe("MYBETS:CANCEL_BET_MODAL_CLOSED", () => {
    pushToDataLayer({
      event: "myBets",
      gaEventCategory: "My Bets",
      gaEventAction: "Cancel Bet Modal Closed",
      gaEventLabel: undefined,
      module: "Cancel Bet Modal"
    });
  });
};
