import React from "react";
import { getTrackName } from "@urp/lib-racetracks/src/utils/races";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Tag } from "../Tag";
import { Paragraph } from "../Typography/Paragraph";
import { Container, Row } from "./styled-components";
import { TrackNameProps } from "./types";
import { CONTAINER, TRACK, TEXT } from "../../utils/constants";

export const TrackNameDisplay = ({
  raceName,
  raceNumber,
  raceHighlightedDescription,
  qaLabel = "",
  isRaceNumberOnSameLine
}: TrackNameProps) => (
  <Container data-qa-label={buildQaLabel([qaLabel, TRACK, CONTAINER])}>
    <Row>
      <Paragraph
        qaLabel={buildQaLabel([qaLabel, TRACK, TEXT, raceName])}
        color={
          raceHighlightedDescription
            ? "var(--fd-colors-content-on-dark)"
            : useColorTokens("content.default")
        }
        fontFamily="medium"
      >
        {getTrackName(raceName)} {isRaceNumberOnSameLine && `R${raceNumber}`}
      </Paragraph>
      {raceHighlightedDescription && (
        <Tag
          qaLabel={buildQaLabel([qaLabel, TRACK])}
          label={raceHighlightedDescription}
          size="small"
          variant="favorite"
          hideIcon
        />
      )}
    </Row>
    {!isRaceNumberOnSameLine && (
      <Paragraph
        qaLabel={buildQaLabel([qaLabel, TRACK, TEXT, raceNumber])}
        color={
          raceHighlightedDescription
            ? "var(--fd-colors-content-on-dark)"
            : useColorTokens("content.subtle")
        }
        fontFamily="regular"
      >
        {`Race ${raceNumber}`}
      </Paragraph>
    )}
  </Container>
);

export default TrackNameDisplay;
