import mediator from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

const buildModule = (isMyBets, isActiveBet) => {
  if (!isMyBets) {
    return "Bet Success";
  }

  return isActiveBet ? "myBets - Active" : "myBets - Settled";
};

export default () => {
  mediator.base.subscribe("CLOSE_SOCIAL_SHARE_MODAL", (data) =>
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Social Share Modal Closed",
      gaEventLabel: undefined,
      module: buildModule(
        get(data, "payload.isMyBets", false),
        get(data, "payload.isActiveBet", false)
      )
    })
  );

  mediator.base.subscribe("SHARE_BET_THROUGH_SOCIAL_MEDIA", (data) =>
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Share Bet CTA Clicked",
      gaEventLabel: "Share Bet",
      module: buildModule(
        get(data, "payload.isMyBets", false),
        get(data, "payload.isActiveBet", false)
      )
    })
  );

  mediator.base.subscribe("OPEN_SOCIAL_SHARE_MODAL", (data) =>
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Social Share Modal Opened",
      gaEventLabel: undefined,
      module: buildModule(
        get(data, "payload.isMyBets", false),
        get(data, "payload.isActiveBet", false)
      )
    })
  );
};
