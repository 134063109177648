import React from "react";
import { AppRegistry } from "react-native";
import { loadableReady } from "@loadable/component";
import { ApolloProvider } from "@apollo/client";
import ApolloContext from "@tvg/utils/apolloContext";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { attempt, get } from "lodash";
import axios from "axios";

// TODO: remove this when support for iOS 10 is over
import "whatwg-fetch";

import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import PerfCollector from "@tvg/perf-collector";
import "@tvg/gtm";
import "@tvg/braze";
import sendToAppsFlyer, { AppsFlyerEvents } from "@tvg/utils/appsflyerUtils";
import { TVGThemeProviderWeb, TvgConfProvider } from "@tvg/design-system";
import { FormationTokens } from "@tvg/design-system/web";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";

import { recoverQueriesFromBackground } from "@tvg/utils/generalUtils";
import qa from "../../config/qa";
import staging from "../../config/staging";
import production from "../../config/production";

import { configureStore } from "../shared/configureStore";
import ApolloClient from "../shared/apolloClient/ApolloClient";
import App from "../shared/pages";
import getPublicPath from "../shared/pages/utils/getPublicPath";

const publicPath = getPublicPath();

if (publicPath) {
  /* eslint-disable */
  __webpack_public_path__ = publicPath;
  /* eslint-enable */
}

// gets conf for specific host / device
const tvg = tvgConf(window.location.hostname, window.navigator.userAgent);

// this closes the window if it was opened programmatically
// in this case its the return url from MZM deposit
if (typeof window !== "undefined" && window.location.hash === "#closeTab") {
  window.localStorage.setItem("isMzmTabClosed", "true");
  window.close();
}

const enableMobileDatadogRUM = get(
  window,
  "__PRELOADED_STATE__.capi.featureToggles.enableMobileDatadogRUM",
  false
);
if (enableMobileDatadogRUM && tvg.device !== "desktop") {
  require("@datadog/browser-rum"); // initialize window.DD_RUM

  window.DD_RUM.init({
    applicationId: tvgConf().config("datadogRum").mobileApplicationId,
    clientToken: tvgConf().config("datadogRum").mobileClientToken,
    site: tvgConf().config("datadogRum").site,
    service: "tvg-mobile",
    env: tvg.environment,
    // Inorder to match the version to the build deployed in production, additional work is required within our App.
    version: "1.0.0", // Default to 1.0.0, since we can't get the build version yet.
    sessionSampleRate: 100, // The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send RUM events.
    sessionReplaySampleRate: 20, // The percentage of tracked sessions with Browser RUM & Session Replay pricing features: 100 for all, 0 for none.
    trackUserInteractions: true, // Enables automatic collection of users actions.
    trackResources: true, // Enables collection of resource events.
    trackLongTasks: true, // Enables collection of long task events. A long task event is any task in the browser that blocks the main thread for more than 50ms.
    // Default privacy options for Session Replay are designed to protect end user privacy and prevent sensitive organizational information from being collected.
    defaultPrivacyLevel: "mask-user-input"
  });
}

// set performance collector as global
window.perf = new PerfCollector(null);

// gets server side generated initial redux store
const store =
  window.store ||
  configureStore({
    initialState: window.__PRELOADED_STATE__ // eslint-disable-line
  });

// register service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker // eslint-disable-line
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
        /* eslint-disable */
        navigator.serviceWorker.controller.postMessage(
          /* eslint-enable */
          window.navigator.userAgent
        ); // eslint-disable-line
      })
      .catch((err) => {
        console.log("ServiceWorker registration failed: ", err);
      });
  });
}

// Event to catch when the user installs the PWA
window.addEventListener("beforeinstallprompt", (e) => {
  e.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "dismissed") {
      mediator.base.dispatch({
        type: "SITE_CLICK",
        payload: {
          action: "Close",
          label: "Add To Home Screen",
          module: "Add To Home Screen"
        }
      });
    } else {
      mediator.base.dispatch({
        type: "SITE_CLICK",
        payload: {
          action: "Add To Home Screen",
          label: "Add To Home Screen",
          module: "Add To Home Screen"
        }
      });
    }
  });
});

// detect private mode
(() => {
  const detectPrivateMode = (cb) => {
    let db;
    const on = cb.bind(null, true);
    const off = cb.bind(null, false);

    const tryls = () => {
      try {
        window.openDatabase(null, null, null, null);
        off();
      } catch (e) {
        on();
      }
    };

    if (window.webkitRequestFileSystem) {
      window.webkitRequestFileSystem(0, 0, off, on);
    } else if ("MozAppearance" in document.documentElement.style) {
      db = indexedDB.open("test");
      db.onerror = on;
      db.onsuccess = off;
    } else if (/constructor/i.test(window.HTMLElement) || window.safari) {
      tryls();
    } else if (
      !window.indexedDB &&
      (window.PointerEvent || window.MSPointerEvent)
    ) {
      on();
    } else {
      off();
    }
  };

  detectPrivateMode((isPrivateMode) => {
    if (isPrivateMode) {
      attempt(() => localStorage.setItem("privateMode", true));
    }
  });
})();

window.onerror = (msg, url, line, column, error) => {
  const accountId = attempt(() => sessionStorage.getItem("userId"));
  if (msg && msg !== "Script error.") {
    axios({
      url: "/logerror",
      method: "post",
      withCredentials: true,
      data: {
        msg,
        error: error && error.stack,
        accountId,
        tvgContext: tvg.context()
      }
    });
  }

  return false;
};

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept();
  }

  if (!window.store) {
    window.store = store;
  }
}

// Send AppsFlyer app launch event for iOS app
const didAppLaunch = sessionStorage.getItem("didAppLaunch");
if (!didAppLaunch) {
  sendToAppsFlyer({
    key: AppsFlyerEvents.AfAppLunch
  });
  sessionStorage.setItem("didAppLaunch", true);
}

const enableWs = get(
  window,
  "__PRELOADED_STATE__.capi.featureToggles.enableWebSocketsTVG",
  false
);

const loadPerimeterXScript = get(
  window,
  "__PRELOADED_STATE__.capi.featureToggles.enablePerimeterx",
  false
);

// This script needs to load in client side to be able to load the px script
// This will be placed only 1 time even if user refreshes or navigates to a different route
// The idea is to load the px script in client without changing the tvgConf package
if (loadPerimeterXScript) {
  import("@tvg/perimeterx/perimeterx");
}

const defaultClient = ApolloClient.createClient(false, "graph", enableWs);
const fcpClient = ApolloClient.createClient(false, "fcp");
const rdaClient = ApolloClient.createClient(false, "rda");
const gasClient = ApolloClient.createClient(false, "gas", enableWs);
const behgClient = ApolloClient.createClient(false, "behg", enableWs);

if (typeof window !== "undefined" && tvg.environment === "qa") {
  // @ts-ignore
  window.apolloClients = {
    defaultClient,
    fcpClient,
    rdaClient,
    gasClient,
    behgClient
  };
}

if (typeof window !== "undefined") {
  recoverQueriesFromBackground([
    defaultClient,
    fcpClient,
    rdaClient,
    gasClient,
    behgClient
  ]);
}
const Main = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <TvgSafeAreaProvider>
        <FormationTokens />
        <ApolloProvider client={defaultClient}>
          <ApolloContext.Provider
            value={{
              fcpClient,
              rdaClient,
              gasClient,
              behgClient
            }}
          >
            <Provider store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Provider>
          </ApolloContext.Provider>
        </ApolloProvider>
      </TvgSafeAreaProvider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);
loadableReady(() => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    rootTag: document.getElementById("app")
  });
});
