import React, { Component } from "react";
import { isEqual } from "lodash/fp";
import FooterLinkMolecule from "../../_molecule/FooterLink";
import {
  home20,
  tracks20,
  bets20,
  talent20,
  more20
} from "../../_static/Icons/icons";
import FooterNav from "./styled-components";

const icons = [home20, tracks20, bets20, talent20, more20];

export class Footer extends Component {
  static defaultProps = {
    linksList: [],
    active: 0,
    layout: "horizontal",
    isTextAside: false
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.linksList.length !== this.props.linksList.length ||
      nextProps.active !== this.props.active ||
      !isEqual(nextProps, this.props)
    );
  }

  render() {
    const FooterElements = this.props.linksList.map((element, index) => (
      <FooterLinkMolecule
        key={`footer-${element.label}`}
        qaLabel={`footer-${element.label}`}
        label={element.label}
        href={element.href}
        icon={icons[index]}
        counter={element.counter}
        selected={this.props.active === index}
        onClick={element.onClick}
        isTextAside={this.props.isTextAside}
      />
    ));

    return (
      !!this.props.linksList.length && (
        <FooterNav layout={this.props.layout}>{FooterElements}</FooterNav>
      )
    );
  }
}

export default Footer;
