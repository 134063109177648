export const initialState = {
  messages: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MESSENGER_ADD_MESSAGE": {
      return {
        ...state,
        messages: [...state.messages, action.payload.message]
      };
    }
    case "MESSENGER_REMOVE_MESSAGE": {
      return {
        ...state,
        messages: [
          ...state.messages.filter((m) => m.key !== action.payload.key)
        ]
      };
    }
    case "MESSENGER_REMOVE_ALL_MESSAGES": {
      return { ...state, messages: [] };
    }
    default: {
      return state;
    }
  }
}
