import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("RACE_ALERTS:TOGGLE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload.toggledOn")
        ? "Toggle On"
        : "Toggle Off",
      gaEventLabel: get(data, "payload.eventLabel"),
      module: get(data, "payload.module"),
      tag: undefined,
      microApp: "non-Microapp"
    });
  });
};
