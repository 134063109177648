export const successBetCancel = (wager) => ({
  type: "MYBETS_CANCEL_SUCCESS",
  payload: { wager }
});

export const openMyBets = () => ({
  type: "MYBETS_OPEN"
});

export const closeMyBets = () => ({
  type: "MYBETS_CLOSE"
});

export const setActiveBetsInfo = (activeBetsInfo) => ({
  type: "MYBETS_GET_ACTIVE_BETS",
  payload: activeBetsInfo
});

export const setSelectedTab = (selectedTab) => ({
  type: "MYBETS_SET_SELECTED_TAB",
  payload: selectedTab
});

export const setSelectedSettledTab = (selectedSettledTab) => ({
  type: "MYBETS_SET_SELECTED_SETTLED_TAB",
  payload: selectedSettledTab
});

export const setUpcomingBetsBadge = (badgeCounter) => ({
  type: "MYBETS_SET_UPCOMING_BETS_BADGE",
  payload: badgeCounter
});

export const updateDeletedBets = (serialNumber, shouldRemove) => ({
  type: "MYBETS_UPDATE_DELETED_BETS",
  payload: {
    serialNumber,
    shouldRemove
  }
});
