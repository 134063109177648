import styled from "styled-components";
import { fontMedium } from "../../_static/Typography";
import Button from "../../_atom/Buttons/button";

const VideoButtonContainer = styled(Button)`
  font-family: ${fontMedium};
  font-size: 14px;
`;

export default VideoButtonContainer;
