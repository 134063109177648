import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";
// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type MenuClickPayload = {
  type: "NAVIGATION:MENU_CLICK";
  payload: {
    action?: string;
    link?: string;
    destinationUrl?: string;
    menu?: string;
    module?: string;
  };
};

type HamburgerMenuClickPayload = {
  type: "NAVIGATION:HAMBURGER_MENU_CLICK";
  payload: {
    isOpen: boolean;
  };
};

type BetSelectorClickPayload = {
  type: "NAVIGATION:BET_SELECTOR_CLICK";
  payload: {
    betType: string;
    raceNumber: string | null;
    trackName: string;
  };
};

export default () => {
  mediatorChannels.base.subscribe(
    "NAVIGATION:MENU_CLICK",
    (data: MenuClickPayload): void => {
      pushToDataLayer({
        event: "navigation",
        gaEventAction: get(data, "payload.action", undefined),
        gaEventCategory: "Navigation",
        gaEventLabel: get(data, "payload.link", undefined),
        module: get(data, "payload.module", undefined),
        destinationUrl: get(data, "payload.destinationUrl", undefined),
        tag: undefined,
        menu: get(data, "payload.menu", undefined)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "NAVIGATION:HAMBURGER_MENU_CLICK",
    ({ payload }: HamburgerMenuClickPayload): void => {
      pushToDataLayer({
        event: "navigation",
        gaEventAction: get(payload, "isOpen")
          ? "Hamburger Menu Opened"
          : "Hamburger Menu Closed",
        gaEventCategory: "Navigation",
        gaEventLabel: undefined,
        module: "Global Header",
        destinationUrl: undefined,
        tag: undefined,
        menu: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "NAVIGATION:BET_SELECTOR_CLICK",
    (data: BetSelectorClickPayload): void => {
      pushToDataLayer({
        event: "program",
        gaEventCategory: "Program",
        gaEventAction: "Bet Type Selected",
        gaEventLabel: get(data, "payload.betType"),
        module: "Bet Selector",
        tag: undefined,
        raceNumber: get(data, "payload.raceNumber"),
        trackName: get(data, "payload.trackName"),
        sport: "Horse Racing"
      });
    }
  );
};
