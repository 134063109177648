export const REGISTRATION_STEPS = {
  WELCOME: "WELCOME",
  AUTO_ADDRESS: "AUTO_ADDRESS",
  ADDRESS: "ADDRESS",
  SSN: "SSN",
  FULLSSN: "FULLSSN",
  REVIEW: "REVIEW",
  AUTHENTICATION: "AUTHENTICATION",
  ONFIDO: "ONFIDO",
  SUCCESS: "SUCCESS"
};

export const STEPS_SEQUENCE = [
  REGISTRATION_STEPS.WELCOME,
  REGISTRATION_STEPS.AUTO_ADDRESS,
  REGISTRATION_STEPS.ADDRESS,
  REGISTRATION_STEPS.SSN,
  REGISTRATION_STEPS.REVIEW,
  REGISTRATION_STEPS.FULLSSN,
  REGISTRATION_STEPS.AUTHENTICATION
];

export const getRegistrationProgress = (currentStep) => {
  const isThirdStepSelected = [
    REGISTRATION_STEPS.SSN,
    REGISTRATION_STEPS.REVIEW,
    REGISTRATION_STEPS.FULLSSN,
    REGISTRATION_STEPS.AUTHENTICATION
  ].includes(currentStep);

  return [
    true,
    isThirdStepSelected ||
      [REGISTRATION_STEPS.AUTO_ADDRESS, REGISTRATION_STEPS.ADDRESS].includes(
        currentStep
      ),
    isThirdStepSelected
  ];
};
