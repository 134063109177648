import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  ITEM_SELECTED = "Item Selected",
  FDTV_TYPE = "FDTV Type",
  RACE_STATUS = "Race Status",
  MTP = "Mtp"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  MENU_CLICK = "NAVIGATION:MENU_CLICK",
  HAMBURGER_MENU_CLICK = "NAVIGATION:HAMBURGER_MENU_CLICK",
  BET_SELECTOR_CLICK = "NAVIGATION:BET_SELECTOR_CLICK"
}

export type MenuClickPayload = {
  type: MediatorEventType.MENU_CLICK;
  payload: {
    action?: string;
    link?: string;
    destinationUrl?: string;
    menu?: string;
    module?: string;
    trackName?: string;
    selectionRaceNumber?: string;
    currentRaceNumber?: string;
  };
};

export type HamburgerMenuClickPayload = {
  type: MediatorEventType.HAMBURGER_MENU_CLICK;
  payload: {
    isOpen: boolean;
  };
};

export type BetSelectorClickPayload = {
  type: MediatorEventType.BET_SELECTOR_CLICK;
  payload: {
    betType: string;
    raceNumber: string | null;
    trackName: string;
    fdtvType?: "fdr_tv" | "fdtv_plus" | "no_fdtv";
    race: {
      status: {
        code: string;
      };
      mtp: string;
    };
  };
};

export const transformedNavigation = [
  "Global Navigation Link Clicked",
  "Account Panel Link Clicked",
  "LHN Navigation Link Clicked"
];
