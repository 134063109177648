import styled from "styled-components";

import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";
import overlayBackground from "../../_static/images/mobile-alert-bg.png";

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  ${({ device }) =>
    device === "mobile" ? "align-items: flex-end;" : "align-items: center;"}
  width: 100%;
  height: 100%;
  padding: 48px 12px;
  background: url("${overlayBackground}") no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-color: ${buildColor("blue", "800")};
  z-index: 2000;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 475px;
  ${({ device }) =>
    device === "tablet" ? "margin-top: 155px;" : "margin-top: 0;"}
  padding: 12px;
  border-radius: 4px;
  background: ${buildColor("white", "100")};
`;

export const Logo = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  margin-bottom: 14px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

export const Description = styled.div`
  margin-bottom: 20px;
  color: ${buildColor("grey", "800")};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;
