import mediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";

export const setSessionId = (sessionId: string | undefined) => {
  const event = getUMEvent(UMEvents.SetSessionId);

  mediator.dispatch({
    type: event.trigger,
    payload: { sessionId }
  });
};
