import { ButtonHTMLAttributes } from "react";
import { type SpaceProps } from "styled-system";
import { IconSizeVariants } from "../../theme";
import { type IconNames } from "../Icon/types";
import { PopperPlacement } from "../../../src/index";

export type IconButtonSize = "s" | "m" | "l";

export const defaultColors: OverrideColorsDefault = {
  light: {
    default: "--fd-colors-component-button-transparent-background-base",
    hover: "--fd-colors-component-button-transparent-background-hover",
    active: "--fd-colors-component-button-transparent-background-active",
    disabled: "--fd-colors-component-button-destructive-background-disabled",
    iconColor: "--fd-colors-content-strong",
    iconActiveColor: "--fd-colors-component-button-transparent-content-base",
    iconHoverColor: "--fd-colors-component-button-tertiary-content-hover",
    iconDisabledColor:
      "--fd-colors-component-button-destructive-content-disabled"
  },
  dark: {
    default: "--fd-colors-component-button-transparent-background-base",
    hover: "--fd-colors-component-button-transparent-background-hover",
    active: "--fd-colors-core-white-tint-030",
    disabled: "--fd-colors-core-white-tint-005",
    iconColor: "--fd-colors-component-button-transparent-content-base",
    iconDisabledColor: "--fd-colors-core-white-tint-050"
  }
};

export interface OverrideColorsDefault {
  light: {
    default: string;
    hover: string;
    active: string;
    disabled?: string;
    iconColor?: string;
    iconActiveColor?: string;
    iconHoverColor?: string;
    iconDisabledColor?: string;
  };
  dark: {
    default: string;
    hover: string;
    active: string;
    disabled?: string;
    iconColor?: string;
    iconActiveColor?: string;
    iconHoverColor?: string;
    iconDisabledColor?: string;
  };
}

export interface OverrideColorsProps {
  default: string;
  hover: string;
  active: string;
  disabled?: string;
  iconColor?: string;
  iconActiveColor?: string;
  iconHoverColor?: string;
  iconDisabledColor?: string;
}

export interface IconButtonProps
  extends SpaceProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button size
   * s/m/l
   * @default m
   */
  size?: IconButtonSize;
  /**
   *  IconButton size
   * s/m/l/xl
   * @default m
   */
  iconSize?: IconSizeVariants;
  /**
   *  Custom icon
   */
  iconName?: IconNames;
  /**
   *  Custom tooltip text
   */
  textTooltip?: string;
  /**
   *  Custom tooltip placement
   *  only for textTooltip
   */
  placementTooltip?: PopperPlacement;
  /**
   * Disables the icon button
   */
  isDisabled?: boolean;
  /**
   * Custom data-qa-label attribute applied to element
   * */
  qaLabel: string;
  /**
   * light/dark mode
   */
  mode?: "light" | "dark";
  /**
   * Override colors for different variants
   */
  overrideColors?: OverrideColorsProps;
  /**
   * Force to active
   */
  forceActive?: boolean;
  /**
   * Has border
   */
  hasBorder?: boolean;
}
