import React, { Component } from "react";
import { connect } from "react-redux";
import { get, noop, isNumber, bindAll, some, attempt } from "lodash";

import { RolesMap } from "@tvg/types/User";

import PreferenceService from "@tvg/api/uam";
import messenger from "@tvg/messenger";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import isMobile from "@tvg/utils/mobileUtils";
import AccountTemplate from "@tvg/atomic-ui/_templates/Account";
import withdrawals from "@tvg/withdrawals/src/service";
import hasWagerProfileException from "@tvg/utils/wagerRewardsUtils";
import parseCapiMessage from "@tvg/utils/capiUtils";

import PromosOnboarding from "@tvg/promos-onboarding";
import { events as AlchemerEvents } from "@urp/alchemer";
import {
  SubWalletContent,
  subWalletsContentDefault
} from "@tvg/atomic-ui/_molecule/AccountSubWallets";
import { getAccountNumber, getEmail, getBalance } from "@urp/store-selectors";
import getTutorialsList from "./services/tutorialsListService";
import {
  balanceShownAction,
  iOSTouchIdToggleChange,
  setBetConfirmationPreference,
  setWithdrawals,
  setTutorialsListAction
} from "./actions";

export const moreLinksGtm = (url, linkName, menu) => {
  mediator.base.dispatch({
    type: "MORE_NAVIGATION",
    payload: {
      linkName,
      url,
      menu
    }
  });
};

export const openContentMoreGtm = (itemOpened) => {
  mediator.base.dispatch({
    type: "MORE_OPEN_CONTENT",
    payload: {
      itemOpened
    }
  });
};

const supportOpenGtm = () => {
  mediator.base.dispatch({
    type: "SUPPORT_MODAL_TOGGLE",
    payload: {
      ToggleModal: "open"
    }
  });
};

const onSendFeedback = () => {
  AlchemerEvents.showSendFeedbackSurvey();
};

export const onPreferencesExpandCallBack = (isExpanded) => {
  mediator.base.dispatch({
    type: "MORE_PAGE:PREFERENCES_SECTION",
    payload: {
      isExpanded
    }
  });
};

export const hasNewLink = (tutorialsList) =>
  some(tutorialsList, (tutorialsListItem) => tutorialsListItem.isNew);

export const onExternalLinkCallback = (url, event) => {
  const pattern = /^https?:\/\//;
  const isEmailOrTel = /^(mailto|tel):.+/.test(url);
  const isRelativePath = /^\/.+/.test(url);
  const isFullUrl = pattern.test(url);

  let openExternalApp =
    !isFullUrl && !isRelativePath && !isEmailOrTel ? `http://${url}` : url;
  openExternalApp = isRelativePath
    ? `${window.location.protocol}//${window.location.host}${url}`
    : openExternalApp;

  event.preventDefault();
  event.stopPropagation();

  if (isMobile(tvgConf().product)) {
    mediator.ios.dispatch({
      type: "OPEN_EXTERNAL_APP",
      payload: { openExternalApp }
    });
  } else if (isEmailOrTel) {
    window.location.href = openExternalApp;
  } else {
    window.open(openExternalApp, "_blank");
  }
};

export class AccountMenuComponent extends Component {
  static defaultProps = {
    talentPicksPageEnableToggle: false,
    enableTalentPickAppToggle: false,
    enableWagerRewards: false,
    userBetPreferences: false,
    showResponsibleGaming: false,
    hasRgLoggedOut: false,
    showAccountSummary: false,
    enableReferFriend: false,
    isLogged: false,
    accountNumber: "",
    userRoles: [],
    userFirstName: "",
    userLastName: "",
    userGeoLocationState: "",
    userEmail: "",
    userBalance: 0,
    userPromoFunds: 0,
    balanceIsShown: "0",
    onLogout: noop,
    routes: {
      deposit: "#funds",
      withdraw: "#withdraw",
      home: "/home",
      tracks: "/tracks",
      results: "/results",
      bets: "#my-bets",
      promos: "#promos",
      picks: "/picks",
      live: "/live",
      wagerRewards: "/wager-rewards",
      privacyPolicy: "#privacyPolicy",
      termsConditions: "#termsConditions",
      wagerResponsibly: "#wagerResponsibly",
      responsibleGaming: "/responsible-gaming",
      tutorials: "#tutorials",
      support: "#support",
      referFriend: "/referral",
      preferences: "/preferences",
      accessibility: "#accessibility",
      quickWithdraw: "#quick-withdraw",
      quickDeposit: "#quick-deposit"
    },
    feedbackUrl: "",
    careersUrl: "",
    desktopUrl: "",
    watchTvgFaqUrl: "",
    phoneUrl: "",
    emailUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    isBetConfirmationEnabled: "0",
    isTouchIdEnabled: false,
    isHandicapStoreModalEnabled: false,
    isSeoTrackListEnabled: false,
    showEnableTouchId: false,
    isFaceId: false,
    hasPendingWithdrawals: false,
    withdrawalModalState: { open: false, type: "" },
    hasTutorials: false,
    tutorialsLinkNew: false,
    externalDeposit: false,
    appVersion: "",
    bettingGuideMenu: {},
    doNotSellToggle: false,
    doNotSellLabel: "",
    doNotSellUrl: "",
    referFriendLabel: "",
    brazeContentCards: {
      unviewed: 0,
      totalCards: 0,
      cards: []
    },
    signUpUrl: "/",
    loginUrl: "/",
    headerContentCardsInboxToggle: false,
    loginMessage: "",
    enableSeoMEPMenuItems: false,
    seoMEPMenuItems: [
      {
        name: "",
        route: "/",
        subQaLabel: ""
      }
    ],
    usePrefReact: false,
    pendingWithdrawals: 0,
    showConnecticutDisclaimer: false,
    connecticutDisclaimer: "",
    enableAccessibility: false,
    pendingWithdrawalsV2: false,
    globalTVGSupportLink: "",
    hasQuickWithdraw: false,
    availablePaymentMethodsInfos: {},
    selectedPaymentMethod: "",
    enablePawsQuickDeposits: false,
    isPawsSubWalletsAvailable: false,
    pawsSubWalletsContent: []
  };

  constructor(props) {
    super(props);
    bindAll(this, [
      "onHideBalance",
      "toggleBetConfirmationPreference",
      "toggleTouchId",
      "getTalentPickUrl"
    ]);
  }

  componentDidMount() {
    if (this.props.isLogged && this.props.accountNumber !== "") {
      this.getPendingWithdrawals();
    }

    if (!this.props.hasTutorials) {
      getTutorialsList().then((tutorialsList) => {
        this.props.dispatch(setTutorialsListAction(tutorialsList));
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.hasTutorials !== this.props.hasTutorials ||
      nextProps.tutorialsLinkNew !== this.props.tutorialsLinkNew ||
      nextProps.isLogged !== this.props.isLogged ||
      nextProps.userBalance !== this.props.userBalance ||
      nextProps.accountNumber !== this.props.accountNumber ||
      nextProps.userFirstName !== this.props.userFirstName ||
      nextProps.userLastName !== this.props.userLastName ||
      nextProps.userEmail !== this.props.userEmail ||
      nextProps.userGeoLocationState !== this.props.userGeoLocationState ||
      nextProps.talentPicksPageEnableToggle !==
        this.props.talentPicksPageEnableToggle ||
      nextProps.balanceIsShown !== this.props.balanceIsShown ||
      nextProps.isHandicapStoreModalEnabled !==
        this.props.isHandicapStoreModalEnabled ||
      nextProps.isSeoTrackListEnabled !== this.props.isSeoTrackListEnabled ||
      nextProps.isBetConfirmationEnabled !==
        this.props.isBetConfirmationEnabled ||
      nextProps.isTouchIdEnabled !== this.props.isTouchIdEnabled ||
      nextProps.hasPendingWithdrawals !== this.props.hasPendingWithdrawals ||
      nextProps.withdrawalModalState.open !==
        this.props.withdrawalModalState.open ||
      nextProps.loginMessage !== this.props.loginMessage
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    // re-get the pending withdrawals when closing the withdrawal modal
    if (
      this.props.withdrawalModalState.type === "withdraw" &&
      this.props.withdrawalModalState.open &&
      nextProps.withdrawalModalState.type === "withdraw" &&
      !nextProps.withdrawalModalState.open
    ) {
      this.getPendingWithdrawals();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.isLogged && this.props.isLogged) ||
      prevProps.accountNumber !== this.props.accountNumber
    ) {
      this.getPendingWithdrawals();
    }
  }

  onHideBalance() {
    const hideBalance = this.props.balanceIsShown === "0" ? "1" : "0";
    PreferenceService.setUserPref(
      +this.props.accountNumber,
      "balance_is_shown",
      hideBalance
    );

    this.props.dispatch(balanceShownAction(hideBalance));
    mediator.base.dispatch({
      type: "MORE_PAGE:BALANCE_SHOWN",
      payload: {
        isBalanceShown: hideBalance
      }
    });
  }

  getPendingWithdrawals() {
    withdrawals
      .getWithdrawals(this.props.accountNumber)
      .then((w) => this.props.dispatch(setWithdrawals(w)));
  }

  getTalentPickUrl() {
    if (
      this.props.enableTalentPickAppToggle &&
      this.props.userRoles.length &&
      this.props.userRoles.some((role) => RolesMap[role])
    ) {
      return `${window.location.protocol}//${tvgConf().config(
        "domain.talentPicks"
      )}`;
    }

    return "";
  }

  toggleBetConfirmationPreference(currentValue) {
    const nextValue = currentValue === "0" ? "1" : "0";
    this.props.dispatch(setBetConfirmationPreference(nextValue));

    mediator.base.dispatch({
      type: "MORE_SWITCH_TOGGLE",
      payload: {
        action: nextValue === "1" ? "Toggle On" : "Toggle Off",
        field: "betConfirmation"
      }
    });

    return PreferenceService.setUserPref(
      +this.props.accountNumber,
      "show_bet_confirm",
      nextValue
    ).catch(() => {
      this.props.dispatch(setBetConfirmationPreference(currentValue));
      messenger.create({
        type: "error",
        position: "bottom",
        hasIcon: true,
        message: "Error while trying to change bet confirmation setting"
      });
    });
  }

  toggleTouchId(newValue) {
    this.props.dispatch(iOSTouchIdToggleChange(true));

    mediator.ios.dispatch({
      type: "PREFERENCE_TOUCH_ID",
      payload: {
        touchIdEnabled: newValue
      }
    });

    mediator.base.dispatch({
      type: "MORE_SWITCH_TOGGLE",
      payload: {
        action: newValue ? "Toggle On" : "Toggle Off",
        field: "touchId"
      }
    });
  }

  render() {
    const userName = `${this.props.userFirstName} ${this.props.userLastName}`;
    const balanceThreshold = 20;
    const bettingGuideMenu =
      attempt(() => JSON.parse(this.props.bettingGuideMenu)) || {};
    const userAuthenticatedState = attempt(() =>
      localStorage.getItem("userAuthenticationState")
    );
    return (
      <AccountTemplate
        appVersion={this.props.appVersion}
        accountNumber={this.props.accountNumber}
        userBetPreferences={this.props.userBetPreferences}
        userName={userName}
        userEmail={this.props.userEmail}
        showUserInfo={this.props.isLogged}
        showBalanceInfo={this.props.isLogged}
        showLoginMessage={!this.props.isLogged}
        showLogoutButton={this.props.isLogged}
        showBetConfirmationToggle={this.props.isLogged}
        isBalanceButtonHighlighted={
          isNumber(this.props.userBalance) &&
          this.props.userBalance <= balanceThreshold
        }
        privacypolicyUrl={this.props.routes.privacyPolicy}
        californiaPolicyUrl={this.props.routes.californiaPolicy}
        termsConditionsUrl={this.props.routes.termsConditions}
        showWagerResponsibly={
          !this.props.isLogged || !this.props.showResponsibleGaming
        }
        wagerResponsiblyUrl={this.props.routes.wagerResponsibly}
        showResponsibleGaming={
          (this.props.isLogged && this.props.showResponsibleGaming) ||
          (!this.props.isLogged &&
            this.props.showResponsibleGaming &&
            this.props.hasRgLoggedOut)
        }
        showAccountSummary={
          this.props.isLogged && this.props.showAccountSummary
        }
        accountSummaryLinkNew
        responsibleGamingUrl={this.props.routes.responsibleGaming}
        tutorialsUrl={this.props.routes.tutorials}
        hasTutorials={this.props.isLogged && this.props.hasTutorials}
        tutorialsLinkNew={this.props.tutorialsLinkNew}
        balance={this.props.userBalance}
        promoFunds={this.props.userPromoFunds}
        subWalletsContent={this.props.pawsSubWalletsContent}
        hideBalance={this.props.balanceIsShown === "0"}
        onHideBalance={this.onHideBalance}
        externalDeposit={this.props.externalDeposit}
        depositUrl={
          this.props.enablePawsQuickDeposits
            ? this.props.routes.quickDeposit
            : this.props.routes.deposit
        }
        withdrawUrl={
          this.props.hasQuickWithdraw
            ? this.props.routes.quickWithdraw
            : this.props.routes.withdraw
        }
        homeUrl={this.props.routes.home}
        picksUrl={this.props.routes.picks}
        isTalentPicksPageEnabled={this.props.talentPicksPageEnableToggle}
        tracksUrl={this.props.routes.tracks}
        trackListUrl={this.props.routes.trackList}
        resultsUrl={this.props.routes.results}
        betsUrl={this.props.routes.bets}
        promotionsUrl={this.props.routes.promos}
        referFriendLabel={this.props.referFriendLabel}
        referFriendUrl={this.props.routes.referFriend}
        showReferFriend={this.props.isLogged && this.props.enableReferFriend}
        handicappingStoreUrl={this.props.routes.handicappingStoreUrl}
        supportUrl={this.props.routes.support}
        onLogout={this.props.onLogout}
        navigationCallBack={moreLinksGtm}
        talentPickAppUrl={this.getTalentPickUrl()}
        feedbackUrl="mailto:support@tvg.com"
        careersUrl="https://boards.greenhouse.io/fanduelgrouptvg"
        desktopUrl={`https://${tvgConf().config(
          "domain.desktop"
        )}/backtodesktop`}
        watchTvgFaqUrl={this.props.globalTVGSupportLink}
        phoneUrl="tel:1-888-752-9884"
        emailUrl="mailto:support@tvg.com"
        facebookUrl="https://www.facebook.com/TVGNetwork"
        twitterUrl="https://twitter.com/TVG"
        watchLiveUrl={this.props.routes.live}
        wagerRewardsUrl={
          this.props.enableWagerRewards ? this.props.routes.wagerRewards : ""
        }
        wagerRewardsLinkNew
        bettingGuideLink={get(bettingGuideMenu, "link", null)}
        bettingGuideLinkNew={get(bettingGuideMenu, "new", false)}
        bettingGuideLinkName={get(bettingGuideMenu, "name", null)}
        onExternalLinkCallback={onExternalLinkCallback}
        onSendFeedback={onSendFeedback}
        onPreferencesExpandCallBack={onPreferencesExpandCallBack}
        contentNavigationCallBack={openContentMoreGtm}
        isBetConfirmationEnabled={this.props.isBetConfirmationEnabled === "1"}
        isTouchIdEnabled={this.props.isTouchIdEnabled}
        isHandicapStoreModalEnabled={this.props.isHandicapStoreModalEnabled}
        isSeoTrackListEnabled={this.props.isSeoTrackListEnabled}
        showEnableTouchId={this.props.showEnableTouchId && this.props.isLogged}
        onBetConfirmationToggle={() =>
          this.toggleBetConfirmationPreference(
            this.props.isBetConfirmationEnabled
          )
        }
        onTouchIdToggle={() => this.toggleTouchId(!this.props.isTouchIdEnabled)}
        isFaceId={this.props.isFaceId}
        pendingWithdrawalsUrl={this.props.routes.pendingWithdrawals}
        hasPendingWithdrawals={this.props.hasPendingWithdrawals}
        onPendingWithdrawalsClick={() =>
          mediator.base.dispatch({
            type: "PENDING_WITHDRAWALS_OPEN",
            payload: {
              accountId: this.props.accountNumber
            }
          })
        }
        onWatchLiveClick={() =>
          mediator.base.dispatch({
            type: "MORE_LIVE_PAGE_CLICK",
            payload: {
              destinationUrl: this.props.routes.live
            }
          })
        }
        device={tvgConf().device}
        supportGtm={supportOpenGtm}
        doNotSellLabel={
          this.props.doNotSellToggle ? this.props.doNotSellLabel : ""
        }
        doNotSellUrl={this.props.doNotSellToggle ? this.props.doNotSellUrl : ""}
        brazeContentCards={this.props.brazeContentCards}
        signUpUrl={this.props.signUpUrl}
        loginUrl={this.props.loginUrl}
        headerContentCardsInboxToggle={this.props.headerContentCardsInboxToggle}
        loginMessageTitle={get(this.props.loginMessage, "title", "")}
        loginMessageDescription={get(
          this.props.loginMessage,
          "description",
          ""
        )}
        enableSeoMEPMenuItems={this.props.enableSeoMEPMenuItems}
        seoMEPMenuItems={this.props.seoMEPMenuItems}
        hasWagerProfileException={hasWagerProfileException(
          this.props.tvgMenus,
          this.props.userProfileType
        )}
        showPrefReact={this.props.usePrefReact && this.props.isLogged}
        preferencesUrl={this.props.routes.preferences}
        pendingWithdrawals={this.props.pendingWithdrawals}
        showConnecticutDisclaimer={
          this.props.showConnecticutDisclaimer &&
          this.props.isLogged &&
          (userAuthenticatedState === "CT" ||
            (tvgConf().product === "ios2" &&
              this.props.userGeoLocationState === "CT"))
        }
        connecticutDisclaimer={this.props.connecticutDisclaimer}
        accessibilityUrl={this.props.routes.accessibility}
        enableAccessibility={this.props.enableAccessibility}
        showPendingWithdrawalsV2={this.props.pendingWithdrawalsV2}
        hasQuickWithdraw={this.props.hasQuickWithdraw}
        enablePawsQuickDeposits={this.props.enablePawsQuickDeposits}
        shouldShowPromoOnboarding
        renderPromoOnboarding={
          <PromosOnboarding
            isDarkMode
            isInternalComponent
            componentName="progress"
            device="mobile"
          />
        }
        isPawsSubWalletsAvailable={this.props.isPawsSubWalletsAvailable}
        menuOptionsBlackList={this.props.menuOptionsBlackList}
      />
    );
  }
}

export default connect(
  (store) => ({
    doNotSellToggle: get(store, "capi.featureToggles.doNotSell", false),
    talentPicksPageEnableToggle: get(
      store,
      "capi.featureToggles.talentPicksPageEnable",
      false
    ),
    enableTalentPickAppToggle: get(
      store,
      "capi.featureToggles.enableTalentPickApp",
      false
    ),
    userBetPreferences: get(
      store,
      "capi.featureToggles.userBetPreferences",
      false
    ),
    enableWagerRewards: get(
      store,
      "capi.featureToggles.wagerRewardsApp",
      false
    ),
    showResponsibleGaming: get(
      store,
      "capi.featureToggles.showResponsibleGaming",
      false
    ),
    hasRgLoggedOut: get(store, "capi.featureToggles.hasRgLoggedOut", false),
    showAccountSummary: get(
      store,
      "capi.featureToggles.showAccountSummary",
      false
    ),
    enableReferFriend: get(
      store,
      "capi.featureToggles.enableReferFriendMicroApp",
      false
    ),
    enableAccessibility: get(
      store,
      "capi.featureToggles.enableAccessibility",
      false
    ),
    usePrefReact: get(store, "capi.featureToggles.usePrefReact", false),
    hasTutorials:
      get(store, "capi.featureToggles.enableOnboardings", false) &&
      !!get(store, "mobileAccount.tutorialsList.length", 0),
    tutorialsLinkNew:
      get(store, "mobileAccount.tutorialsList.length", 0) &&
      hasNewLink(get(store, "mobileAccount.tutorialsList", [])),
    isLogged: get(store, "userData.logged"),
    userBalance: getBalance(store),
    accountNumber: getAccountNumber(store),
    userRoles: get(store, "userData.user.accountRoles"),
    userFirstName: get(store, "userData.user.firstName"),
    userLastName: get(store, "userData.user.lastName"),
    userGeoLocationState: get(store, "geolocation.state", ""),
    userEmail: getEmail(store),
    balanceIsShown: get(store, "userData.preferences.balance_is_shown", "0"),
    isBetConfirmationEnabled: get(
      store,
      "userData.preferences.show_bet_confirm",
      "0"
    ),
    userProfileType: get(store, "userData.user.profile"),
    isHandicapStoreModalEnabled: get(
      store,
      "capi.featureToggles.equibaseHandicapStore",
      false
    ),
    isSeoTrackListEnabled: get(
      store,
      "capi.featureToggles.enableSeoContentTrackList",
      false
    ),
    isTouchIdEnabled: get(store, "ios.touchId.touchIdEnabled", false),
    hasPendingWithdrawals: !!get(store, "withdrawals.withdrawals", []).length,
    withdrawalModalState: get(store, "modal.deposits"),
    bettingGuideMenu: get(store, "capi.messages.bettingGuideMenu", "{}"),
    doNotSellLabel: get(store, "capi.messages.doNotSellLabel", ""),
    doNotSellUrl: get(store, "capi.messages.doNotSellLink", ""),
    referFriendLabel: get(store, "capi.messages.referFriendLabel", ""),
    brazeContentCards: get(store, "brazeData.brazeContentCards"),
    headerContentCardsInboxToggle: get(
      store,
      "capi.featureToggles.headerContentCardsInbox"
    ),
    loginMessage: parseCapiMessage(store, "capi.messages.loginMessageMorePage"),
    seoMEPMenuItems: parseCapiMessage(store, "capi.messages.seoMEPMenuItems"),
    enableSeoMEPMenuItems: get(
      store,
      "capi.featureToggles.enableSeoMEPMenuItems",
      false
    ),
    tvgMenus: parseCapiMessage(store, "capi.messages.globalTVG4MenuItems"),
    pendingWithdrawals: get(store, "withdrawals.withdrawals", []).length,
    showConnecticutDisclaimer: get(
      store,
      "capi.featureToggles.showConnecticutDisclaimer",
      false
    ),
    connecticutDisclaimer: get(
      store,
      "capi.messages.connecticutDisclaimer",
      ""
    ),
    pendingWithdrawalsV2: get(
      store,
      "capi.featureToggles.pendingWithdrawalsV2",
      false
    ),
    globalTVGSupportLink: get(
      store,
      "capi.messages.globalTVGSupportLink",
      tvgConf().getExternalDomain().support
    ),
    selectedPaymentMethod: get(
      store,
      "paws.paymentMethodSelector.selectedMethod.paymentType",
      ""
    ),
    userPromoFunds: get(store, "userData.promoFunds", 0),
    pawsSubWalletsContent: parseCapiMessage(
      store,
      "capi.messages.pawsSubWalletsContent",
      subWalletsContentDefault
    ),
    isPawsSubWalletsAvailable: get(
      store,
      "capi.featureToggles.pawsSubWalletsAvailable",
      false
    ),
    menuOptionsBlackList: parseCapiMessage(
      store,
      "Global.menuOptionsBlackList",
      []
    )
  }),
  (dispatch) => ({ dispatch })
)(AccountMenuComponent);
