import {
  UserCustomProperties,
  setAmplitudeUserProperties,
  setAmplitudeUserId,
  setAmplitudeAnonymize as amplitudeSessionEnd,
  trackingInit,
  buildGlobalProperties
} from "@urp/amplitude";
import { MEP_PAGE_CONFIG } from "@urp/amplitude/src/constants";
import { Location } from "react-router";

interface GlobalProps {
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  user: {
    balance: number;
    accountNumber: number;
    isLogged: boolean;
    homeState: string;
  };
  geolocation: {
    state: string;
  };
  deviceManufacturer: string;
  deviceModel: string;
}

interface UserInfo {
  accountNumber: string;
  homeState: string;
}

const getAmplitudeGlobalProperties = (
  props: GlobalProps,
  jurisdiction: string
) => {
  const { location, user, deviceManufacturer, deviceModel } = props;
  const { balance, isLogged } = user;
  return buildGlobalProperties({
    balance,
    isLogged,
    location: location as Location,
    pageConfig: MEP_PAGE_CONFIG,
    pageTitle: document.title,
    jurisdiction: jurisdiction || user.homeState,
    deviceManufacturer,
    deviceModel
  });
};

const amplitudeSetLocation = (state: string) => {
  setAmplitudeUserProperties({
    [UserCustomProperties.USER_LOCATION]: state
  });
};

const amplitudeSessionStart = (props: UserInfo) => {
  const { accountNumber, homeState } = props;
  setAmplitudeUserId(accountNumber);
  amplitudeSetLocation(homeState.toLowerCase());
};

export {
  trackingInit,
  getAmplitudeGlobalProperties,
  amplitudeSessionStart,
  amplitudeSessionEnd,
  MEP_PAGE_CONFIG
};
