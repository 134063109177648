import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("PREF_UPDATE", (data) => {
    pushToDataLayer({
      event: "preferences",
      gaEventAction: data.payload.gaEventAction,
      gaEventCategory: "Preferences",
      gaEventLabel: data.payload.gaEventLabel,
      module: data.payload.module
    });
  });
};
