import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("ONBOARDINGS:CLOSE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: undefined,
      module: data.payload.tutorialName,
      tag: data.payload.closingStage,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("ONBOARDINGS:FIND_OUT_MORE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Find out more",
      gaEventLabel: undefined,
      module: data.payload.tutorialName,
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("ONBOARDINGS:NAVIGATE_STEPS", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: data.payload.orientation,
      gaEventLabel: undefined,
      module: data.payload.tutorialName,
      tag: data.payload.currentSlide,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("ONBOARDINGS:COMPLETE", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: data.payload.destination,
      module: data.payload.tutorialName,
      tag: data.payload.label,
      microApp: "non-MicroApp",
      sport: undefined,
      menu: undefined,
      destinationUrl: data.payload.destination
    });
  });
};
