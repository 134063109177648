import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("POOLS_INFO:OPEN_CLOSE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload.open") ? "Open" : "Close",
      gaEventLabel: "Pools & Info Modal",
      module: get(data, "payload.module"),
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("POOLS_INFO:EXPAND_COLLAPSE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload.action"),
      gaEventLabel: get(data, "payload.section"),
      module: "Pools & Info Modal",
      tag: `${get(data, "payload.trackName")} R${get(
        data,
        "payload.raceNumber"
      )}`,
      microApp: undefined
    });
  });
};
