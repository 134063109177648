import { findIndex } from "lodash";

export const initialState = {
  races: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "UPNEXT_BETS_REQUEST_SUCCESS": {
      const newRaces = state.races.map((r) => r);
      const userBets = action.payload.bets;

      // for each bet that the user has, lets try to find an up next race with the same track code and number
      userBets.forEach((b) => {
        const raceWithBetIndex = findIndex(
          newRaces,
          (r) => r.trackCode === b.trackCode && r.number === b.raceNumber
        );

        // if so, mark it with hasBets true so the little B tag on the right top corner shows up on the molecule
        if (newRaces[raceWithBetIndex]) {
          newRaces[raceWithBetIndex].hasBets = true;
        }
      });

      return { ...state, races: newRaces };
    }
    case "UPNEXT_BETS_REQUEST_ERROR": {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
