import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

export const Button = styled.div`
  display: flex;
  font-size: 14px;
`;

export const Label = styled.span`
  margin-left: 8px;
  font-family: ${fontNormal};
  color: ${buildColor("blue_accent", "000")};
`;
