import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Button from "../../_atom/Buttons/default";
import MTP from "../../_atom/MTP";
import { buildText } from "../../_atom/Text";
import { buildTitle } from "../../_atom/Title";

import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const ArrowContainer = styled.span`
  display: flex;
  padding-left: 8px;
  align-self: center;
`;

export const MTPContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RaceMTP = styled(MTP)`
  padding: 0;
  width: 56px;
  font-family: ${fontMedium};
  ${(props) =>
    props.isMicro &&
    !props.isLink &&
    css`
      width: auto;
      padding-right: 3px;
      font-size: 12px;
      line-height: 11px;
      flex-direction: row;

      &::before {
        width: 4px;
        height: 4px;
      }

      &::after {
        padding-left: 4px;
      }

      & span {
        width: auto;
        height: 11px;
        padding: 2px;
        font-size: 10px;
      }
    `};
  ${(props) =>
    props.isSmall &&
    !props.isLink &&
    css`
      min-height: 32px;
      width: 48px;
      font-size: 12px;
    `};
  color: ${(props) => props.isLink && buildColor("grey", "900")};
`;

export const RaceInfoContainer = styled.dl`
  display: flex;
  flex-direction: column;
  min-width: 0;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  flex: 1;
  overflow: hidden;
  padding-left: ${(props) => (props.isMicro ? "2px" : "8px")};
  border-left: 1px solid
    ${(props) =>
      props.isLink ? buildColor("blue", "100") : "rgba(255, 255, 255, 0.2)"};
`;

export const RaceTitle = styled.dt`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
`;

export const RaceDescription = styled.dd`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 1px 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GreyhoundText = styled(
  buildText({
    fontSize: 10,
    uppercase: true,
    color: buildColor("blue", "300"),
    bold: true,
    qaLabel: "raceNavigation-greyhound"
  })
)`
  margin-top: 2px;
`;

export const RaceNumber = styled(
  buildTitle({
    tag: "h4",
    fontSize: 16,
    bold: true,
    qaLabel: "raceNavigation-raceNumber"
  })
)`
  white-space: nowrap;
`;

export const RaceMicroNumber = styled(
  buildTitle({
    tag: "h4",
    fontSize: 12,
    bold: true,
    qaLabel: "raceNavigation-raceNumber"
  })
)`
  white-space: nowrap;
  height: 11px;
  line-height: 11px;
`;

export const buildTitleWrapper = (isSmall) => styled(
  buildTitle({
    tag: "h3",
    fontSize: 16,
    color: buildColor("white", "100"),
    bold: true,
    ellipsis: !isSmall,
    uppercase: true,
    qaLabel: "raceLink-trackName"
  })
)`
  padding-right: ${isSmall ? "4px" : "8px"};
`;

export const TitleMicro = styled(
  buildTitle({
    tag: "h3",
    fontSize: 12,
    color: buildColor("white", "100"),
    bold: true,
    ellipsis: false,
    uppercase: true,
    qaLabel: "raceLink-trackName"
  })
)`
  padding-right: 2px;
`;

export const Title = buildTitle({
  tag: "h3",
  fontSize: 16,
  color: buildColor("white", "100"),
  bold: true,
  ellipsis: true,
  uppercase: true,
  qaLabel: "raceLink-trackName"
});

export const buildRaceContainer = (isLink, isSmall, isMicro, isRaceResult) => {
  const Container = isLink
    ? styled(Link).attrs({
        "data-qa-label": "raceSelectorItem"
      })
    : styled(Button);
  return Container`
    display: flex;
    flex-grow: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    text-decoration: none;
    border-radius: 2px;
    outline: none;
    height: 48px;
    background-color: ${buildColor("black", "30")};
    color: ${buildColor("white", "100")};
    min-width: 0;

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }

    ${
      (isSmall || isMicro) &&
      !isLink &&
      css`
        /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
        @media (pointer: fine) {
          &:hover {
            background-color: ${buildColor("black", "30")};
          }
        }
      `
    };
   ${
     isSmall &&
     !isLink &&
     css`
       height: 32px;
       width: auto;
     `
   }
   ${
     isMicro &&
     !isLink &&
     css`
       height: 16px;
       width: auto;
       padding: 0 2px;
       align-self: flex-end;
     `
   }
    ${
      isLink &&
      css`
        min-height: 64px;
        height: auto;
        background-color: ${buildColor("white", "0")};
        border-bottom: 1px solid ${buildColor("blue", "100")};

        &:active {
          background-color: ${buildColor("blue", "000")};
        }
      `
    };
    ${
      isRaceResult &&
      isLink &&
      css`
        background-color: ${buildColor("grey", "000")};
      `
    };
  `;
};
