import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("BET_CONFIRMATION:BET_ERROR", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Error",
      gaEventLabel: get(data, "payload.error"),
      module: get(data, "payload.module"),
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: get(data, "payload.runnerType"),
      repeatBet: get(data, "payload.repeatBet"),
      runnerAmount: get(data, "payload.runnerAmount")
    });
  });

  mediatorChannels.base.subscribe("BET_CONFIRMATION:CONFIRM_BET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Confirm",
      gaEventLabel: undefined,
      gaEventValue: undefined,
      module: "Bet Ticket",
      runnerSelectionList: get(data, "payload.selections"), // Concatenate values fron left to right e.g '1,5'
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"), // pass as a JS decimal
      runnerAmount: get(data, "payload.runnerAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("BET_CONFIRMATION:RETRY_BET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Retry Bet",
      gaEventLabel: undefined,
      module: "Bet Ticket",
      runnerSelectionList: get(data, "payload.selections"), // Concatenate values fron left to right e.g '1,5'
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betAmount: get(data, "payload.betAmount"), // pass as a JS decimal
      runnerAmount: get(data, "payload.runnerAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`
    });
  });

  mediatorChannels.base.subscribe(
    "BET_CONFIRMATION:CLOSE_CONFIRM_MODAL",
    (data) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Close",
        gaEventLabel: get(data, "payload.confirmMode")
          ? "Confirm Bet Modal"
          : "Bet Success Modal",
        module: get(data, "payload.confirmMode")
          ? "Confirm Bet Modal"
          : "Bet Success Modal",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("BET_CONFIRMATION:BET_SUCCESS", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Success",
      gaEventLabel: get(data, "payload.wagerSerialNumber"),
      gaEventValue: get(data, "payload.betAmount"),
      module: get(data, "payload.module"),
      runnerSelectionList: get(data, "payload.selections"), // Concatenate values fron left to right e.g '1,5'
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: get(data, "payload.betId"),
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet"),
      runnerAmount: get(data, "payload.runnerAmount")
    });
  });

  mediatorChannels.base.subscribe("BET_CONFIRMATION:RETAIN_RUNNERS", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Add To Betslip",
      gaEventLabel: "Retained Runners",
      module: "Bet Success Modal",
      runnerSelectionList: undefined,
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: undefined,
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });

  mediatorChannels.base.subscribe("BET_CONFIRMATION:BET_TIMEOUT", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Bet Time out",
      gaEventLabel: undefined,
      module: get(data, "payload.module"), // 'Race Card' or 'Bet Confirmation Modal',
      runnerSelectionList: get(data, "payload.selections"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      betId: undefined,
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      repeatBet: get(data, "payload.repeatBet")
    });
  });
};
