export const initialState = {
  activeBets: {
    totalBets: 0,
    totalAmount: 0,
    bets: []
  },
  isOpen: false,
  selectedTab: "ACTIVE",
  selectedSettledTab: "TODAY",
  activeBetsCounter: 0,
  deletedBets: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MYBETS_GET_ACTIVE_BETS": {
      return {
        ...state,
        activeBets: {
          bets: action.payload.bets,
          totalAmount: action.payload.totalAmount,
          totalBets: action.payload.totalBets
        }
      };
    }

    case "MYBETS_CANCEL_SUCCESS": {
      const { wager } = action.payload;
      const totalAmount = state.activeBets.totalAmount - wager.betTotal;
      const totalBets = state.activeBets.totalBets - 1;

      const bets = state.activeBets.bets
        .map((group) => ({
          ...group,
          wagers: group.wagers.filter(
            (b) => b.serialNumber !== wager.serialNumber
          )
        }))
        .filter((g) => g.wagers.length > 0);

      return {
        ...state,
        activeBets: {
          bets,
          totalAmount,
          totalBets
        }
      };
    }

    case "MYBETS_OPEN": {
      return { ...state, isOpen: true };
    }

    case "MYBETS_CLOSE": {
      return { ...state, isOpen: false };
    }

    case "MYBETS_SET_SELECTED_TAB": {
      return { ...state, selectedTab: action.payload };
    }

    case "MYBETS_SET_SELECTED_SETTLED_TAB": {
      return { ...state, selectedSettledTab: action.payload };
    }

    case "MYBETS_SET_UPCOMING_BETS_BADGE": {
      return { ...state, activeBetsCounter: action.payload };
    }

    case "MYBETS_UPDATE_DELETED_BETS": {
      if (
        action.payload.shouldRemove &&
        state.deletedBets.includes(action.payload.serialNumber)
      ) {
        const pSerialNumber = action.payload.serialNumber;
        const updatedBets = state.deletedBets.filter(
          (serialNumber) => serialNumber !== pSerialNumber
        );

        return { ...state, deletedBets: updatedBets };
      }

      if (!state.deletedBets.includes(action.payload.serialNumber)) {
        return {
          ...state,
          deletedBets: [...state.deletedBets, action.payload.serialNumber]
        };
      }

      return { ...state };
    }

    default: {
      return state;
    }
  }
}
