import tvgConf from "@tvg/conf";
import { Limits, PaymentOptionsEnum } from "@tvg/ts-types/ResponsibleGaming";
import { PaymentTypeItem } from "@tvg/wallet/src/types";
import { isFDR } from "@tvg/utils/generalUtils";
import { getAuthTokenCookie } from "@tvg/sh-utils/sessionUtils";
import getProtocol from "../protocolSetter";
import requester from "../requester";
import { serviceResponse } from "../types";
import { getWalletBalances } from "../aw";

const uwtService: string = "service.uwt";
const balanceHeaders = () => ({
  "content-type": "application/json",
  "x-clientapp": tvgConf().brand,
  "x-tvg-context": tvgConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

const getPaymentOptions = (accountNumber: string): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/payment-options`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getAvailablePaymentMethods = (
  accountNumber: string
): Promise<
  serviceResponse<{
    firstDepositDone: boolean;
    paymentTypes: PaymentTypeItem[];
  }>
> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/available-payment-methods`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const blockPaymentMethod = (
  accountNumber: string,
  paymentType: PaymentOptionsEnum
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/payment-options`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      enablePaymentType: false,
      isCustomerRequest: true,
      paymentType
    }
  });

const getPendingWithdrawals = (
  accountNumber: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/withdrawals/pending`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getWithdrawalLimit = (accountNumber: string): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/withdrawal/balance`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const cancelWithdrawal = (
  accountNumber: string,
  withdrawalId: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/withdrawals/${withdrawalId}`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getDepositLimits = (accountNumber: string) =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/limits/responsible-gaming`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const updateDepositLimits = (
  accountNumber: string,
  limits: Limits
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${tvgConf().config(
      uwtService
    )}/users/${accountNumber}/limits/responsible-gaming`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: limits
  });

const getBalance = (
  accountId: string,
  isPoller: boolean = false,
  showPromoFunds: boolean = false
): Promise<serviceResponse<{ balance: { toFixed: Function } }>> => {
  const queryString = new URLSearchParams({
    isPoller: isPoller.toString(),
    showPromoFunds: showPromoFunds.toString()
  }).toString();

  if (isFDR()) {
    // This is wrong, we can't use two struct in the same function.
    // @ts-ignore -> We need to refactor this.
    return getWalletBalances(getAuthTokenCookie());
  }

  return requester()({
    method: "get",
    url: `${getProtocol()}${
      tvgConf().config().service.uwt
    }/users/${accountId}/balance?${queryString}`,
    headers: balanceHeaders(),
    withCredentials: true
  });
};

const UWT_SERVICE = {
  getPendingWithdrawals,
  cancelWithdrawal,
  getPaymentOptions,
  blockPaymentMethod,
  getDepositLimits,
  updateDepositLimits,
  getBalance,
  getAvailablePaymentMethods,
  getWithdrawalLimit,
  balanceHeaders
};

export default UWT_SERVICE;
