import React from "react";
import { Store } from "redux";
import { useSelector } from "react-redux";
import { getActiveBets } from "@urp/lib-racetracks/src/redux/selectors";
import { useURPProgramPageOnXSell } from "@urp/amplitude-experiments";
import { get } from "lodash";
import {
  getRacePromos,
  isUserOptedInPromo,
  hasRacePromoCPP,
  hasRacePromoCPPOptedIn
} from "@urp/lib-racetracks/src/utils/races";
import {
  getCPPPromos,
  getIsLogged,
  getOptedInPromos
} from "@urp/store-selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Container } from "./styled-components";
import { RaceIconProps } from "./types";
import { renderOtherIcons, renderVideoIcons } from "./renderer";
import { CONTAINER, ICONS } from "../../utils/constants";

export const RaceIcons = ({ race, qaLabel = "" }: RaceIconProps) => {
  const optedInPromos = useSelector(getOptedInPromos);
  const cppPromos = useSelector(getCPPPromos);
  const isLogged = useSelector(getIsLogged);
  const shouldUseUrpPPonXSell = useURPProgramPageOnXSell();
  const activeBets = useSelector((store: Store) =>
    getActiveBets(store, shouldUseUrpPPonXSell)
  );

  const hasDescription = !!race.highlighted?.description;
  const availablePromos = getRacePromos(race);
  const isOptedIn = isUserOptedInPromo(optedInPromos, availablePromos);
  const hasCPPPromoAvailable = hasRacePromoCPP(cppPromos, race);
  const isCPPPromosOptedIn = hasRacePromoCPPOptedIn(cppPromos, race, isLogged);
  const hasPromosAvailable = !!availablePromos.length;
  const hasActiveBets = get(activeBets, race?.tvgRaceId ?? "", 0);
  const lineColor = hasDescription
    ? "--fd-colors-content-on-dark"
    : "--fd-colors-content-subtle";
  const brandColor = hasDescription ? "white" : "grey";
  const promoLineColor = hasDescription
    ? "--fd-colors-system-alert-background-default"
    : "--fd-colors-system-alert-content-accent";

  const promoBackgroundColor = hasDescription
    ? "--fd-colors-core-yellow-d-3"
    : "--fd-colors-system-alert-background-subtle";

  const video = get(race, "video");
  const code = get(race, "status.code");

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, ICONS, CONTAINER])}>
      {renderOtherIcons(
        isLogged,
        isOptedIn || isCPPPromosOptedIn,
        hasPromosAvailable || hasCPPPromoAvailable,
        hasActiveBets,
        lineColor,
        promoLineColor,
        promoBackgroundColor,
        qaLabel
      )}
      {renderVideoIcons(video, code, lineColor, brandColor, qaLabel)}
    </Container>
  );
};
