import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  // When a user clicks "Select Provider" in the registration form
  mediator.base.subscribe(
    "REGISTRATION:TVG_PROVIDER_SELECT_PROVIDER",
    (data) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Field Modal Opened",
        gaEventLabel: "TVG Provider",
        module: get(data, "payload.module", ""),
        siteVersion
      });
    }
  );

  // When a user clicks continue and applies a TVG provider to the form
  mediator.base.subscribe(
    "REGISTRATION:TVG_PROVIDER_APPLIED_PROVIDER",
    (data) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "TVG Provider Applied",
        gaEventLabel: get(data, "payload.providerName", ""),
        module: get(data, "payload.module", ""),
        siteVersion
      });
    }
  );

  // When a user clicks continue and removes a TVG provider from the form
  mediator.base.subscribe(
    "REGISTRATION:TVG_PROVIDER_REMOVE_PROVIDER",
    (data) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "TVG Provider Removed",
        gaEventLabel: undefined,
        module: get(data, "payload.module", ""),
        siteVersion
      });
    }
  );

  // When a user clicks to close the TV provider modal
  mediator.base.subscribe("REGISTRATION:TVG_PROVIDER_CLOSE_MODAL", (data) => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: "Field Modal Closed",
      gaEventLabel: "TVG Provider",
      module: "TVG Provider"
    });
  });
};
