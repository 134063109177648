export const initialState = {
  showDownloadTransactions: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_HEADER_DOWNLOAD_TRANSACTIONS": {
      return {
        ...state,
        showDownloadTransactions: action.payload.showDownloadTransactions
      };
    }
    default: {
      return state;
    }
  }
}
