import React, { cloneElement, isValidElement, FC } from "react";
import type { TooltipDescriptiveContent } from "./types";
import { Header, Paragraph } from "../Typography";
import { Wrapper as StyledWrapper } from "./styled-components";

const Content: FC<TooltipDescriptiveContent> = ({
  placement,
  header,
  body,
  actionLink,
  isDarkMode,
  widthContent = "284px",
  ...rest
}) => (
  <StyledWrapper
    {...rest}
    placement={placement}
    backgroundColor={
      !isDarkMode
        ? "var(--fd-colors-brand-secondary-shade)"
        : "var(--fd-colors-background-layer)"
    }
    p="space-4"
    width={widthContent}
  >
    {header &&
      (isValidElement(header) ? (
        cloneElement(header)
      ) : (
        <Header
          qaLabel="tooltip-descriptive-header"
          tag="h3"
          marginBottom="space-3"
          color={
            isDarkMode
              ? "--fd-colors-content-strong"
              : "--fd-colors-content-on-dark"
          }
        >
          {header}
        </Header>
      ))}
    {isValidElement(body) ? (
      cloneElement(body)
    ) : (
      <Paragraph
        qaLabel="tooltip-descriptive-body"
        color={
          isDarkMode ? "--fd-colors-content-default" : "--fd-colors-core-white"
        }
      >
        {body}
      </Paragraph>
    )}
    {actionLink && isValidElement(actionLink) && cloneElement(actionLink)}
  </StyledWrapper>
);

export default Content;
