import { get } from "lodash";
import * as amplitude from "@amplitude/analytics-browser";
import UAParser from "ua-parser-js";

import { isMobile } from "@tvg/sh-utils/mobileUtils";
import {
  BaseEventProps,
  ExtraPropertiesArgs,
  GetGlobalProperties,
  GlobalPropertiesObject,
  GlobalProperties,
  PathnameHashAndSearch
} from "./types";
import { getPageName, getPageViewVal } from "./utils";
import { URP_PAGE_CONFIG } from "./constants";

export default (
  getGlobalProperties: GetGlobalProperties,
  pagesConfig = URP_PAGE_CONFIG,
  extra?: ExtraPropertiesArgs
) => {
  const COMMON_EVENT_NAMES = {
    SITE_CLICK: "SITE CLICK",
    PAGE_VIEWED: "PAGE VIEWED",
    NAVIGATED: "NAVIGATION LINK CLICKED",
    OPEN: "OPENED",
    CLOSE: "CLOSED",
    DELETED: "DELETED"
  };

  const name = "global-properties-plugin";
  const type: amplitude.Types.EnrichmentPlugin<
    amplitude.Types.CoreClient,
    amplitude.Types.Config
  >["type"] = "enrichment";
  const setup = async function () {
    // initial setup here!
  };

  const parser = new UAParser();

  const enrichEventWithDeviceInfo = (
    event: amplitude.Types.Event,
    globalProperties: GlobalPropertiesObject
  ) => {
    const isApp = isMobile();
    const device = parser.getDevice();
    const OS = parser.getOS();
    const deviceFamily =
      get(globalProperties, GlobalProperties.DEVICE_FAMILY) || device.vendor;
    const deviceType =
      get(globalProperties, GlobalProperties.DEVICE_TYPE) || device.model;

    event.device_manufacturer = deviceFamily;
    event.device_model = deviceType;

    if (isApp && deviceFamily) {
      const isTablet = device.type === "tablet";
      let globalPropDeviceFamily = deviceFamily.toLowerCase();

      if (globalPropDeviceFamily === "apple") {
        globalPropDeviceFamily = isTablet ? "apple_ipad" : "apple_iphone";
      } else {
        globalPropDeviceFamily = isTablet
          ? `${globalPropDeviceFamily}_tablet`
          : `${globalPropDeviceFamily}_phone`;
      }

      return {
        [GlobalProperties.DEVICE_TYPE]: deviceType,
        [GlobalProperties.DEVICE_FAMILY]: globalPropDeviceFamily
      };
    }
    return {
      [GlobalProperties.DEVICE_TYPE]: OS.name,
      [GlobalProperties.DEVICE_FAMILY]: OS.name
    };
  };

  const handleNavigationEvent = (
    event: amplitude.Types.Event,
    eventType: string,
    extraPropertiesArgs: ExtraPropertiesArgs | undefined,
    pagesConfiguration: Array<{
      page: string;
      urlReg: RegExp;
    }>
  ) => {
    const fullUrl: string = get(
      event.event_properties,
      GlobalProperties.FULL_URL,
      ""
    );
    const urlObj = new URL(fullUrl);
    const prevPath = extraPropertiesArgs?.prevPath.current;
    const ogPath = get(event.event_properties, GlobalProperties.PAGE_PATH, "");
    const linkUrl: string = get(
      event.event_properties,
      BaseEventProps.LINK_URL
    );

    const hasNavigated = linkUrl && linkUrl.includes(ogPath);
    const hasChangedTab = eventType.includes("TAB");
    const isModalOpen = !!urlObj.hash;
    const hasOpenedModal =
      (eventType.includes(COMMON_EVENT_NAMES.OPEN) ||
        eventType.includes(COMMON_EVENT_NAMES.NAVIGATED)) &&
      isModalOpen &&
      !hasChangedTab;

    if (!(hasNavigated || hasOpenedModal) || !prevPath) return null;

    const prevFullUrl = hasOpenedModal
      ? `${urlObj.origin}${prevPath}`
      : fullUrl.replace(ogPath, prevPath);

    return {
      ...(hasNavigated || linkUrl
        ? { [BaseEventProps.LINK_URL]: fullUrl }
        : {}),
      [GlobalProperties.FULL_URL]: prevFullUrl,
      [GlobalProperties.PAGE_PATH]: prevPath,
      [GlobalProperties.PAGE_NAME]: getPageName(
        { pathname: prevPath } as PathnameHashAndSearch,
        pagesConfiguration
      )
    };
  };

  // The following function will "intercept" every event being triggered
  // and will append the "global properties" to every event
  const execute = async function (event: amplitude.Types.Event) {
    const globalProperties =
      typeof getGlobalProperties === "function" && getGlobalProperties();
    if (!globalProperties) return event;

    const eventType = event.event_type.toUpperCase();
    if (eventType === COMMON_EVENT_NAMES.PAGE_VIEWED) {
      const pageName = get(
        event.event_properties,
        GlobalProperties.PAGE_NAME,
        getPageName(window.location, pagesConfig)
      );
      event.event_type = getPageViewVal(pageName);
    }

    event.event_properties = {
      ...globalProperties,
      ...event.event_properties,
      ...enrichEventWithDeviceInfo(event, globalProperties)
    };

    const navigationProps = handleNavigationEvent(
      event,
      eventType,
      extra,
      pagesConfig
    );
    if (navigationProps) {
      event.event_properties = {
        ...event.event_properties,
        ...navigationProps
      };
    }

    return event;
  };
  const plugin: amplitude.Types.EnrichmentPlugin = {
    name,
    setup,
    type,
    execute
  };

  return plugin;
};
