import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFdUserId } from "@urp/store-selectors";
import { Variant } from "@amplitude/experiment-js-client";
import useExperiments from "../../useExperiments";
import { DEPLOYMENT_KEY, EXPERIMENT_KEY } from "./constants";
import { VariantEnum } from "../../types";

export const useAllowChoseBuiltInProfile = () => {
  const accountNumber = useSelector(getFdUserId);
  const { setUserExperiment, getVariant } = useExperiments(DEPLOYMENT_KEY);
  const [variant, setVariant] = useState<Variant>();

  useEffect(() => {
    setUserExperiment({ user_id: accountNumber }).then(() => {
      setVariant(getVariant<VariantEnum, number>(EXPERIMENT_KEY));
    });
  }, [accountNumber]);

  return variant?.value === VariantEnum.TREATMENT;
};
