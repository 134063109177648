import { find, get, findKey, endsWith, orderBy } from "lodash";

import {
  HandicapOptionsType,
  HandicapSortOptionsType,
  RaceCardBettingInterest,
  BettingInterest,
  RaceOdds
} from "@tvg/ts-types/Race";
import { UnaryFn } from "@tvg/ts-types/Functional";
import handicappingOptions from "./handicapping-conf/handicappingOptions";

type OrderEnum = "asc" | "desc";

const setHandicappingSortOrderMode = (
  handicappingMode: HandicapOptionsType,
  handicappingSortMode: HandicapSortOptionsType
): OrderEnum => {
  const matchedHandicappingMode = find(handicappingOptions, {
    key: handicappingMode
  });
  const matchedHandicappingSortMode = find(
    get(matchedHandicappingMode, "handicapData", {
      key: handicappingSortMode,
      sorting: "asc"
    }),
    // @ts-ignore
    { key: handicappingSortMode }
  );
  return get(matchedHandicappingSortMode, "sorting", "asc");
};

const sortRunnerList = (
  runnersList: RaceCardBettingInterest[],
  handicappingMode: HandicapOptionsType,
  handicappingSortMode: HandicapSortOptionsType
): RaceCardBettingInterest[] => {
  let handicappingOrderMode = setHandicappingSortOrderMode(
    handicappingMode,
    handicappingSortMode
  );
  const defaultHandicappingSortMode = HandicapSortOptionsType.SADDLECLOTH;
  const defaultHandicappingOrderMode: OrderEnum = "asc";
  const firstElementSample = get(
    runnersList,
    "[0].runners[0].handicapping",
    {}
  );

  // Find sort mode criteria within betting interest structure

  const findDataByKey = (dataToSort: HandicapSortOptionsType): string => {
    const foundValueByKey = findKey(firstElementSample, (val) =>
      endsWith(val.key, dataToSort)
    );
    return foundValueByKey
      ? `runners[0].handicapping[${foundValueByKey}].value`
      : "";
  };

  const rateCalculation = (
    numerator: number | null | undefined,
    denominator: number | null | undefined
  ): number => {
    let returnValue = 0;

    if (denominator && numerator) {
      returnValue = numerator / denominator;
    } else if (denominator === null && numerator) {
      returnValue = numerator;
    }

    return !Number.isNaN(returnValue) ? returnValue : 0;
  };

  // Figuring out which values should be cast to number for sorting or not
  const valuesToCastToNumber = [
    HandicapSortOptionsType.AGE,
    HandicapSortOptionsType.WEIGHT,
    HandicapSortOptionsType.POWER,
    HandicapSortOptionsType.DAYS_OFF,
    HandicapSortOptionsType.AVG_SPEED,
    HandicapSortOptionsType.AVG_DISTANCE,
    HandicapSortOptionsType.HIGH_SPEED,
    HandicapSortOptionsType.AVG_CLASS_RATING,
    HandicapSortOptionsType.LAST_CLASS_RATING,
    HandicapSortOptionsType.NUM_RACES,
    HandicapSortOptionsType.EARLY,
    HandicapSortOptionsType.MIDDLE,
    HandicapSortOptionsType.FINISH,
    HandicapSortOptionsType.STARTS,
    HandicapSortOptionsType.WINS,
    HandicapSortOptionsType.PLACES,
    HandicapSortOptionsType.SHOWS
  ];

  const valueCasting =
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (dataToSort: string | UnaryFn<any, number>) => (value: BettingInterest) => {
      // @ts-ignore
      if (typeof dataToSort === "string") {
        let valueToReturn = get(value, dataToSort, "-");
        const limit = dataToSort.length - 6;
        const keyValue = `${dataToSort.substring(0, limit)}.key`;
        const keyToReturn = get(value, keyValue, "");
        // This string transformation is here because of long key names with dots
        // The objective is to get the last value splitted by dots to match any
        // values on the valuesToCastToNumber array above
        if (
          valuesToCastToNumber.includes(keyToReturn.split(".").slice(-1)[0])
        ) {
          valueToReturn = !Number.isNaN(+valueToReturn) ? +valueToReturn : 0;
        }
        return valueToReturn;
      }

      return "";
    };

  // Sort modes config

  const sortModes = {
    saddlecloth: "biNumber",
    odds: ({ currentOdds }: { currentOdds: RaceOdds }): number => {
      const { numerator, denominator } = currentOdds || {
        numerator: 99,
        denominator: 0
      };
      return rateCalculation(numerator, denominator);
    },
    age: findDataByKey(HandicapSortOptionsType.AGE),
    med: findDataByKey(HandicapSortOptionsType.MED),
    weight: findDataByKey(HandicapSortOptionsType.WEIGHT),
    power: findDataByKey(HandicapSortOptionsType.POWER),
    winStarts: (bettingInterest: BettingInterest): number => {
      const winStarts = get(
        bettingInterest,
        findDataByKey(HandicapSortOptionsType.WIN_STARTS),
        "0"
      );
      const [numerator, denominator] = winStarts.split("/");
      return rateCalculation(numerator, denominator);
    },
    daysOff: findDataByKey(HandicapSortOptionsType.DAYS_OFF),
    avgSpeed: findDataByKey(HandicapSortOptionsType.AVG_SPEED),
    avgDistance: findDataByKey(HandicapSortOptionsType.AVG_DISTANCE),
    highSpeed: findDataByKey(HandicapSortOptionsType.HIGH_SPEED),
    avgClassRating: findDataByKey(HandicapSortOptionsType.AVG_CLASS_RATING),
    lastClassRating: findDataByKey(HandicapSortOptionsType.LAST_CLASS_RATING),
    numRaces: findDataByKey(HandicapSortOptionsType.NUM_RACES),
    early: findDataByKey(HandicapSortOptionsType.EARLY),
    middle: findDataByKey(HandicapSortOptionsType.MIDDLE),
    finish: findDataByKey(HandicapSortOptionsType.FINISH),
    starts: findDataByKey(HandicapSortOptionsType.STARTS),
    wins: findDataByKey(HandicapSortOptionsType.WINS),
    places: findDataByKey(HandicapSortOptionsType.PLACES),
    shows: findDataByKey(HandicapSortOptionsType.SHOWS)
  };

  const bettingInterests: RaceCardBettingInterest[] = [];
  const bettingInterestsScratched: RaceCardBettingInterest[] = [];

  // Sorting out scratched and coupled entries

  runnersList.forEach((bettingInterest) => {
    if (bettingInterest.runners && bettingInterest.runners.length > 1) {
      bettingInterest.runners.forEach((currentRunner) => {
        const runner = {
          ...bettingInterest,
          runners: [currentRunner]
        };

        if (currentRunner.scratched) {
          bettingInterestsScratched.push(runner);
        } else {
          bettingInterests.push(runner);
        }
      });
    } else if (
      bettingInterest.runners &&
      bettingInterest.runners[0].scratched
    ) {
      bettingInterestsScratched.push(bettingInterest);
    } else {
      bettingInterests.push(bettingInterest);
    }
  });

  // exception for when you have year of birth instead of age

  if (handicappingSortMode === HandicapSortOptionsType.AGE) {
    const ageSample = get(
      bettingInterests,
      `[0].${findDataByKey(HandicapSortOptionsType.AGE)}`,
      0
    );
    handicappingOrderMode = ageSample < 100 ? "asc" : "desc";
  }

  // Actual sorting criteria

  const exceptionsToCast = [
    HandicapSortOptionsType.ODDS,
    HandicapSortOptionsType.WIN_STARTS
  ];

  // Take note of the exception for odds, which has its own sorting method
  const sortOptions = [
    exceptionsToCast.includes(handicappingSortMode)
      ? sortModes[`${handicappingSortMode}`]
      : valueCasting(sortModes[`${handicappingSortMode}`]),
    sortModes[`${defaultHandicappingSortMode}`]
  ];

  const sortOrders = [handicappingOrderMode, defaultHandicappingOrderMode];
  const bettingInterestsSorted = orderBy(
    bettingInterests,
    sortOptions,
    sortOrders
  );
  const bettingInterestsScratchedSorted = orderBy(
    bettingInterestsScratched,
    sortOptions,
    sortOrders
  );
  // @ts-ignore
  return bettingInterestsSorted.concat(bettingInterestsScratchedSorted);
};

export default sortRunnerList;
