import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import { RaceProgram } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

// @ts-ignore
import pushToDataLayer from "../gtmUtils";

type SelectBetTypeGtmType = {
  type: "PP_RUNNER_SELECTION";
  payload: {
    eventLabel: string;
    currentRace: RaceProgram;
    betTypeCode: WagerTypeCodesEnum | undefined;
  };
};

export default () => {
  mediatorChannels.base.subscribe(
    "PP_RUNNER_SELECTION",
    (data: SelectBetTypeGtmType): void => {
      pushToDataLayer({
        event: "selection",
        gaEventCategory: "Selection",
        gaEventAction: "Add to Betslip",
        gaEventLabel: get(data, "payload.option"),
        module: "Race Card",
        tag: undefined,
        raceNumber: get(data, "payload.currentRace.raceNumber"),
        trackName: get(data, "payload.currentRace.track.trackName"),
        sport: "Horse Racing",
        betType: get(data, "payload.betTypeCode")
      });
    }
  );
};
