import tvgConf from "@tvg/conf";
import { get } from "lodash";

const { FEATURE_BRANCH = "", NODE_ENV = "production" } = process.env;
const isDev = NODE_ENV === "development";

const featureBranch =
  typeof window !== "undefined"
    ? get(window, "__TVG_GLOBALS__.FEATURE_BRANCH", "")
    : FEATURE_BRANCH;

const conf = tvgConf();

const { gcs } = conf.config("google");
const { environment } = conf;

const fbParsed =
  featureBranch && featureBranch !== "featurebranch"
    ? `-${featureBranch.toLowerCase()}`
    : "";

const getPublicPath = (publicPath?: string) =>
  (environment === "staging" || environment === "production") && !isDev
    ? `${gcs.url}/${gcs.tvgStatic}/static/mbl${fbParsed}/`
    : publicPath;

export default getPublicPath;
