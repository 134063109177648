export const initialState = false;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "GRAPH_REQUEST_PENDING":
    case "VIDEO_REQUEST_FETCHING": {
      return true;
    }
    case "GRAPH_REQUEST_FINISH":
    case "VIDEO_REQUEST_ERROR":
    case "VIDEO_REQUEST_SUCCESS": {
      return false;
    }
    default: {
      return state;
    }
  }
}
