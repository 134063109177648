import styled, { css } from "styled-components";

import { buildText } from "../../_atom/Text";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontNormal } from "../../_static/Typography";

const getDescriptionColor = (importanceLevel, isDark) => {
  switch (importanceLevel) {
    case "0":
      return isDark ? buildColor("white", "100") : buildColor("grey", "800");
    case "1":
      return isDark ? buildColor("blue", "300") : buildColor("blue", "500");
    default:
      return buildColor("grey", "900");
  }
};

export const RaceDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 5px;
  flex: 1;
  padding-top: 1px;
  color: white;
`;

export const RacePromoWrapper = styled.div`
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.hasRaceEvent &&
    css`
      align-items: end;
    `}
`;

export const EventWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: end;
`;

export const RaceDataList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;

  > li {
    color: ${(props) => props.textColor};
  }
`;

export const RaceDataListItem = styled.li`
  font-size: 12px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  font-family: ${fontNormal};

  &:not(:last-child) {
    border-right: 1px solid
      ${(props) =>
        props.isDark ? buildColor("white", "20") : buildColor("grey", "600")};
    padding-right: 4px;
    margin-right: 4px;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  margin-bottom: 4px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

export const Description = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    bold: false
  })
)`
  display: flex;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  font-family: ${fontMedium};
  color: ${(props) => getDescriptionColor(props.importanceLevel, props.isDark)};
`;

export const Title = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    bold: false,
    uppercase: true
  })
)`
  font-family: ${fontMedium};
  padding-right: 8px;
`;
export const SubTitle = styled(
  buildText({
    tag: "strong",
    fontSize: 14,
    color: buildColor("grey", "900"),
    bold: false,
    uppercase: true,
    ellipsis: true
  })
)`
  padding-right: 8px;
`;
export const PromoBadge = styled.div`
  display: flex;
  color: ${buildColor("grey", "800")};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
`;

export const PromoBadgeText = styled.span`
  margin-left: 3px;
`;

export const ChangesIcon = styled.span`
  padding-right: 2px;
  display: inherit;
`;
