import React, { useContext } from "react";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { useEvents } from "../../../../hooks/useEvents";
import { SortContext } from "../../context";
import { Container, ContentContainer, Dropdown } from "./styled-components";
import { AnimatedDropdownProps } from "./types";

export const AnimatedDropdown: React.FC<AnimatedDropdownProps> = ({
  isDarkMode = false,
  children,
  qaLabel,
  width = "auto",
  ...rest
}) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { events, eventsHandlers, hoverHandlers } = useEvents({});
  const { isDropdownOpen, layer } = useContext(SortContext);

  // @TODO: implement animation
  return isDropdownOpen ? (
    // @ts-ignore
    <Container
      {...viewTestProps}
      {...eventsHandlers}
      {...events}
      {...hoverHandlers}
    >
      <ContentContainer layer={layer} width={width}>
        <Dropdown isDarkMode={isDarkMode} {...rest}>
          {children}
        </Dropdown>
      </ContentContainer>
    </Container>
  ) : null;
};
