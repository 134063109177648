import React, { FC, useState, useCallback, useEffect } from "react";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
  Button,
  ButtonsContainer,
  CarouselContainer,
  Embla,
  EmblaContainer,
  EmblaSlide,
  EmblaViewport,
  HeaderContainer
} from "./styled-components";
import { Icon } from "../Icon";
import { Header } from "../Typography";
import {
  CarouselProps,
  UsePrevNextButtonsType,
  IconButtonProps
} from "./types";

const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setPrevBtnDisabled(!api.canScrollPrev());
    setNextBtnDisabled(!api.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  };
};

const IconButton = ({ onClick, isDisabled, direction }: IconButtonProps) => (
  <Button
    className="embla__button embla__button--next"
    data-qa-label={direction === "left" ? "left-button" : "right-button"}
    onClick={onClick}
    disabled={isDisabled}
  >
    <Icon
      qaLabel={direction === "left" ? "arrow-left" : "arrow-right"}
      name={direction === "left" ? "arrowLeft" : "arrowRight"}
      size="s"
      lineColor="--fd-colors-component-button-tertiary-content-base"
    />
  </Button>
);

export const Carousel: FC<CarouselProps> = ({
  qaLabel = "carousel",
  items,
  title = "",
  slideSize = 300,
  gap = "--fd-space-space-2",
  edgeGap = "--fd-space-space-4",
  slidesToScroll = 1,
  dragFree = false,
  loop = false,
  align = "start",
  displayHeader = true,
  containerSpace,
  headerSpace,
  onEmblaApi,
  headerChildren = null,
  displayNavButtons = true
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop,
    slidesToScroll,
    dragFree,
    align
  });

  useEffect(() => {
    if (onEmblaApi && emblaApi) {
      onEmblaApi(emblaApi);
    }
  }, [emblaApi, onEmblaApi]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi);

  return (
    <CarouselContainer
      data-qa-label={`carousel-container-${qaLabel}`}
      {...containerSpace}
    >
      {displayHeader && (
        <HeaderContainer {...headerSpace}>
          {title && (
            <Header
              tag="h2"
              fontFamily="bold"
              fontWeight="700"
              qaLabel="carousel-title"
              color="--fd-colors-content-strong"
              my={0}
            >
              {title}
            </Header>
          )}
          {headerChildren}
          {displayNavButtons && !(prevBtnDisabled && nextBtnDisabled) && (
            <ButtonsContainer>
              <IconButton
                onClick={onPrevButtonClick}
                isDisabled={prevBtnDisabled}
                direction="left"
              />
              <IconButton
                onClick={onNextButtonClick}
                isDisabled={nextBtnDisabled}
                direction="right"
              />
            </ButtonsContainer>
          )}
        </HeaderContainer>
      )}
      <Embla slideSize={slideSize} className="embla">
        <EmblaViewport
          edgeGap={edgeGap}
          className="embla__viewport"
          ref={emblaRef}
        >
          <EmblaContainer gap={gap} className="embla__container">
            {items.map((item, index) => (
              <EmblaSlide
                className="embla__slide"
                slideSize={slideSize}
                key={index.toString()}
              >
                {item}
              </EmblaSlide>
            ))}
          </EmblaContainer>
        </EmblaViewport>
      </Embla>
    </CarouselContainer>
  );
};

export type { CarouselProps };
