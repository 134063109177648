import React, { useContext, useEffect } from "react";
import { noop } from "lodash";

import { useEvents } from "../../../../hooks/useEvents";
import { useQaLabel } from "../../../../hooks/useQaLabel";
import { useTheme } from "../../../../theming";
import { SortContext } from "../../context";
import {
  StyledPressable,
  StyledTextDescription,
  StyledTextTitle
} from "./styled-components";
import { OptionProps } from "./types";

export { OptionProps };

export const Option = ({
  label,
  value,
  description,
  isSelected = false,
  isDisabled = false,
  qaLabel = value,
  ...rest
}: OptionProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { colors } = useTheme();
  const { events, setEvents, eventsHandlers } = useEvents({});
  const { selectedValue, onChange = noop } = useContext(SortContext);

  useEffect(() => {
    setEvents({ ...events, isSelected });
  }, [isSelected]);

  useEffect(() => {
    setEvents({
      ...events,
      isSelected: isSelected || selectedValue === value
    });
  }, [selectedValue]);

  const pressHandler = () => {
    onChange(value);
  };

  return (
    <StyledPressable
      onPress={pressHandler}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onLongPress={pressHandler}
      underlayColor={colors.blue_accent[100]}
      {...eventsHandlers}
      {...events}
      {...viewTestProps}
    >
      <>
        <StyledTextTitle {...events} {...rest} isDisabled={isDisabled}>
          {label}
        </StyledTextTitle>
        {description && (
          <StyledTextDescription {...events} {...rest}>
            {description}
          </StyledTextDescription>
        )}
      </>
    </StyledPressable>
  );
};
