import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("MORE_PAGE:BALANCE_SHOWN", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction:
        get(data, "payload.isBalanceShown", "0") === "0" ? "Hide" : "Show",
      gaEventLabel: "Balance",
      module: "More",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("MORE_NAVIGATION", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: `${get(data, "payload.linkName")}`,
      menu: `${get(data, "payload.menu", "More")}`,
      module: "More",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: get(data, "payload.url")
    });
  });

  mediatorChannels.base.subscribe("MORE_OPEN_CONTENT", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: `${get(data, "payload.itemOpened")}`,
      module: "More",
      microApp: "non-Microapp",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("MORE_CLOSE_CONTENT", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Go Back To",
      gaEventLabel: `${get(data, "payload.destinationMenu")}`,
      module: `More - ${get(data, "payload.module")}`,
      microApp: "non-Microapp",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("MORE_SWITCH_TOGGLE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: data.payload.action,
      gaEventLabel: data.payload.field,
      module: "More",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    // FR-197
    "MORE_PAGE:PREFERENCES_SECTION",
    (data) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: data.payload.isExpanded ? "Collapse" : "Expand",
        gaEventLabel: "preferences",
        module: "More",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("SUPPORT_MODAL_TOGGLE", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "site click",
      gaEventAction: get(data, "payload.modalToggle", "open"),
      gaEventLabel: "support",
      module: "support modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("SUPPORT_MODAL_BUTTONS", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "site click",
      gaEventAction: "select",
      gaEventLabel: get(data, "payload.buttonType", "phone"),
      module: "support modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "MORE_PAGE:WITHDRAWAL_AND_DEPOSIT_BUTTON",
    (data) => {
      pushToDataLayer({
        destinationUrl: get(data, "payload.destinationUrl"),
        event: "navigation",
        gaEventAction: "Navigated To",
        gaEventCategory: "Navigation",
        gaEventLabel: get(data, "payload.eventLabel"),
        menu: "More",
        module: "More",
        tag: undefined,
        ...(get(data, "payload.isEvenTimeout", true)
          ? { evenTimeout: 2000 }
          : {})
      });
    }
  );

  // When a user clicks the Inbox icon
  mediatorChannels.base.subscribe(
    "MORE_PAGE:HEADER_SECTION_NOTIFICATIONS_BUTTON",
    () => {
      pushToDataLayer({
        event: "siteClick",
        gaEventAction: "Open",
        gaEventCategory: "Site Click",
        gaEventLabel: "TVG Inbox Notifications",
        menu: "More",
        module: "More"
      });
    }
  );
};
