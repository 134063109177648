import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  mediatorChannels.base.subscribe("REGISTRATION:INFO_SCREEN_ERROR", (data) => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: get(data, "payload.eventAction", ""),
      gaEventLabel: get(data, "payload.eventLabel", ""),
      siteVersion,
      module: get(data, "payload.module", "")
    });
  });

  mediatorChannels.base.subscribe(
    "REGISTRATION:INFO_SCREEN_CTA_CLICK",
    (data) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: get(data, "payload.eventAction", ""),
        gaEventLabel: get(data, "payload.eventLabel", ""),
        siteVersion,
        module: get(data, "payload.module", "")
      });
    }
  );
};
