import { get } from "lodash";
import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  EventProps,
  ModuleEventProps,
  MediatorEventType,
  MenuClickPayload,
  HamburgerMenuClickPayload,
  BetSelectorClickPayload,
  transformedNavigation
} from "./types";
import { EVENTS } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.MENU_CLICK,
    (data: MenuClickPayload): void => {
      const eventAction = get(data, "payload.action");

      const formattedEvent = transformedNavigation.includes(eventAction)
        ? EVENTS.NAVIGATION_LINK_CLICKED
        : eventAction;

      track<EventProps>(formattedEvent, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.link"),
        [BaseEventProps.MODULE]: get(data, "payload.module"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.currentRaceNumber"),
        [ModuleEventProps.ITEM_SELECTED]: get(
          data,
          "payload.selectionRaceNumber"
        ),
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.HAMBURGER_MENU_CLICK,
    ({ payload }: HamburgerMenuClickPayload): void => {
      const eventAction = get(payload, "isOpen")
        ? EVENTS.HAMBURGER_MENU_OPENED
        : EVENTS.HAMBURGER_MENU_CLOSED;

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.MODULE]: "Global Header"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.BET_SELECTOR_CLICK,
    (data: BetSelectorClickPayload): void => {
      track<EventProps>(EVENTS.BET_TYPE_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.betType"),
        [BaseEventProps.MODULE]: "bet_selector",
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.race.raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.race.track.trackName"),
        [ModuleEventProps.MTP]: get(data, "payload.race.mtp"),
        [ModuleEventProps.RACE_STATUS]: get(data, "payload.race.status.code"),
        [ModuleEventProps.FDTV_TYPE]: get(data, "payload.fdtvType")
      });
    }
  );
};
