import { gql } from "@apollo/client";

export const GET_GRAPH_CURRENT_RACE = gql`
  query getGraphCurrentRace(
    $trackCode: String
    $raceNumber: String
    $wagerProfile: String
  ) {
    races(
      filter: { matchRaces: { trackCode: $trackCode, number: $raceNumber } }
      profile: $wagerProfile
    ) {
      id
      number
      trackCode
      trackName
      mtp
      postTime
      status {
        code
      }
    }
  }
`;

export default GET_GRAPH_CURRENT_RACE;
