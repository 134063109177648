export default {
  app_start: {
    samples: 1,
    threshold: 100
  },
  program_page_load: {
    samples: 10,
    threshold: 100,
    conditions: [
      {
        device: "iPhone",
        version: "8",
        factor: 3
      },
      {
        device: "iPhone",
        version: "9",
        factor: 2
      }
    ]
  }
};
