import { find } from "lodash";
import { modalHashMap } from "../Hashes";

export const interceptor = (
  previousLocation,
  currentLocation,
  dispatch,
  history,
  isLogged,
  redirectedFromQuickToFullDeposit,
  myBetsStandaloneToggle,
  isLoginModalOpen,
  enablePawsQuickDeposits
) => {
  if (currentLocation.hash) {
    const url = `${currentLocation.pathname}${currentLocation.search}`;

    const previousHash =
      previousLocation && previousLocation.hash
        ? previousLocation.hash
        : undefined;

    const hash = find(
      modalHashMap({
        dispatch,
        history,
        location: url,
        isLogged,
        redirectedFromQuickToFull: redirectedFromQuickToFullDeposit,
        previousHash,
        myBetsStandaloneToggle,
        isLoginModalOpen,
        enablePawsQuickDeposits
      }),
      (h) => h.hash === currentLocation.hash
    );
    if (hash) hash.call();
  }
};

export default {
  interceptor
};
