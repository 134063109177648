export default {
  register: (mark, timing = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.register(mark, timing);
    }
  },
  measure: (mark, tags = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.measure(mark, tags);
    }
  }
};
