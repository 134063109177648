import { useEffect, useRef } from "react";

// custom hook for getting previous value
export const usePrevious = <T = unknown>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
