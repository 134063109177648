import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PARLAY_CARD_CTA_CLICKED = "SRP:PARLAY_CARD_CTA_CLICKED"
}
export interface ParlayCardCTAEventProps {
  raceNumber?: string;
  trackName?: string;
  position: number;
  linkUrl?: string;
  linkText?: string;
}

export interface SameRaceParlayData {
  type: MediatorEventType;
  payload: ParlayCardCTAEventProps;
}
