import React, { Component } from "react";
import InAppMessagesContainer from "./styled-components";

export default class BrazeInAppMessages extends Component {
  static defaultProps = {
    device: "mobile"
  };

  constructor(props) {
    super(props);
    this.state = {
      showInAppMessage: false,
      inAppMessage: {}
    };
  }

  componentDidMount() {
    try {
      const self = this;
      if (typeof window !== "undefined" && window.braze) {
        if (!window.appboyBridge) {
          window.appboyBridge = {};
        }

        window.appboyBridge.closeMessage = () => {
          self.setState({
            showInAppMessage: false,
            inAppMessage: {}
          });
        };

        window.braze.subscribeToInAppMessage((inAppMessage) => {
          if (inAppMessage.Re === "WEB_HTML") {
            self.setState({
              showInAppMessage: true,
              inAppMessage
            });
          } else {
            let shouldDisplay = true;
            // Read the key-value pair for msg-id
            const msgId = inAppMessage.extras["msg-id"];

            // If this is our push primer message
            if (msgId === "push-primer") {
              // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
              // has already granted/blocked permission
              if (
                !window.braze.isPushSupported() ||
                window.braze.isPushPermissionGranted() ||
                window.braze.isPushBlocked()
              ) {
                shouldDisplay = false;
              }
              if (inAppMessage.buttons[0] != null) {
                // Prompt the user when the first button is clicked
                inAppMessage.buttons[0].subscribeToClickedEvent(function () {
                  window.braze.requestPushPermission();
                });
              }
            }
          }
        });
      }
    } catch (e) {
      console.warn(
        "[Braze In app]: Make sure you have initialized WEB SDK\nError from lib:",
        e
      );
    }
  }

  render() {
    return this.state.showInAppMessage ? (
      <InAppMessagesContainer
        device={this.props.device}
        dangerouslySetInnerHTML={{ __html: this.state.inAppMessage.message }}
      />
    ) : (
      ""
    );
  }
}
