export const initialState = {
  tutorialsList: []
};

export default function capiReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_TUTORIALS_LIST": {
      return { ...state, tutorialsList: action.payload };
    }

    default: {
      return state;
    }
  }
}
