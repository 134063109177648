import { get } from "lodash";

export const initialState = {
  config: {
    isProcessing: false,
    isBetPlaced: false,
    isBettingError: false,
    errorBetMessage: "",
    betConfirmationTitle: "",
    repeatBetNumber: 1,
    randomTalentPhrase: "",
    randomTalentPhraseId: -1,
    hasFeedback: false,
    feedbackType: "error",
    partialFailure: false,
    partialInfo: "",
    partialInfoDetails: "",
    feedbackTitle: "",
    feedbackMsg: ""
  },
  repeatBetNumber: 1,
  showRepeatBet: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_BET_TICKET_STATE": {
      return {
        ...state,
        config: { ...state.config, ...action.payload }
      };
    }
    case "SET_BET_REPEAT_NUMBER": {
      return {
        ...state,
        repeatBetNumber: get(action, "payload")
      };
    }
    case "SET_SHOW_REPEAT_BET": {
      return {
        ...state,
        showRepeatBet: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
