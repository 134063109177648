import React, { useEffect } from "react";

import { connect } from "react-redux";
import { noop, get } from "lodash";
import {
  getTodayActiveCounter,
  getTodaySettledCounter,
  getTodayFutureCounter,
  getTotalActiveBets,
  getSelectedTab,
  getSelectedSettledTab,
  getIsLoadingMyBets
} from "@tvg/sh-lib-my-bets/redux/selectors";
import MyBetsHeaderTemplate from "@tvg/atomic-ui/_templates/MyBetsHeader";
import {
  buildTabs,
  buildTimeFrameTabs
} from "@tvg/sh-lib-my-bets/utils/service";
import {
  onTabClickGtm,
  sendTimeframeSelectionGtmEvent
} from "@tvg/sh-lib-my-bets/utils/gtm";
import usePrevious from "@tvg/sh-lib-my-bets/hooks/usePrevious";

export const MyBetsHeader = ({
  totalActiveBets,
  futureBetsCounter,
  settledBetsCounter,
  selectedTab,
  selectedSettledTab,
  isLoading,
  dispatch
}) => {
  const previousSelectedTab = usePrevious(selectedTab);
  const previousLoading = usePrevious(isLoading);

  const timeFrameTabs = buildTimeFrameTabs(
    selectedSettledTab,
    isLoading,
    dispatch
  );

  useEffect(() => {
    onTabClickGtm({
      selectedTab,
      activeBetsCount: totalActiveBets,
      settledBetsCount: settledBetsCounter,
      futureBetsCount: futureBetsCounter,
      previousSelectedTab
    });
  }, [selectedTab]);

  useEffect(() => {
    if (
      previousLoading &&
      !isLoading &&
      selectedTab === "SETTLED" &&
      selectedTab === previousSelectedTab
    ) {
      const selectedTimeFrame = timeFrameTabs.find(
        (tab) => tab.id === selectedSettledTab
      );

      if (selectedTimeFrame && selectedTimeFrame !== "None") {
        sendTimeframeSelectionGtmEvent(
          get(selectedTimeFrame, "title", ""),
          selectedTab,
          totalActiveBets,
          settledBetsCounter
        );
      }
    }
  }, [isLoading]);

  return (
    <MyBetsHeaderTemplate
      tabs={buildTabs(
        totalActiveBets,
        futureBetsCounter,
        selectedTab,
        dispatch
      )}
      timeFrameTabs={timeFrameTabs}
      shouldDisplayTimeFrameTabs={selectedTab === "SETTLED"}
    />
  );
};

MyBetsHeader.defaultProps = {
  dispatch: noop,
  totalActiveBets: 0,
  futureBetsCounter: 0,
  settledBetsCounter: 0,
  selectedTab: "ACTIVE",
  selectedSettledTab: "TODAY",
  isLoading: true
};

export default connect(
  (store) => ({
    totalActiveBets: getTotalActiveBets(store),
    selectedTab: getSelectedTab(store),
    selectedSettledTab: getSelectedSettledTab(store),
    activeBetsCounter: getTodayActiveCounter(store),
    settledBetsCounter: getTodaySettledCounter(store),
    futureBetsCounter: getTodayFutureCounter(store),
    isLoading: getIsLoadingMyBets(store)
  }),
  (dispatch) => ({ dispatch })
)(MyBetsHeader);
