import React, { PureComponent } from "react";
import { noop, get } from "lodash";

import VideoButtonContainer from "./styled-components";
import buildButtonInfo, { videoMessages } from "./VideoButtonInfo";

export default class VideoButton extends PureComponent {
  static defaultProps = {
    small: false,
    onClick: noop
  };

  render() {
    const { feedback, small, onClick } = this.props;

    return (
      <VideoButtonContainer
        isBold={false}
        isDisabled={
          feedback.indexOf("AVAILABLE") !== 0 &&
          feedback.indexOf("NO_REPLAY") > -1
        }
        type={
          feedback.indexOf("NO_REPLAY") === -1 ? "secondary_alt" : "primary"
        }
        onClick={onClick}
        isStretched={!small}
        qaLabel={`raceCardHeader-${get(
          videoMessages,
          `[${feedback}]`,
          ""
        ).replace(" ", "")}`}
      >
        {buildButtonInfo({ feedback, small })}
      </VideoButtonContainer>
    );
  }
}
