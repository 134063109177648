export const initialState = {
  raceTypeFilters: [],
  regionFilters: []
};

// when the user selects a filter, we need to check the current filters we have set
// if the filter selected is already there, remove it, else, add it
export const mergeFilterCodes = (currentFilters, selectedFilter) => {
  const mergedFilters = currentFilters.map((f) => f);
  const selectedFilterIndex = currentFilters.indexOf(selectedFilter);

  if (selectedFilterIndex > -1) {
    mergedFilters.splice(selectedFilterIndex, 1);
  } else {
    mergedFilters.push(selectedFilter);
  }

  return mergedFilters;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "RACE_FILTER_SELECTED":
      return {
        ...state,
        raceTypeFilters: mergeFilterCodes(
          state.raceTypeFilters,
          action.payload.filterSelected
        )
      };
    case "REGION_FILTER_SELECTED":
      return {
        ...state,
        regionFilters: mergeFilterCodes(
          state.regionFilters,
          action.payload.filterSelected
        )
      };
    case "SET_RACE_FILTERS":
      return { ...state, raceTypeFilters: action.payload.filters };
    case "SET_REGION_FILTERS":
      return { ...state, regionFilters: action.payload.filters };
    default: {
      return state;
    }
  }
}
