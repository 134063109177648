import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

const FooterNav = styled.nav`
  display: flex;
  ${(props) =>
    props.layout === "horizontal"
      ? css`
          min-height: 48px;
          padding-bottom: 0;
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
          background-color: ${buildColor("blue", "500")};

          & > a,
          button {
            flex: 0 0 20%;
          }
        `
      : css`
          background-color: ${buildColor("blue", "700")};
          flex-direction: column;
          width: 56px;
          align-items: center;
          justify-content: center;

          & > a,
          button {
            margin: 8px 0;
            height: 48px;
          }
        `};
`;

export default FooterNav;
