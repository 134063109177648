import tvgConf from "@tvg/conf";
import requester from "../requester";
import getProtocol from "../protocolSetter";

const getLicense = () => {
  const tvg = tvgConf();
  return requester()({
    method: "get",
    url: `${getProtocol()}${tvg.config().service.geo}/license`,
    headers: {
      Accept: "application/json",
      "x-tvg-context": tvg.context()
    }
  });
};

const validateGeoPacket = (userId: string, geo: string) => {
  const tvg = tvgConf();
  return requester()({
    method: "POST",
    url: `${getProtocol()}${tvg.config().service.geo}/geopacket`,
    headers: {
      Accept: "application/json",
      "x-tvg-context": tvg.context()
    },
    data: {
      geo,
      userId
    }
  });
};

const getRegion = (location: { latitude: number; longitude: number }) => {
  const { latitude, longitude } = location;
  const tvg = tvgConf();
  return requester()({
    method: "get",
    url: `${getProtocol()}${
      tvg.config().service.geo
    }/regions/search/findByGpsCoordinate?latitude=${latitude}&longitude=${longitude}`,
    headers: {
      Accept: "application/json",
      "x-tvg-context": tvg.context()
    }
  });
};

const GEO_SERVICE = {
  getLicense,
  validateGeoPacket,
  getRegion
};

export default GEO_SERVICE;
