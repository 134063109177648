import { Store } from "redux";
import { get } from "lodash";

import parseCAPIMessage from "@tvg/utils/capiUtils";
import { PastRaceResults, RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { isURP, isTvg5 } from "@tvg/utils/generalUtils";
import { WagerProfile, UserOptedInPromos } from "@tvg/ts-types/User";
import {
  TracksFilterMsg,
  TracksSearchMsg,
  TabsTracks,
  TopTracksConfig,
  TrackFilterValueEnum,
  ToggleHideResultsEnum
} from "../types";
import {
  tracksFilterMsgDefault,
  tracksSearchMsgDefault,
  tabsTracksPage,
  topTracksConfig
} from "./defaultValues";

export const getIsAccountCompliant = (store: unknown): boolean =>
  get(store, "userData.isVerified", true) &&
  get(store, "userData.jurisdiction.accepted", true);

export const getOptedInPromos = (store: Store): UserOptedInPromos =>
  get(store, "userData.optedInPromos", {});

export const getUserGotPromos = (store: Store): boolean =>
  get(store, "userData.gotPromos", false);

export const getActiveBets = (
  store: Store,
  shouldUseUrpPPOnXSell: boolean
): Record<string, number> =>
  isURP || isTvg5() || shouldUseUrpPPOnXSell
    ? get(store, "myBetsStandalone.racesActiveBets", {})
    : get(store, "myBets.racesActiveBets", {});

export const getFavoriteTracks = (store: Store): Array<string> =>
  get(store, "raceTracks.userFavorites.tracks");

export const getFavoriteTracksWithId = (store: Store): Record<string, number> =>
  get(store, "raceTracks.userFavorites.tracksWithId");

export const getOpenTracks = (store: Store): Array<string> =>
  get(store, "raceTracks.tracks.openTracks", []);

export const getOnlyFavoritesTracks = (store: Store): boolean =>
  get(store, "raceTracks.tracks.onlyFavorites", false);

export const getCurrentReplayVideo = (store: Store): string =>
  get(store, "raceTracks.tracks.currentReplay", undefined);

export const getCurrentReplayVideoFileName = (store: Store): string =>
  get(store, "raceTracks.tracks.currentReplayFileName", undefined);

export const getCurrentVideoTitle = (store: Store): string =>
  get(store, "raceTracks.tracks.videoTitle", undefined);

export const getTracksFilterMsg = (store: Store): TracksFilterMsg =>
  parseCAPIMessage(store, "capi.messages.tracksFilter", tracksFilterMsgDefault);

export const getTracksSearchMsg = (store: Store): TracksSearchMsg =>
  parseCAPIMessage(store, "capi.messages.tracksSearch", tracksSearchMsgDefault);

export const getRaceTypesFilter = (store: Store): Array<RaceTypeCodeEnum> =>
  get(store, "raceTracks.filter.raceTypes", []);

export const getRegionsFilter = (store: Store): Array<string> =>
  get(store, "raceTracks.filter.regions", []);

export const getDistancesFilter = (store: Store): Array<string> =>
  get(store, "raceTracks.filter.distances", []);

export const getRacesFilter = (store: Store): Array<string> =>
  get(store, "raceTracks.filter.races", []);

export const getTrackTypesFilter = (
  store: Store
): Array<TrackFilterValueEnum> =>
  get(store, "raceTracks.filter.trackTypes", []);

export const getRaceTracksSearchQuery = (store: Store): string =>
  get(store, "raceTracks.search.searchQuery", "");

export const getRaceTracksIsSearchActive = (store: Store): boolean =>
  get(store, "raceTracks.search.isSearchActive", false);

export const getCurrentTabTracksPage = (store: Store): string =>
  get(store, "raceTracks.tabs.currentTab", "tracks");

export const getTabsTracksPage = (store: Store): TabsTracks =>
  parseCAPIMessage(store, "capi.messages.tabsTracksPage", tabsTracksPage);

export const getSeoFetchedWagerProfile = (store: Store): WagerProfile =>
  get(store, "seoRaceTracks.fetchedWagerProfile", WagerProfile.PORT_GENERIC);

export const getIsNewTILEnabled = (store: Store): boolean =>
  get(store, "capi.featureToggles.enableNewTracksInformationList", false);

export const getTopTracksConfig = (store: Store): TopTracksConfig =>
  parseCAPIMessage(store, "capi.messages.topTracksConfig", topTracksConfig);

export const getHideResults = (store: Store): string =>
  get(store, "raceTracks.races.hideResults", ToggleHideResultsEnum.OFF);

export const getPastRacesCached = (
  store: Store
): Record<string, PastRaceResults> =>
  get(store, "raceTracks.races.pastRacesCache", {});

export const getSearchedHorseNames = (
  store: Store
): { value: string | number; label: string }[] =>
  get(store, "raceTracks.horses.filteredHorseNames", []);
