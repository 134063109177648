import React, { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import MessageBox from "../../_molecule/MessageBox";
import {
  MessageContainer,
  TopMessagesContainer,
  BottomMessagesContainer
} from "./styled-components";

const buildMessages = (messages) =>
  messages.map((m) => (
    <MessageContainer sideToSide={m.sideToSide} key={m.key}>
      <MessageBox
        type={m.type}
        message={m.message}
        hasIcon={m.hasIcon}
        title={m.title}
        onClose={m.onClose}
      />
    </MessageContainer>
  ));

const Messenger = ({ messages = [], isFooterVisible = true }) => {
  const [element, setElement] = useState({});

  useEffect(() => {
    /* istanbul ignore next */
    if (typeof window !== "undefined") {
      const el = document.createElement("div");
      const root = document.body;
      root.appendChild(el);
      setElement({ el, root });
    }
  }, []);

  const getTopMessages = () => {
    return messages.filter((m) => m.position === "top");
  };

  const getBottomMessages = () => {
    return messages.filter((m) => m.position === "bottom");
  };

  const renderContent = () => {
    const topMessages = getTopMessages();
    const bottomMessages = getBottomMessages();

    return (
      <Fragment>
        {topMessages.length > 0 && (
          <TopMessagesContainer>
            {buildMessages(topMessages)}
          </TopMessagesContainer>
        )}
        {bottomMessages.length > 0 && (
          <BottomMessagesContainer isFooterVisible={isFooterVisible}>
            {buildMessages(bottomMessages)}
          </BottomMessagesContainer>
        )}
      </Fragment>
    );
  };

  /* istanbul ignore next */
  if (typeof window === "undefined") {
    return null;
  }

  if (element.el) {
    return createPortal(renderContent(), element.el);
  }

  return null;
};

export default Messenger;
