import { setDeviceId as umSetDeviceId } from "@urp/unified-modules/src/utils/amplitude/setDeviceId";
import { setSessionId as umSetSessionId } from "@urp/unified-modules/src/utils/amplitude/setSessionId";

import {
  getSessionId,
  setSessionId,
  getDeviceId,
  setDeviceId
} from "@amplitude/analytics-browser";

import {
  addCookie,
  getCookieValue,
  removeCookie
} from "@tvg/sh-utils/cookieUtils";
import { isFDR } from "@tvg/utils/generalUtils";
import {
  DEVICE_ID_COOKIE,
  DEVICE_ID_COOKIE_MAX_AGE,
  SESSION_ID_COOKIE,
  SESSION_ID_COOKIE_MAX_AGE
} from "./constants";

export const getAmpSessionIDCookie = () => getCookieValue(SESSION_ID_COOKIE);
export const removeAmpSessionIDCookie = () => removeCookie(SESSION_ID_COOKIE);

export const setAmpSessionIDCookie = () => {
  let amplitudeSessionId: number | undefined = parseInt(
    getAmpSessionIDCookie(),
    10
  );

  if (!Number.isNaN(amplitudeSessionId)) {
    setSessionId(amplitudeSessionId);
  } else {
    amplitudeSessionId = getSessionId();
  }

  // Setting the session ID on Amplitude Unified Modules
  if (isFDR()) {
    umSetSessionId(amplitudeSessionId?.toString());
  }

  // update cookie validity
  if (amplitudeSessionId) {
    addCookie(
      SESSION_ID_COOKIE,
      `${amplitudeSessionId}`,
      SESSION_ID_COOKIE_MAX_AGE,
      true
    );
  }
};

export const setAmpDeviceIDCookie = () => {
  let amplitudeAppDeviceId: string | undefined =
    getCookieValue(DEVICE_ID_COOKIE);

  if (amplitudeAppDeviceId) {
    setDeviceId(amplitudeAppDeviceId);
  } else {
    amplitudeAppDeviceId = getDeviceId();
  }

  // Setting the device ID on Amplitude Unified Modules
  if (isFDR()) {
    umSetDeviceId(amplitudeAppDeviceId);
  }

  // update cookie validity
  if (amplitudeAppDeviceId) {
    addCookie(
      DEVICE_ID_COOKIE,
      amplitudeAppDeviceId,
      DEVICE_ID_COOKIE_MAX_AGE,
      true
    );
  }
};
