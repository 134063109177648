import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import Messenger from "@tvg/atomic-ui/_templates/Messenger";

import service from ".";

export const MessengerComponent = ({
  dispatch,
  messages = [],
  isMyBetsModalOpen = false,
  isAnyModalOpen = false
}) => {
  useEffect(() => {
    service.setDispatcher(dispatch);
  }, []);

  return (
    <Messenger
      messages={messages}
      isFooterVisible={!(isAnyModalOpen || isMyBetsModalOpen)}
    />
  );
};

export default connect(
  (store) => ({
    messages: store.messenger.messages,
    isAnyModalOpen: store.modal.isModalOpen,
    isMyBetsModalOpen: get(store, "myBets.isOpen", false)
  }),
  (dispatch) => ({ dispatch })
)(MessengerComponent);
