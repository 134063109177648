import { attempt, get, isError } from "lodash";
import cookie from "react-cookie";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import pushToDataLayer, { getSiteVersion, getProduct } from "../../gtmUtils";

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);
  const productVersion = getProduct(tvgConf().product);
  const hasLoginOnce = cookie.load("hasLoginOnce");

  let privateBrowser = attempt(() =>
    localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
  );

  if (isError(privateBrowser)) {
    privateBrowser = "No";
  }

  mediator.base.subscribe("REGISTRATION:PAGE_VIEW", (data) => {
    pushToDataLayer({
      accountId: get(data, "payload.accountId", ""),
      customerStatus: hasLoginOnce ? "Returning" : "First Time",
      event: "ga_pageload",
      graphVersion: "v2",
      loginStatus: get(data, "payload.loginStatus", "Logged out"),
      page: get(data, "payload.location.pathname"),
      privateBrowser,
      productVersion,
      registrationStatus: hasLoginOnce ? "Registered" : "Unregistered",
      residenceState: get(data, "payload.residenceState", ""),
      registrationVersion: "v3",
      screenName: "Registration",
      sectionName: "Registration",
      siteVersion
    });
  });

  mediator.base.subscribe("REGISTRATION:VIRTUAL_PAGE_VIEW", (data) => {
    pushToDataLayer({
      event: "virtualPageLoad",
      page: `${get(data, "payload.location.pathname", "/registration")}/${get(
        data,
        "payload.view",
        ""
      )}`,
      screenName: get(data, "payload.view", ""),
      siteVersion
    });
  });
};
