export const initialState = {
  tracks: [],
  openFavoriteTracks: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "TRACKSAZ_FAV_TOGGLE_TRACK": {
      return {
        ...state,
        openFavoriteTracks: action.payload.openFavoriteTracks
      };
    }
    default: {
      return state;
    }
  }
}
