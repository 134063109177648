export type HandicappingStoreOpenModalAction = {
  type: "OPEN_HANDICAPPING_STORE_MODAL";
};

export type HandicappingStoreCloseModalAction = {
  type: "CLOSE_HANDICAPPING_STORE_MODAL";
};

export type SetHandicappingStoreModalUrlAction = {
  type: "SET_HANDICAPPING_STORE_MODAL_URL";
  payload: {
    url: string;
  };
};

export type SetHandicappingStoreModalHeaderAction = {
  type: "SET_HANDICAPPING_STORE_MODAL_HEADER";
  payload: {
    isModalHasHeader: boolean;
  };
};

export type Actions =
  | HandicappingStoreOpenModalAction
  | HandicappingStoreCloseModalAction
  | SetHandicappingStoreModalUrlAction
  | SetHandicappingStoreModalHeaderAction;

export const openHandicappingStoreModal =
  (): HandicappingStoreOpenModalAction => ({
    type: "OPEN_HANDICAPPING_STORE_MODAL"
  });

export const closeHandicappingStoreModal =
  (): HandicappingStoreCloseModalAction => ({
    type: "CLOSE_HANDICAPPING_STORE_MODAL"
  });

export const setHandicappingStoreModalUrl = (
  url: string
): SetHandicappingStoreModalUrlAction => ({
  type: "SET_HANDICAPPING_STORE_MODAL_URL",
  payload: {
    url
  }
});

export const setHandicappingStoreModalHeader = (
  isModalHasHeader: boolean
): SetHandicappingStoreModalHeaderAction => ({
  type: "SET_HANDICAPPING_STORE_MODAL_HEADER",
  payload: {
    isModalHasHeader
  }
});
