import { attempt } from "lodash";
import { v4 as uuid } from "uuid";
import getUserPromos from "../services/optedInPromos";

export const manageEquibaseId = (toAdd) => {
  if (toAdd) {
    if (!attempt(() => window.localStorage.getItem("equibaseId"))) {
      attempt(() => window.localStorage.setItem("equibaseId", uuid()));
    }
  } else if (attempt(() => window.localStorage.getItem("equibaseId"))) {
    attempt(() => window.localStorage.removeItem("equibaseId"));
  }
};

export const promosSubscriber = ({
  isLogged,
  accountNumber,
  loadingPromos,
  dispatch
}) => {
  if (isLogged && accountNumber && accountNumber !== "" && !loadingPromos) {
    getUserPromos(dispatch, accountNumber);
  }
};
