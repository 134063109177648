import styled from "styled-components";

import buildColor from "../../_static/ColorPalette";

export const FeatureTogglesContainer = styled.div`
  background-color: ${buildColor("white", "100")};
`;

export const FeatureToggleItem = styled.li`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  font-size: 12px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
`;
