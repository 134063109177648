export const iOSInitResponseAction = (responseData) => ({
  type: "IOS_INIT_RESPONSE",
  payload: responseData
});

export const iOSTouchIDResponseAction = (responseData) => ({
  type: "IOS_TOUCH_ID_RESPONSE",
  payload: responseData
});

export function iOSTouchIDErrorAction(err) {
  return { type: "IOS_TOUCH_ID_ERROR", payload: err, error: true };
}

export const iOSNotificationsStateAction = (isNotificationsEnabled) => ({
  type: "IOS_NOTIFICATIONS_STATE",
  payload: { isNotificationsEnabled }
});
