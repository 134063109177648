import { get, find } from "lodash";
import UAParser from "ua-parser-js";
import axios from "axios";
import config from "./config";

class PerfCollector {
  mark;

  measures;

  violations;

  reportServiceUrl;

  metadata;

  constructor(reportServiceUrl) {
    this.mark = {};
    this.measures = {};
    this.violations = [];
    const parser = new UAParser();
    this.metadata = parser.getResult();
    this.reportServiceUrl = reportServiceUrl;
    if (typeof window === "object") {
      setInterval(this.reportViolations.bind(this), 10000);
    }
  }

  register(markName, timestamp = 0) {
    this.mark[markName] = {
      start: timestamp || Date.now(),
      name: markName
    };
  }

  measure(markName, customTags = {}) {
    const mark = get(this.mark, markName);
    if (mark) {
      const timing = Date.now() - mark.start;
      const device = String(get(this.metadata, "device.model")).replace(
        /\s+/g,
        ""
      );
      const browser = String(get(this.metadata, "browser.name")).replace(
        /\s+/g,
        ""
      );
      const connection = String(
        get(window, "navigator.connection.effectiveType")
      ).replace(/\s+/g, "");
      const osName = String(get(this.metadata, "os.name")).replace(/\s+/g, "");
      const osVersion = String(get(this.metadata, "os.version")).replace(
        /\s+/g,
        ""
      );
      const os = `${osName}${osVersion.split(".")[0]}`;
      const indicator = get(config, markName);
      delete this.mark[markName];

      const tags = {
        browser,
        device,
        os,
        connection
      };

      if (indicator) {
        const conditions = find(indicator.conditions, tags);
        let { threshold } = indicator;
        if (conditions) {
          threshold *= conditions.factor;
        }

        if (!this.measures[mark.name]) {
          this.measures[mark.name] = [];
        }
        this.measures[mark.name].push(timing);
        const nMeasures = this.measures[mark.name].length;

        if (nMeasures >= indicator.samples) {
          const sum = this.measures[mark.name].reduce(
            (previous, current) => previous + current
          );
          const avg = sum / this.measures[mark.name].length;
          this.measures[mark.name].shift();

          if (avg > threshold) {
            this.measures[mark.name] = [];
            const violation = {
              indicator: mark.name,
              time: avg,
              metadata: this.metadata
            };
            this.violations.push(violation);
          }
        }
      }
    }
  }

  clearStack() {
    this.violations = [];
  }

  reportViolations() {
    if (this.violations.length > 0 && this.reportServiceUrl) {
      axios
        .post(this.reportServiceUrl, this.violations)
        .then(() => {
          this.clearStack();
        })
        .catch((e) =>
          console.warn("Unable to report performance indicators", e)
        );
    }
  }
}

export default PerfCollector;
